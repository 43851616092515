import styled from "@emotion/styled";
import { Button, TableCell, TableRow } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

export const DetailColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 10px;
  padding: 4px;
  flex-grow: 1;
  & :first-of-type {
    font-weight: bold;
  }
  > * {
    margin: 4px 0;
  }
  p {
    color: #536171;
  }
`;

export const DetailRowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaginationDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const DetailsRow = styled(TableRow)`
  color: #536171 !important;
`;

export const RefreshButton = styled(Button)`
  text-transform: none !important;
  margin-top: 16px !important;
  p {
    text-decoration: underline;
    color: #536171;
  }
  :hover {
    background-color: transparent !important;
  }
`;

export const HeaderCell = styled(TableCell)`
  font-weight: bold !important;
  color: #536171 !important;
`;

export const CellNoDivider = styled(TableCell)`
  border-bottom: none !important;
  color: #536171 !important;
  &:last-child {
    padding-right: 4px !important;
  }
`;

export const CellNoDividerPointer = styled(CellNoDivider)`
  cursor: pointer;
`;

export const WarningIcon = styled(ErrorIcon)`
  color: #f9a107;
`;
export const StatusCellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const FileNameDetails = styled.div`
  max-width: 307px;
`;
