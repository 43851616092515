import {
  PageExtensionSDK,
  SidebarExtensionSDK,
} from 'contentful-ui-extensions-sdk';
import { InitConfigPayload } from 'store/config/config.interface';
import { api } from './axiosInstance';
import i18n from './initTranslation';

export const getContentfulIds = async (
  sdk: PageExtensionSDK | SidebarExtensionSDK,
  contentTypes?: string[]
): Promise<InitConfigPayload> => {
  const {
    ids: { environment, space },
    locales,
  } = sdk;
  const contentTypeIds = contentTypes || [];

  if (!contentTypes) {
    try {
      const { data } = await api.allowedContentTypes(space, environment);
      contentTypeIds.push(...data);
    } catch {
      sdk.notifier.error(i18n.t('errormessage.somethingWrong'));
    }
  }

  return {
    environmentId: environment,
    spaceId: space,
    defaultLanguage: locales.default,
    contentTypeIds,
    sdk,
  };
};
