/** @jsx jsx */
import { jsx } from '@emotion/core';
import Tooltip from '@material-ui/core/Tooltip';
import {InputAdornment} from '@material-ui/core';
import {TextField} from '@material-ui/core';
import {ChangeEvent, Component, ReactNode} from 'react';
import { FieldInputProps } from 'react-final-form';
import {Trans, withTranslation, WithTranslation} from 'react-i18next';
import {customInputStyles, CustomTypography} from './CustomInput.style';
import InfoIconOutlined from '@material-ui/icons/InfoOutlined';

interface IProps {
    label: string;
    error?: string | boolean;
    touched?: boolean;
    inputProps: FieldInputProps<unknown>;
    type?: string;
    testId?: string;
    disableAutoComplete?: boolean;
    disabled?: boolean;
    placeholder?: string;
    showCharacterCount?: boolean
    multiline?: boolean
    rows?: number
    tooltipInfo?: string
    adornment?: string
}

interface IState {
  characterLenght: number
}

type PropType = IProps & WithTranslation
export class CustomField extends Component<PropType , IState> {
  // @ts-ignore
  constructor(props: PropType) {
    super(props);
    this.state = {
      characterLenght: this.props.inputProps.maxLength
    }
  }

    charactersLeft = () => {
        const {showCharacterCount, inputProps} = this.props
        if (!showCharacterCount) return null
        return <span
            css={customInputStyles.countLabel}>{inputProps.maxLength - (inputProps.value as string).length} characters left</span>

    }

    render(): JSX.Element {
        const {
            t,
            error,
            touched,
            type,
            testId,
            inputProps,
            disableAutoComplete,
            disabled,
            placeholder,
            label,
            multiline,
            rows,
            tooltipInfo,
            adornment
        } = this.props;

        const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
            this.setState({characterLenght: inputProps.maxLength - event.currentTarget.value.length})
        }

    const InputProps = (tooltipInfo: string | undefined, adornment: ReactNode) => {
      return {
        endAdornment: tooltipInfo ? <InputAdornment position={"end"}>
            <Tooltip title={<Trans i18nKey={tooltipInfo}/>} arrow>
              <InfoIconOutlined/>
            </Tooltip>
          </InputAdornment> : undefined,
        startAdornment: adornment ? <InputAdornment position={"start"}>
          {adornment}
        </InputAdornment> : undefined }
      }

    const shouldErrorDisplay = typeof error === 'boolean' || (error && touched);

        return (
            <div css={customInputStyles.root}>
                <div css={customInputStyles.labelFlex}><CustomTypography color="textSecondary"
                                                                         variant="subtitle2">{t(label)}</CustomTypography>
                    {this.charactersLeft()}
                </div>
                <TextField
                    css={shouldErrorDisplay ? customInputStyles.outlineError : {}}
                    variant="outlined"
                    margin="dense"
                    inputProps={{...inputProps, 'data-testid': testId}}
                    InputProps={InputProps(tooltipInfo, adornment)}
                    type={type}
                    autoComplete={disableAutoComplete ? 'new-password' : type}
                    disabled={disabled}
                    placeholder={placeholder ? t(placeholder) : undefined}
                    onChange={(event) => {
                        handleOnChange(event)
                    }}
                    multiline={multiline}
                    rows={rows}
                />

        {shouldErrorDisplay && (
          <span
            css={customInputStyles.textErrorNoMargin}
            data-testid={`${testId}Error`}
          >
            {typeof error !== 'boolean' && t(error as string)}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomField);
