import styled from '@emotion/styled';

export const Scroller = styled.div`
  padding-top: 20px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
`;

export const TabsWrapper = styled.div`
  max-width: 900px;
  margin: auto;
`;
