import Axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import { AuthenticationResult, AxiosRestApplicationClient } from 'utils/restApplicationClient';

const axiosInstance = Axios.create();
export const tokenStorageKey = '___PLUGIN_TOKEN_KEY___';
export const refreshTokenStorageKey = '___PLUGIN_TOKEN2_KEY___';
export const tokenTtlStorageKey = '___PLUGIN_TOKEN3_KEY___';
export const urlStorageKey = '___URL_KEY___';

/**
 * request interceptor
 *
 * @param config - axios default configuration
 */
const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  let headers: AxiosRequestConfig['headers'];
  const accessToken = localStorage.getItem(tokenStorageKey);
  const refreshToken = localStorage.getItem(refreshTokenStorageKey);

  if (config.url && config.url.includes('refreshToken')) {
    headers = {
      Authorization: `${accessToken}`,
      'X-Authorization': `${refreshToken}`,
    };
  } else {
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  config.headers = headers;
  return config;
};

/**
 * response interceptor
 *
 * @param response - axios response
 */
const responseInterceptor = (response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
  if (response.config.url === 'api/auth/user' || response.config.url === 'api/auth/refreshToken') {
    const { accessToken, refreshToken, ttl } = response.data as AuthenticationResult;
    localStorage.setItem(tokenStorageKey, accessToken);
    localStorage.setItem(refreshTokenStorageKey, refreshToken);
    localStorage.setItem(tokenTtlStorageKey, String(ttl));
  }

  return response;
};

interface CustomRequestConfig extends AxiosRequestConfig {
  _retry?: boolean
}

/**
 * response error interceptor
 *
 * @param error - axios error
 */
/**
 * response error interceptor
 *
 * @param error - axios error
 */
const responseErrorInterceptor = async (error: AxiosError<unknown>): Promise<any> => {
  const originalRequest = error.config as CustomRequestConfig;

  if ((error.response?.status === 401 || error.response?.status === 403)
      && !originalRequest._retry
      && !originalRequest.url?.includes('refreshToken')) {

    const accessToken = localStorage.getItem(tokenStorageKey);
    const refreshToken = localStorage.getItem(refreshTokenStorageKey);

    originalRequest._retry = true;

    try {
      const response = await axiosInstance({
        method: 'GET',
        url: '/api/auth/refreshToken',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Authorization': `${refreshToken}`,
        },
      });

      const { accessToken: newAccessToken } = response.data as AuthenticationResult;
      localStorage.setItem(tokenStorageKey, newAccessToken);
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

      return axiosInstance.request(originalRequest);
    } catch (error) {
      console.error('Refreshing token failed', error);
      throw error;
    }
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

// eslint-disable-next-line require-jsdoc
export const clearToken = (): void => {
  localStorage.removeItem(tokenStorageKey);
  localStorage.removeItem(refreshTokenStorageKey);
  localStorage.removeItem(tokenTtlStorageKey);
  localStorage.removeItem(urlStorageKey);
};

// eslint-disable-next-line require-jsdoc
export const getTTL = (): number => {
  return Number(localStorage.getItem(tokenTtlStorageKey));
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem(tokenStorageKey);
};

export const refreshTokenTimeout = async (): Promise<void> => {
  const [localAccessToken, localRefreshToken, localTtl] = [
    localStorage.getItem(tokenStorageKey),
    localStorage.getItem(refreshTokenStorageKey),
    localStorage.getItem(tokenTtlStorageKey),
  ];

  if (!api) {
    if (localStorage.getItem(urlStorageKey)) {
      initializeApi();
      refreshTokenTimeout();
    } else {
      console.error('Missing Application URL in configuration. Please log in first');
    }
  }

  if (!localAccessToken || !localRefreshToken || !localTtl) {
    return;
  }
  try {
    const {
      data: { accessToken, refreshToken, ttl },
    } = await api.refreshToken();
    localStorage.setItem(tokenStorageKey, accessToken);
    localStorage.setItem(refreshTokenStorageKey, refreshToken);
    localStorage.setItem(tokenTtlStorageKey, String(ttl));
    setTimeout(async () => {
      await refreshTokenTimeout();
    }, (ttl - 10 || 890) * 1000);
  } catch {
    clearToken();
  }
};

let api: AxiosRestApplicationClient;

const initializeApi = () => {
  api = new AxiosRestApplicationClient(localStorage.getItem(urlStorageKey) || '/', axiosInstance);
};

export { api, initializeApi };
export default axiosInstance;
