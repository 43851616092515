import { PayloadAction } from "@reduxjs/toolkit";
import { ContentType, User } from "contentful-ui-extensions-sdk";
import { EntryStatusEnum } from "enums/entryStatus";
import { FilterOptionsEnum } from "enums/filterOptions";
import { OrderEnum } from "enums/orderEnum";
import { combineEpics, Epic, ofType, StateObservable } from "redux-observable";
import { EMPTY, from, interval, of, zip } from "rxjs";
import {
  catchError,
  debounce,
  mergeMap,
  pluck,
  switchMap,
} from "rxjs/operators";
import { IStoreState } from "store";
import { initConfig } from "store/config/config.actions";
import { InitConfigPayload } from "store/config/config.interface";
import { checkNotifications } from "store/notification/notification.actions";
import { api } from "utils/axiosInstance";
import { getEntryTitle, getUserName } from "utils/getEntryTitle";
import i18n from "utils/initTranslation";
import { EntriesDTO, TagDTO } from "utils/restApplicationClient";
import { EntryRequestDTO } from "utils/restApplicationClientTypeOverrides";
import {
  getContentTypes,
  getContentTypesError,
  getContentTypesSuccess,
  getEntries,
  getEntriesData,
  getEntriesError,
  getEntriesSuccess,
  getTags,
  getTagsError,
  getTagsSuccess,
  getUsers,
  getUsersError,
  getUsersSuccess,
  prepareEntriesPayload,
  removeTagsPill,
  setEntriesFilters,
  setEntriesPaginationEnd,
  setEntriesPaginationStart,
  setParsedEntries,
  setSearchEnd,
  setSearchStart,
  setSelectedTags,
  setSortEnd,
  setSortStart,
  setTagsFilter,
} from "./entries.actions";
import { EntriesPagination, IEntriesState } from "./entries.interface";

const getEntriesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getEntries.type),
    pluck<PayloadAction<EntryRequestDTO>, "payload">("payload"),
    switchMap((payload) =>
      from(api.getEntries(payload)).pipe(
        switchMap(({ data }) => {
          return of(getEntriesSuccess(data));
        }),
        catchError((error) => {
          return of(getEntriesError(error));
        })
      )
    )
  );

const parseEntriesEpic: Epic = (
  action$,
  store$: StateObservable<IStoreState>
) =>
  zip(
    action$.ofType(getEntriesSuccess.type),
    action$.ofType(getContentTypesSuccess.type),
    action$.ofType(getTagsSuccess.type),
    action$.ofType(getUsersSuccess.type)
  ).pipe(
    mergeMap<
      [
        PayloadAction<EntriesDTO>,
        PayloadAction<IEntriesState["contentTypes"]>,
        PayloadAction<{ tags: IEntriesState["tags"] }>,
        PayloadAction<{ users: IEntriesState["users"] }>
      ],
      any
    >(
      ([
        {
          payload: { entries },
        },
        { payload: contentTypes },
        {
          payload: { tags },
        },
        {
          payload: { users },
        },
      ]) => {
        const {
          config: { defaultLanguage },
        } = store$.value;

        const map = entries.map((entry) => {
          return {
            contentName: getEntryTitle({
              defaultLocaleCode: defaultLanguage,
              defaultTitle: i18n.t("common.untitled"),
              entry,
              contentType: contentTypes[entry.contentType],
            }),
            type: contentTypes[entry.contentType].name,
            updatedAt: entry.updatedAt,
            author: getUserName(users, entry.updatedBy),
            tags: entry.tags.map((id) => tags[id]?.name).join(" ● "),
            status: {
              archivedAt: entry.archivedVersion,
              updatedAt: entry.updatedAt,
              publishedAt: entry.publishedAt,
            },
            checkbox: "checkbox",
          };
        });

        return of(setParsedEntries(map));
      }
    )
  );

const prepareEntriesPayloadEpic: Epic = (
  action$,
  store$: StateObservable<IStoreState>
) =>
  action$.pipe(
    ofType(prepareEntriesPayload.type),
    pluck<PayloadAction<Partial<EntryRequestDTO>>, "payload">("payload"),
    switchMap((payload) => {
      const {
        entries: {
          pagination: { rows },
          filters: { type, contentType },
          sort: { order },
          search,
          tagFilter,
          selectedTags,
        },
        config: { environmentId, spaceId, defaultLanguage, contentTypeIds },
      } = store$.value;

      const query: { [key: string]: string | boolean } = {};
      const orderString = `${
        (payload.order || order) === OrderEnum.ASC ? "" : "-"
      }sys.updatedAt`;
      const currentSelectedTags = selectedTags;

      currentSelectedTags.forEach((tags, index) => {
        const joinedTags = tags.map(({ value }) => value).join(",");

        if (tagFilter[index] === FilterOptionsEnum.ANY) {
          query["metadata.tags[exists]"] = true;
        } else if (tagFilter[index] === FilterOptionsEnum.NOT_TAGGED) {
          query["metadata.tags[exists]"] = false;
        } else if (
          tagFilter[index] === FilterOptionsEnum.INCLUDES &&
          tags.length
        ) {
          query["metadata.tags.sys.id[in]"] = query["metadata.tags.sys.id[in]"]
            ? `${query["metadata.tags.sys.id[in]"]},${joinedTags}`
            : joinedTags;
        } else if (
          tagFilter[index] === FilterOptionsEnum.NO_INCLUDES &&
          tags.length
        ) {
          query["metadata.tags.sys.id[nin]"] = query[
            "metadata.tags.sys.id[nin]"
          ]
            ? `${query["metadata.tags.sys.id[nin]"]},${joinedTags}`
            : joinedTags;
        } else if (
          tagFilter[index] === FilterOptionsEnum.INCLUDE_ALL &&
          tags.length
        ) {
          query["metadata.tags.sys.id[all]"] = query[
            "metadata.tags.sys.id[all]"
          ]
            ? `${query["metadata.tags.sys.id[all]"]},${joinedTags}`
            : joinedTags;
        }
      });

      if (payload.search || search) {
        query["query"] = payload.search || (search as string);
      }

      if (orderString) {
        query["order"] = orderString;
      }

      if (contentTypeIds && contentTypeIds.length > 0) {
        query["sys.contentType.sys.id[in]"] = contentTypeIds.join(",");
      } else {
        query["sys.contentType.sys.id[in]"] = "";
      }

      const requestPayload = {
        environmentId,
        spaceId,
        language: defaultLanguage,
        limit: payload.rows || rows,
        skip: payload.rows && payload.page ? payload.page * payload.rows : 0,
        query,
        type:
          (payload.type || (type as string)) === EntryStatusEnum.ANY
            ? undefined
            : payload.type || type,
        contentType:
          (payload.contentType || contentType) === "any"
            ? undefined
            : payload.contentType || contentType,
      };

      return of(getEntries(requestPayload));
    })
  );

const getContentTypesEpic: Epic = (
  action$,
  store$: StateObservable<IStoreState>
) =>
  action$.pipe(
    ofType(getContentTypes.type),
    pluck<PayloadAction<InitConfigPayload | undefined>, "payload">("payload"),
    switchMap((payload) => {
      const {
        config: { sdk: configSdk },
      } = store$.value;

      const sdk = payload?.sdk || configSdk;

      if (!sdk?.space) {
        return EMPTY;
      }

      //@ts-ignore
      return from(sdk.space.getContentTypes({ limit: 1000 })).pipe(
        switchMap(({ items: contentfulContentTypes }) => {
          const contentTypes: IEntriesState["contentTypes"] = {};
          const { contentTypeIds } = store$.value.config;
          (contentfulContentTypes as Array<ContentType>).forEach((type) => {
            const { id } = type.sys;

            if (contentTypeIds.indexOf(id) !== -1) contentTypes[id] = type;
          });

          return of(getContentTypesSuccess(contentTypes));
        }),
        catchError((error) => {
          return of(getContentTypesError(error));
        })
      );
    })
  );

const getUsersEpic: Epic = (action$, store$: StateObservable<IStoreState>) =>
  action$.pipe(
    ofType(getUsers.type),
    pluck<PayloadAction<InitConfigPayload | undefined>, "payload">("payload"),
    switchMap((payload) => {
      const {
        config: { sdk: configSdk },
      } = store$.value;

      const sdk = payload?.sdk || configSdk;

      return from(sdk.space.getUsers()).pipe(
        switchMap(({ items }) => {
          const users: { [key: string]: User } = {};

          (items as Array<User>).forEach((item): void => {
            users[item.sys.id] = item;
          });

          return of(getUsersSuccess({ users }));
        }),
        catchError((error) => {
          return of(getUsersError(error));
        })
      );
    })
  );

const initConfigEpic: Epic = (action$) =>
  action$.pipe(
    ofType(initConfig.type),
    pluck<PayloadAction<InitConfigPayload>, "payload">("payload"),
    switchMap((payload) =>
      of(
        getContentTypes(payload),
        getTags(payload),
        getUsers(payload),
        checkNotifications(payload)
      )
    )
  );

const getEntriesDataEpic: Epic = (action$) =>
  action$.pipe(
    ofType(
      getEntriesData.type,
      setEntriesPaginationEnd.type,
      setSortEnd.type,
      removeTagsPill.type
    ),
    pluck<PayloadAction<Partial<EntryRequestDTO>>, "payload">("payload"),
    switchMap((payload) => {
      return of(
        prepareEntriesPayload(payload),
        getContentTypes(),
        getTags(),
        getUsers()
      );
    })
  );

const setEntriesPaginationEpic: Epic = (
  action$,
  store$: StateObservable<IStoreState>
) =>
  action$.pipe(
    ofType(
      setEntriesPaginationStart.type,
      setEntriesFilters.type,
      setSearchEnd.type,
      setTagsFilter.type,
      setSelectedTags.type
    ),
    pluck<PayloadAction<Partial<EntriesPagination>>, "payload">("payload"),
    switchMap((payload) =>
      of(
        setEntriesPaginationEnd({
          ...payload,
          page: payload.page || 0,
          rows: payload.rows || store$.value.entries.pagination.rows,
        })
      )
    )
  );

const getTagsEpic: Epic = (action$, store$: StateObservable<IStoreState>) =>
  action$.pipe(
    ofType(getTags.type),
    pluck<PayloadAction<InitConfigPayload | undefined>, "payload">("payload"),
    switchMap((payload) => {
      const {
        config: { environmentId: configEnvironmentId, spaceId: configSpaceId },
      } = store$.value;

      const spaceId = payload?.spaceId || configSpaceId;
      const environmentId = payload?.environmentId || configEnvironmentId;

      return from(api.getTags(spaceId, environmentId)).pipe(
        switchMap(({ data }) => {
          const tags: Record<string, TagDTO> = {};

          data.forEach((tag) => {
            tags[tag.id] = tag;
          });

          return of(getTagsSuccess({ tags }));
        }),
        catchError((error) => {
          return of(getTagsError(error));
        })
      );
    })
  );

const setSortEpic: Epic = (action$, store$: StateObservable<IStoreState>) =>
  action$.pipe(
    ofType(setSortStart.type),
    pluck<PayloadAction<{ property: string }>, "payload">("payload"),
    switchMap(({ property }) => {
      const { orderBy, order } = store$.value.entries.sort;
      const isAsc = orderBy === property && order === OrderEnum.ASC;

      return of(
        setSortEnd({
          order: isAsc ? OrderEnum.DESC : OrderEnum.ASC,
          orderBy: property,
        })
      );
    })
  );

const setSearchEpic: Epic = (action$) =>
  action$.pipe(
    ofType(setSearchStart.type),
    pluck<PayloadAction<{ search: string }>, "payload">("payload"),
    debounce(() => interval(500)),
    switchMap(({ search }) => {
      return of(setSearchEnd({ search }));
    })
  );

export const entriesEpics = combineEpics(
  getEntriesEpic,
  setSortEpic,
  getContentTypesEpic,
  prepareEntriesPayloadEpic,
  parseEntriesEpic,
  setSearchEpic,
  getTagsEpic,
  getEntriesDataEpic,
  getUsersEpic,
  setEntriesPaginationEpic,
  initConfigEpic
);
