import styled from "@emotion/styled";
import { Typography } from "@material-ui/core";

export const DialogContainer = styled.div`
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  > button {
    margin: 0 8px;
    > span {
      text-transform: none !important;
      font-size: 14px;
    }
  }
  justify-content: center;
`;

export const ModalTitle = styled(Typography)`
  font-weight: bold !important;
  font-size: 16px !important;
`;

export const ModalText = styled(Typography)`
  margin: 12px 12px 12px 0 !important;
`;

export const Ul = styled.ul`
  padding-left: 0;
  li {
    list-style-type: none;
  }
`;

export const TagsDialog = styled.div(
  ({ open }: any) => `
  display: ${open ? "flex" : "none"};
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(12, 20, 28, 0.74902);
  z-index: 10000;
  justify-content: center;
  align-items: center;

  & > div {
    border-radius: 6px;
    padding: 1rem 1.5rem;
  }
`
);

export const TableTitle = styled.p`
  text-align: center;
`;

export const TreeMoreItem = styled.div`
  padding: 11px 0;
  cursor: pointer;
`;
