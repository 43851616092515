import { Box } from '@material-ui/core';
import { Dispatch } from '@reduxjs/toolkit';
import { FilterOptionsEnum } from 'enums/filterOptions';
import React, { ChangeEvent, Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { setDialog } from 'store/config/config.actions';
import { setTagsFilter, setTagsIndex } from 'store/entries/entries.actions';
import { IEntriesState } from 'store/entries/entries.interface';
import { getEntriesSelectedTagsSelector, getEntriesTagFilterSelector } from 'store/entries/entries.selectors';
import {
  EntriesTableButton,
  EntriesTableFilterPillDiv,
  EntriesTableFilterPillSelect,
  EntriesTableTagSelect,
} from './EntriesTable.styled';

interface IProps extends WithTranslation {
  title: string;
  filterOptions?: Array<{ value: FilterOptionsEnum; hideOption?: boolean }>;
  index: number;
}

interface IState {
  hideOption?: boolean;
}

interface IStateProps {
  tagFilter: IEntriesState['tagFilter'];
  selectedTags: IEntriesState['selectedTags'];
}

interface IDispatchProps {
  setTagFilter: (payload: { tagFilter: FilterOptionsEnum; index: number }) => AppDispatch;
  setDialogOpen: (payload: boolean) => AppDispatch;
  setTagIndex: (payload: { index: number }) => AppDispatch;
}

type PropType = IDispatchProps & IStateProps & IProps;

class EntriesTableTagPill extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      hideOption: true,
    };
  }

  componentDidMount() {
    const { filterOptions, tagFilter, index } = this.props;
    const filterOption = filterOptions?.find(({ value: filterValue }) => tagFilter[index] === filterValue);

    this.setState({ hideOption: filterOption?.hideOption });
  }

  componentDidUpdate(previousProps: PropType): void {
    const { tagFilter, filterOptions, index } = this.props;

    if (previousProps.tagFilter.length !== tagFilter.length) {
      const filterOption = filterOptions?.find(({ value: filterValue }) => tagFilter[index] === filterValue);

      this.setState({ hideOption: filterOption?.hideOption });
    }
  }

  parseTagsLabel = () => {
    const { selectedTags, index } = this.props;
    const tagsLength = selectedTags[index].length;

    if (!tagsLength) {
      return '';
    }

    return `${selectedTags[index]?.[0]?.label || ''}${tagsLength >= 2 ? ` and ${tagsLength - 1} more` : ''}`;
  };

  handleTagFilterChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: string;
    }>
  ) => {
    const { filterOptions, setTagFilter, index } = this.props;
    const value = event.target.value as string;

    const filterOption = filterOptions?.find(({ value: filterValue }) => value === filterValue);

    this.setState({ hideOption: filterOption?.hideOption });
    setTagFilter({ tagFilter: event.target.value as FilterOptionsEnum, index });
  };

  onClick = () => {
    const { setDialogOpen, setTagIndex, index } = this.props;

    setTagIndex({ index });
    setDialogOpen(true);
  };

  render() {
    const { title, filterOptions, tagFilter, index, t } = this.props;
    const { hideOption } = this.state;
    const label = this.parseTagsLabel();

    return (
      <Fragment>
        <EntriesTableButton variant="text" size="small" disableRipple>
          <Box padding="0 8px" fontWeight="fontWeightBold" fontSize="14px" color="#536171">
            {title}
          </Box>

          {filterOptions && (
            <EntriesTableFilterPillDiv>
              <EntriesTableFilterPillSelect value={tagFilter[index]} onChange={this.handleTagFilterChange}>
                {filterOptions.map(({ value }, index) => (
                  <option value={value} key={index}>
                    {t(`addcontent.filtersPopup.labels.${value}`)}
                  </option>
                ))}
              </EntriesTableFilterPillSelect>
            </EntriesTableFilterPillDiv>
          )}

          {!hideOption && <EntriesTableTagSelect onClick={this.onClick}>{label}</EntriesTableTagSelect>}
        </EntriesTableButton>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  selectedTags: getEntriesSelectedTagsSelector(state),
  tagFilter: getEntriesTagFilterSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setTagFilter: (payload: { tagFilter: FilterOptionsEnum; index: number }) => dispatch(setTagsFilter(payload)),
  setDialogOpen: (payload: boolean) => dispatch(setDialog(payload)),
  setTagIndex: (payload: { index: number }) => dispatch(setTagsIndex(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EntriesTableTagPill));
