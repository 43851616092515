import { Tooltip } from '@material-ui/core';
import ProgressBar from 'components/Sidebar/ProgressBar/ProgressBar';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { XTMJobDTO } from 'utils/restApplicationClient';
import { getLanguageName } from 'utils/languageUtils';
import {
  DetailColumnDiv,
  DetailRowDiv,
  DetailsDiv,
  FileNameDetails
} from './TranslationTable.styles';
import Name from "../Name/Name";

interface IProps {
  rowDetails: XTMJobDTO;
  fullscreen?: boolean;
}

export class RowDetails extends PureComponent<IProps & WithTranslation> {
  render() {
    const { rowDetails, t, fullscreen } = this.props;
    const { targetLanguages: languages } = rowDetails;
    return (
        <React.Fragment>
          <DetailsDiv>
            <DetailRowDiv>
              <DetailColumnDiv>
                <p>{t('addcontent.sourceLanguage')}</p>
                <p>{getLanguageName(rowDetails.sourceLanguage)}</p>
              </DetailColumnDiv>
              <DetailColumnDiv>
                <p>
                  {languages.length > 1
                      ? t('queue.targetLanguages')
                      : t('queue.targetLanguage')}
                </p>
                {languages.length > 1 ? (
                    <Tooltip
                        title={
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {languages.map((language, index) => (
                                <p key={index}>{getLanguageName(language)}</p>
                            ))}
                          </div>
                        }
                        data-testid="targetLanguagesTooltip"
                    >
                      <p>{getLanguageName(languages[0])}, ...</p>
                    </Tooltip>
                ) : (
                    <p>{getLanguageName(languages[0])}</p>
                )}
              </DetailColumnDiv>
              <DetailColumnDiv>
                <p>{t('queue.xtmWorkflowStep')}</p>
                {rowDetails.workflowStep ? <Name text={rowDetails.workflowStep} width={fullscreen ? 700 : 120}/> :
                    <p>'----'</p>}
              </DetailColumnDiv>
              <DetailColumnDiv>
                {( rowDetails.dueDate && (
                    <Fragment>
                      <p>{t('queue.dueDate')}</p>
                      <p>{moment(rowDetails.dueDate).format('LL')}</p>
                    </Fragment>
                ) ) || (
                    <Fragment>
                      <p>{t('queue.addedDate')}</p>
                      <p>{moment(rowDetails.createdAt).format('LL')}</p>
                    </Fragment>
                )}
              </DetailColumnDiv>
            </DetailRowDiv>
            <DetailRowDiv>
              <DetailColumnDiv>
                <FileNameDetails>
                  <Name text={rowDetails.filename} width={fullscreen ? 700 : 300}/>
                </FileNameDetails>
              </DetailColumnDiv>
            </DetailRowDiv>
            <ProgressBar value={rowDetails.jobProgress || 0}/>
          </DetailsDiv>
        </React.Fragment>
    );
  }
}

export default withTranslation()(RowDetails);
