import { Box, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { versionDigits } from 'utils/versionDigits';
import { Logout } from './Buttons/Logout';
import { Manual } from './Buttons/Manual';
import { ReleaseNotes } from './Buttons/ReleaseNotes';
import { StyledAppbar } from './CustomAppbar.styled';

interface IProps {
  onIconClick?: () => void;
  open?: boolean;
  handleLogout: () => void;
  fullscreen?: boolean;
}

type PropType = IProps & WithTranslation;

class CustomAppbar extends PureComponent<PropType> {
  handleOpenLink = (url: string) => (): void => {
    window.open(url, '_blank');
  };

  render() {
    const { t, open, onIconClick, handleLogout, fullscreen } = this.props;
    return (
      <StyledAppbar position="relative" color="primary">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" flexDirection="row" alignItems="center">
            {t('common.connectPanel')}
            {!fullscreen && (
              <IconButton data-testid="collapse" size="small" onClick={onIconClick}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </Box>
          <Box>
            {fullscreen && (
              <>
                <ReleaseNotes onClick={this.handleOpenLink} />
                <Manual onClick={this.handleOpenLink} />{' '}
              </>
            )}
            <Logout onClick={handleLogout} />
          </Box>
        </Box>
        <Box fontSize="12px">{t('common.version', { version: versionDigits(process.env.REACT_APP_VERSION) })}</Box>
      </StyledAppbar>
    );
  }
}

export default withTranslation()(CustomAppbar);
