import { AxiosError } from 'axios';
import { PageExtensionSDK, SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';
import { clearToken } from './axiosInstance';
import i18n from './initTranslation';

export interface ApiError {
  args: Array<Object>;
  errorMessage: string;
  errorCode: string;
}

export const apiErrorHandler = (reason: AxiosError<ApiError>, sdk: SidebarExtensionSDK | PageExtensionSDK): any => {
  if (reason.isAxiosError) {
    if (reason.response?.status === 403) {
      sdk.notifier.error(i18n.t('errormessage.unauthorized'));
      clearToken();
    } else if (Array.isArray(reason.response?.data)) {
      reason.response?.data.forEach((err) => {
        if (err.errorCode && /errormessage\.contentful.(source|target)languages?error/.test(err.errorCode)) {
          const params: Array<string> = [];
          if (Array.isArray(err.args[0])) {
            params.push(err.args[0].join(', '));
          } else {
            params.push(err.args[0] as string);
          }
          params.push(err.args[1] as string);
          sdk.notifier.error(
            i18n.t(err.errorCode, {
              param1: params[0],
              param2: params[1],
            })
          );
        } else {
          sdk.notifier.error(i18n.t(err.errorCode || 'errormessage.somethingWrong'));
          if (err.errorCode === 'errormessage.unauthorized') {
            clearToken();
          }
        }
      });
    } else {
      sdk.notifier.error(i18n.t(reason.response?.data.errorCode || 'errormessage.somethingWrong'));
    }
  } else {
    sdk.notifier.error(i18n.t('errormessage.somethingWrong'));
  }

  return reason;
};
