import { Button, ButtonProps } from "@material-ui/core";
import { DialogExtensionSDK } from "contentful-ui-extensions-sdk";
import { ButtonVariantEnum } from "enums/buttonVariant";
import { DialogParamEnum } from "enums/dialogParam";
import { DialogVariantEnum } from "enums/dialogVariant";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import MultipleEntriesUpdateDialog from "./CustomVariants/MultipleEntriesUpdateDialog";
import MultipleErrorsDialog from "./CustomVariants/MultipleErrorsDialog";
import SingleCancelledUpdateDialog from "./CustomVariants/SingleCancelledUpdateDialog";
import SingleEntryUpdateDialog from "./CustomVariants/SingleEntryUpdateDialog";
// import TranslationTableData from "./CustomVariants/TranslationTableDialog";
import {
  ButtonContainer,
  DialogContainer,
  ModalText,
  ModalTitle,
  Ul,
} from "./Dialog.styled";
import ManageReferencesDialog from "./CustomVariants/ManageReferenceDialog/ManageReferencesDialog";

interface DialogProps {
  sdk: DialogExtensionSDK;
}

type PropType = DialogProps & WithTranslation;

class Dialog extends Component<PropType> {
  componentDidMount() {
    this.props.sdk.window.updateHeight();
  }

  breakLines(text: string): Array<JSX.Element> {
    return text.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  }

  handleQuit = (param?: DialogParamEnum) => () => {
    this.props.sdk.close(param);
  };

  applyVariant(variant: ButtonVariantEnum): ButtonProps | null {
    switch (variant) {
      case ButtonVariantEnum.QUIT: {
        return {
          variant: "outlined",
          onClick: this.handleQuit(DialogParamEnum.CANCEL),
        };
      }
      case ButtonVariantEnum.OK: {
        return {
          variant: "contained",
          color: "secondary",
          onClick: this.handleQuit(DialogParamEnum.CANCEL),
        };
      }
      case ButtonVariantEnum.UPDATE: {
        return { variant: "outlined", onClick: this.handleQuit() };
      }
      case ButtonVariantEnum.NEW: {
        return {
          variant: "contained",
          color: "secondary",
          onClick: this.handleQuit(),
        };
      }
      case ButtonVariantEnum.SEND: {
        return {
          variant: "contained",
          color: "secondary",
          onClick: this.handleQuit(),
        };
      }
      case ButtonVariantEnum.CONFIRM: {
        return {
          variant: "contained",
          color: "secondary",
          onClick: this.handleQuit(DialogParamEnum.CONFIRM),
        };
      }
      default: {
        return null;
      }
    }
  }
  renderList = (data: string[]) => {
    const { t } = this.props;
    return (
      <Ul>
        {data
          .filter((value: string) => value !== undefined)
          .map((value: string, index: string | number | undefined) => (
            <li key={index}>{t(`${(value as string).toLowerCase()}`)}</li>
          ))}
      </Ul>
    );
  };
  renderBody = (): JSX.Element => {
    const { t, sdk } = this.props;
    const { variant, text, data } = sdk.parameters.invocation as any;
    switch (variant) {
      case DialogVariantEnum.MULTIPLE_UPDATE:
        return <MultipleEntriesUpdateDialog sdk={sdk} data={data} />;
      case DialogVariantEnum.SINGLE_UPDATE:
        return <SingleEntryUpdateDialog sdk={sdk} data={data} />;
      case DialogVariantEnum.MULTIPLE_ERRORS:
        return <MultipleErrorsDialog sdk={sdk} data={data} />;
      case DialogVariantEnum.SINGLE_CANCELLED:
        return <SingleCancelledUpdateDialog sdk={sdk} />;
      case DialogVariantEnum.MANAGE_REFERENCES:
        return <ManageReferencesDialog sdk={sdk} data={data} />;
      // case DialogVariantEnum.TABLE_VIEW:
      // return <TranslationTableData sdk={sdk} data={data} />;
      default:
        return (
          <>
            <ModalText color="textPrimary" variant="body2">
              {text ? this.breakLines(t(text)) : null}
              {data ? this.renderList(data) : null}
            </ModalText>
            <ButtonContainer>{this.renderButtons()}</ButtonContainer>
          </>
        );
    }
  };

  renderButtons = (): Array<JSX.Element> | null => {
    const { t } = this.props;
    const params = this.props.sdk.parameters.invocation;
    if (params && params.hasOwnProperty("buttons")) {
      return (
        params as Record<
          string,
          Array<{ label: string; variant: ButtonVariantEnum }>
        >
      ).buttons.map((button, key) => {
        return (
          <Button size="small" {...this.applyVariant(button.variant)} key={key}>
            {t(button.label)}
          </Button>
        );
      });
    }
    return null;
  };

  render() {
    const { t } = this.props;
    const { title, title2 } = this.props.sdk.parameters.invocation as Record<
      string,
      string | undefined
    >;
    return (
      <DialogContainer data-testid={"dialog"}>
        <ModalTitle color="textPrimary">
          {title ? t(title) : null}
          {title2 ? " - " + t(title2) : null}
        </ModalTitle>
        {this.renderBody()}
      </DialogContainer>
    );
  }
}

export default withTranslation()(Dialog);
