import styled from '@emotion/styled';

export const SelectRowsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px;
  > p {
    margin-right: 8px;
  }
`;

export const TableSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  flex-direction: column;
`;
