import { css } from '@emotion/core';

export const formHeaderStyle = css`
    text-align: center;
`;

export const loginButtonStyle = css`
    margin-top: 30px !important;
`

export const labelColorStyle = css`
    color: #d4d4d4;
`

export const loginWrapperStyle = css`
    padding: 30px;
    h2 {
        color: rgb(83, 97, 113);
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        line-height: 1.5;
    }
`