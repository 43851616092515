import { Global } from "@emotion/core";
import { ThemeProvider } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import Page from "components/Page/Page";
import {
  AppExtensionSDK,
  BaseExtensionSDK,
  DialogExtensionSDK,
  init,
  locations,
  PageExtensionSDK,
  SidebarExtensionSDK,
} from "contentful-ui-extensions-sdk";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "store";
import axiosInstance, { initializeApi } from "utils/axiosInstance";
import { checkVersion } from "utils/checkVersion";
import Config from "./components/Config/ConfigScreen";
import Dialog from "./components/Dialog/Dialog";
import Sidebar from "./components/Sidebar/Sidebar";
import "./index.css";
import { mainStyles } from "./main.style";
import { theme } from "./theme";
import { initTranslation } from "./utils/initTranslation";

Sentry.init({
  dsn: (window as any)._env_?.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

initTranslation();

init(async (sdk: BaseExtensionSDK) => {
  await checkVersion();

  initTranslation();

  initializeApi();

  const root = document.getElementById("root");

  const ComponentLocationSettings = [
    {
      location: locations.LOCATION_APP_CONFIG,
      component: (
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Global styles={mainStyles} />
            <Config sdk={sdk as unknown as AppExtensionSDK} />
          </ThemeProvider>
        </Provider>
      ),
    },
    {
      location: locations.LOCATION_DIALOG,
      component: (
        <ThemeProvider theme={theme}>
          <Global styles={mainStyles} />
          <Dialog sdk={sdk as unknown as DialogExtensionSDK} />
        </ThemeProvider>
      ),
    },
    {
      location: locations.LOCATION_ENTRY_SIDEBAR,
      component: (
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Global styles={mainStyles} />
            <Sidebar sdk={sdk as unknown as SidebarExtensionSDK} />
          </ThemeProvider>
        </Provider>
      ),
    },
    {
      location: locations.LOCATION_PAGE,
      component: (
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Global styles={mainStyles} />
            <Page sdk={sdk as unknown as PageExtensionSDK} fullscreen />
          </ThemeProvider>
        </Provider>
      ),
    },
  ];

  // Select a component depending on a location in which the app is rendered.
  //
  // NB: Location "app-config" is auto-included in the list as most apps need it
  // You can remove it (and on the app definition also) in case the app
  // doesn't require it
  ComponentLocationSettings.forEach((componentLocationSetting) => {
    if (sdk.location.is(componentLocationSetting.location)) {
      render(componentLocationSetting.component, root);
    }
  });
});
