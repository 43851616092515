import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { from, of, timer } from 'rxjs';
import { switchMap, pluck, debounce, catchError } from 'rxjs/operators';
import { IStoreState } from 'store';
import { InitConfigPayload } from 'store/config/config.interface';
import { api } from 'utils/axiosInstance';
import i18n from 'utils/initTranslation';
import { checkNotifications } from './notification.actions';

const checkNotificationEpic: Epic = (
  action$,
  store$: StateObservable<IStoreState>
) =>
  action$.pipe(
    ofType(checkNotifications.type),
    pluck<PayloadAction<InitConfigPayload | undefined>, 'payload'>('payload'),
    debounce((payload) => (!!payload ? timer(0) : timer(1000 * 60))),
    switchMap((payload) => {
      const {
        config: { sdk: configSdk },
      } = store$.value;

      const sdk = payload?.sdk || configSdk;

      return from(api.getAllNotifications()).pipe(
        switchMap(({ data }) => {
          const errorArray = data.filter(
            ({ notificationType }) =>
              notificationType === 'ASYNCHRONOUS_TRANSLATION_ERROR'
          );

          if (errorArray.length > 6) {
            sdk.notifier.error(
              i18n.t('errormessage.notifications.multipleErrors')
            );

            return of(checkNotifications());
          }

          errorArray.forEach(({ notificationType, entryId, sourceLanguage, targetLanguages }) => {
            sdk.notifier.error(
              i18n.t(`errormessage.notifications.${notificationType}`, {
                entryId,
                sourceLanguage,
                targetLanguages,
              })
            );
          });
          return of(checkNotifications());
        }),
        catchError(() => of(checkNotifications()))
      );
    })
  );

export const notificationEpics = combineEpics(checkNotificationEpic);
