import styled from '@emotion/styled';
import { Card } from '@material-ui/core';

export const TopBar = styled.div`
  height: 60px;
  background: #2e3645;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const ConfigCard = styled(Card)`
  margin: auto;
  min-height: 500px;
  height: 85vh;
  width: 460px;
  padding: 30px;
  position: absolute;
  top: 3vh;
  left: calc(50% - 260px);
`;

export const ConfigLogoWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: calc(50% - 64px);
`;

export const StyledLinkDiv = styled.div`
  margin-top: 12px;
  > a p {
    font-weight: bold !important;
  }
  text-align: start;
  width: 100%;
`;
