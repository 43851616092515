import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import { DialogVariantEnum } from 'enums/dialogVariant';
import { StatusEnum } from 'enums/status';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { apiErrorHandler } from 'utils/apiErrorHandler';
import { api, urlStorageKey } from 'utils/axiosInstance';
import { XTMJobDTO } from 'utils/restApplicationClient';
import { TranslateRequestDTO } from 'utils/restApplicationClientTypeOverrides';
import StatusMenu from '../Menu/StatusMenu';
import Name from '../Name/Name';
import Status from '../Status/Status';
import TargetLanguages from '../TargetLanguages/TargetLanguages';
import RowDetails from './RowDetails';
import {
  CellNoDivider,
  CellNoDividerPointer,
  DetailsRow,
  StatusCellWrapper,
  WarningIcon,
} from './TranslationTable.styles';

const buttons: Array<{ label: string; variant: ButtonVariantEnum }> = [
  { label: 'modal.ok', variant: ButtonVariantEnum.OK },
];

const buttonsMultipleJobs: Array<{
  label: string;
  variant: ButtonVariantEnum;
}> = [
  { label: 'modal.sendOne', variant: ButtonVariantEnum.QUIT },
  { label: 'modal.sendAll', variant: ButtonVariantEnum.CONFIRM },
];

interface IProps {
  row: XTMJobDTO;
  setJobs: (forceRefresh?: boolean) => void;
  sdk: SidebarExtensionSDK;
  fullscreen?: boolean;
  setSpinner: (spinner: boolean) => void;
}

interface IState {
  open: boolean;
}

type PropType = IProps & WithTranslation;

export class Row extends Component<PropType, IState> {
  toggleOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleErrorOpen = (error: string) => () => {
    const { t, row, setJobs, sdk, setSpinner } = this.props;
    if (error === 'errormessage.job.content') {
      sdk.dialogs
        .openCurrent({
          shouldCloseOnOverlayClick: true,
          shouldCloseOnEscapePress: true,
          parameters: {
            title: t('common.warning'),
            variant: DialogVariantEnum.SINGLE_UPDATE,
            data: error,
          },
        })
        .then((res) => {
          setSpinner(true);
          if (res === null) {
            api
              .validate(row.id, {
                errorMessageCode: 'errormessage.job.content',
              })
              .then(() => {
                setJobs(true);
              })
              .catch((error) => {
                setSpinner(false);
                apiErrorHandler(error, sdk);
              });
          } else if (res === 'errormessage.job.content') {
            api
              .translate(this.getTranslationParameters(true))
              .catch((error) => {
                apiErrorHandler(error, sdk);
              })
              .finally(() => {
                setJobs(true);
              });
          } else if (Array.isArray(res)) {
            api
              .translate(this.getTranslationParameters(false))
              .then(() =>
                api.validate(row.id, {
                  errorMessageCode: 'errormessage.job.content',
                })
              )
              .catch((error) => {
                apiErrorHandler(error, sdk);
              })
              .finally(() => {
                setJobs(true);
              });
          } else {
            setJobs(true);
          }
        });
    } else if (error === 'errormessage.contentful.notfound') {
      sdk.dialogs
        .openCurrent({
          width: 400,
          parameters: {
            title: t('common.warning'),
            variant: DialogVariantEnum.SINGLE_CANCELLED,
          },
        })
        .then((res) => {
          setSpinner(true);
          if (res === ButtonVariantEnum.QUIT) {
            api
              .validate(row.id, {
                errorMessageCode: 'errormessage.contentful.notfound',
              })
              .then(() => {
                setJobs(true);
              })
              .catch((error) => {
                setSpinner(false);
                apiErrorHandler(error, sdk);
              });
          } else if (res === ButtonVariantEnum.CONFIRM) {
            api
              .findJobsIdsByOperationType(row.id, { jobOperation: 'CANCEL' })
              .then(({ data }) => {
                if (data.length > 1) {
                  return sdk.dialogs
                    .openCurrent({
                      width: 400,
                      parameters: {
                        title: 'modal.warning',
                        text: 'modal.multipleJobs',
                        buttons: buttonsMultipleJobs,
                      },
                    })
                    .then((res) => {
                      let ids = [row.id];
                      if (res === ButtonVariantEnum.CONFIRM) {
                        ids = data;
                      }
                      return api
                        .performOperation({
                          jobsIds: ids,
                          operation: 'CANCEL',
                        })
                        .then(() =>
                          api
                            .performOperation({
                              jobsIds: ids,
                              operation: 'DELETE',
                            })
                            .finally(() => {
                              setJobs(true);
                            })
                        );
                    });
                } else {
                  return api
                    .performOperation({
                      jobsIds: [row.id],
                      operation: 'CANCEL',
                    })
                    .then(() =>
                      api
                        .performOperation({
                          jobsIds: [row.id],
                          operation: 'DELETE',
                        })
                        .finally(() => {
                          setJobs(true);
                        })
                    );
                }
              })
              .catch((error) => {
                setSpinner(false);
                apiErrorHandler(error, sdk);
              });
          }
        });
    } else {
      this.props.sdk.dialogs.openCurrent({
        width: 400,
        parameters: {
          title: 'common.warning',
          title2: error,
          data: row.errorMessagesDetails,
          buttons,
        },
      });
    }
  };

  getTranslationParameters = (update: boolean): TranslateRequestDTO => {
    const { sdk, row } = this.props;
    return {
      contentfulUsername: sdk.user.email,
      customerId: row.customerId,
      dueDate: row.dueDate,
      entries: { [update ? row.projectId : 'new']: [row.entryId] },
      environmentId: sdk.ids.environment,
      serverUrl: localStorage.getItem(urlStorageKey) || '',
      sourceLanguage: row.sourceLanguage,
      targetLanguages: row.targetLanguages,
      spaceId: sdk.ids.space,
      templateId: row.templateId,
      projectDescription: "",
      projectName: "",
      mergeFilesCheckbox: false,
    };
  };

  tooltipTranslation = () => {
    const { t } = this.props;
    return t('queue.fetched');
  };

  state = { open: false };
  render() {
    const { open } = this.state;
    const { row, setJobs, sdk, fullscreen } = this.props;
    return (
      <React.Fragment>
        <TableRow>
          <CellNoDivider>
            <IconButton
              size="small"
              onClick={this.toggleOpen}
              data-testid="collapseButton"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </CellNoDivider>
          <CellNoDividerPointer onClick={this.toggleOpen}>
            <TargetLanguages languages={row.targetLanguages} length={2} />
            <Name text={row.projectName} width={fullscreen ? 700 : 120} />
          </CellNoDividerPointer>
          <CellNoDivider>
            <StatusCellWrapper>
              {row.errorMessage && (
                <IconButton
                  data-testid="errorButton"
                  size="small"
                  onClick={this.handleErrorOpen(row.errorMessage)}
                >
                  <ErrorIcon color="error" fontSize="small" />
                </IconButton>
              )}
              {row.status === StatusEnum.FETCHED && !row.errorMessage && (
                <Tooltip title={this.tooltipTranslation()}>
                  <IconButton data-testid="errorButton" size="small">
                    <WarningIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <Status
                status={row.status}
                error={
                  row.errorMessage === 'errormessage.xtm.internalservererror' ||
                  row.errorMessage ===
                    'errormessage.job.sendfortranslationunsuccessful'
                }
              />
              <StatusMenu
                jobId={row.id}
                status={row.status}
                setJobs={setJobs}
                sdk={sdk}
              />
            </StatusCellWrapper>
          </CellNoDivider>
        </TableRow>
        <DetailsRow>
          <TableCell padding="none" colSpan={3}>
            <Collapse in={open} unmountOnExit data-testid="collapseDiv">
              <RowDetails rowDetails={row} fullscreen={fullscreen} />
            </Collapse>
          </TableCell>
        </DetailsRow>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Row);
