/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextField } from '@material-ui/core';
import { Component } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { customFieldStyles } from './CustomField.style';

interface IProps {
  label: string | JSX.Element;
  error?: string;
  touched?: boolean;
  inputProps: FieldInputProps<unknown, HTMLElement>;
  type?: string;
  testId?: string;
  disableAutoComplete?: boolean;
}

export class CustomField extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const {
      t,
      error,
      touched,
      type,
      testId,
      inputProps,
      disableAutoComplete,
      label
    } = this.props;

    const shouldErrorDisplay = typeof error === 'boolean' || (error && touched);

    return (      
      <div css={customFieldStyles.root}>
        { typeof label === 'string' ? <span css={customFieldStyles.label}>{t(label)}</span> : label  }
        <TextField
          className={error && touched ? 'outLineError' : ''}
          variant="outlined"
          margin="dense"
          inputProps={{ ...inputProps, 'data-testid': testId }}
          type={type}
          autoComplete={disableAutoComplete ? 'new-password' : type}
        />
        {shouldErrorDisplay && (
          <span className="textError" data-testid={`${testId}Error`}>
            {typeof error !== 'boolean' && t(error as string)}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomField);
