import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Typography } from "@material-ui/core";

export const selectStyles = {
  root: css`
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  button: css`
    text-transform: none !important;
    margin: 12px !important;
    width: 40%;
    line-height: 1.3 !important;
  `,
  select: css`
    background-color: white;
  `,
  outlineError: css`
    background-color: white;
    fieldset {
      border-color: red !important;
      border-width: 2px;
    }
  `,
  textError: css`
    color: red;
    text-align: start;
    font-style: oblique;
    max-width: 312px;
    margin-top: 4px;
    font-size: 14px;
  `,
  listBox: css`
    max-width: 312px;
    overflow: hidden;
    > li {
      display: block;
      text-overflow: ellipsis;
      max-width: 312px;
      overflow: hidden;
      white-space: nowrap;
    }
  `,
};

export const ButtonWrapper = styled.div`
  margin: 80px 0 15px 0;
  border-top: 1px solid rgba(120, 120, 120, 0.2);
`;
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ManageReferenceButtonWrapper = styled.div<{
  fullscreen?: boolean;
}>`
  padding-top: 18px;
  display: flex;
  flex-direction: row;

  > button {
    width: 100%;
    padding: ${({ fullscreen }) => (fullscreen ? "0.5rem" : "0.75rem")};
  }
`;

export const CustomTypography = styled(Typography)`
  margin: 6px 0 !important;
`;

export const TitleTypography = styled(Typography)`
  margin-top: 12px !important;
  font-weight: bold !important;
`;

export const CustomEndAdornment = styled.div`
  position: absolute;
  right: 12px;
`;
