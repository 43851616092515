import { ReferenceDTO } from "./restApplicationClient";

export const getAllNodesIds = (data: ReferenceDTO[]): string[] => {
  const traverse = (node: ReferenceDTO): string[] => {
    if (!node?.isEnabled) {
      if (node?.references) {
        return [...node?.references?.flatMap(traverse)];
      }
      return [];
    }

    if (!node.references) {
      return [node.id];
    }
    return [node.id, ...node?.references?.flatMap(traverse)];
  };

  return Array.from(new Set(data.flatMap(traverse) ?? []));
};
