import styled from '@emotion/styled';
import { Button, Select, TableCell, TextField } from '@material-ui/core';
import { Table } from 'react-virtualized';
import { HeaderCell } from '../TranslationQueue/TranslationTable/TranslationTable.styles';

export const EntriesTableSelect = styled(Select)`
  padding: 0 !important;
  background-color: #2e75d4 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  > div {
    background-color: #2e75d4 !important;
  }
`;

export const EntriesTableTagSelect = styled.div`
  white-space: nowrap;
  height: 19px;
  padding: 6px;
  background-color: #2e75d4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  font-size: 14px;
  display: block;
  align-items: center;
  max-width: 800px;
  min-width: 100px;
  text-overflow: ellipsis;
  line-height: 19px;
`;

export const EntriesTableButton = styled(Button)`
  text-transform: none !important;
  padding: 0 !important;
  margin: 4px !important;
  height: 100%;
  background-color: #e5ebed !important;
  > span fieldset {
    border: none !important;
  }
  > span svg {
    color: #fff !important;
  }
`;

export const EntriesTableTextField = styled(TextField)`
  background-color: white;
  display: flex;
  min-width: 280px;
  margin: 4px !important;
  flex-grow: 1;
  > div {
    padding-left: 4px !important;
    > input {
      font-size: 14px;
      padding: 8px !important;
      width: auto;
      flex-grow: 1;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
`;

export const EntriesTableTableContainer = styled.div`
  height: 500px;
  width: 900px;
  display: block;
`;

export const EntriesTablePaginationContainer = styled.div`
  border: 1px solid rgba(120, 120, 120, 0.2);
`;

export const EntriesTableBorderTable = styled(Table)`
  border: 1px solid rgba(120, 120, 120, 0.2);

  .ReactVirtualized__Table__rowColumn {
    margin-right: 0;
  }
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 0;
  }
  .ReactVirtualized__Table__headerRow {
    text-transform: none;
  }
  .ReactVirtualized__Table__headerColumn:first-of-type {
    margin-left: 0;
  }
  .ReactVirtualized__Table__headerColumn {
    margin-right: 0;
    background-color: #f6f9fa;
  }
`;

export const EntriesTableTableCell = styled(TableCell)`
  display: flex !important;
  box-sizing: border-box !important;
  height: 75px;
  justify-content: flex-start;
`;

export const EntriesTableHeaderCell = styled(HeaderCell)`
  display: flex !important;
  box-sizing: border-box !important;
  height: 75px;
  justify-content: flex-start;
  align-items: center;
`;

export const EntriesTableWithPaginationContainer = styled.div`
  position: relative;
`;

export const EntriesTableSpinnerOverlay = styled.div`
  position: absolute;
  background-color: rgba(200, 200, 200, 0.4);
  display: flex;
  bottom: -52px;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const EntriesTableFilterPillDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 31px;
  padding-right: 6px;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 50%;
    left: 0px;
    background-color: rgb(231, 235, 238);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 50%;
    right: 0px;
    background-color: #2e75d4;
  }
`;
export const EntriesTableFilterPillSelect = styled.select`
  color: rgb(3, 111, 227);
  min-width: 101px;
  max-width: 200px;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  text-align-last: center;
  appearance: none;
  border-radius: 10em;
  padding: 3px 8px;
  border: 1px solid transparent;
  background-color: white;
  z-index: 10;
`;
