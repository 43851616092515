/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CircularProgress, Collapse } from "@material-ui/core";
import CustomAppbar from "components/Appbar/CustomAppbar";
import LoginForm from "components/Config/Form/Login";
import { WindowPortal } from "components/Config/WindowPortal/WindowPortal";
import TabsPanel from "components/TabsPanel/TabsPanel";
import { SidebarExtensionSDK } from "contentful-ui-extensions-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginSuccess } from "store/auth/auth.actions";
import {
  clearToken,
  getAuthToken,
  refreshTokenTimeout,
} from "utils/axiosInstance";
import { getContentfulIds } from "utils/initConfig";
import FormContainer from "./AddContent/FormContainer";
import { ConnectButton, SpinnerDiv } from "./Sidebar.styled";
import TranslationQueue from "./TranslationQueue/TranslationQueue";

interface SidebarProps {
  sdk: SidebarExtensionSDK;
  fullscreen?: boolean;
}

type IProps = SidebarProps;

const Sidebar = (props: IProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showWindowPortal, setShowWindowPortal] = useState<boolean>(false);
  const [fetchFinished, setFetchFinished] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    clearToken();
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const { sdk } = props;

    sdk.window.startAutoResizer();

    async function refreshAsync() {
      if (localStorage.getItem('refreshing') === 'true') {
        while (localStorage.getItem('refreshing') === 'true') {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        setIsLoggedIn(Boolean(getAuthToken()));
        setFetchFinished(true);
      }
      else {
        localStorage.setItem('refreshing', 'true');
        await refreshTokenTimeout().finally(() => {
          setIsLoggedIn(Boolean(getAuthToken()));
          setFetchFinished(true);
          localStorage.setItem('refreshing', 'false');
        });
      }
    }

    refreshAsync();

  }, [props.sdk]);

  useEffect(() => {
    const onLoginSuccess = async () => {
      // console.log(await getContentfulIds(props.sdk));
      dispatch(loginSuccess(await getContentfulIds(props.sdk)));
    };

    if (isLoggedIn) {
      // console.log("onLoginSuccess");
      onLoginSuccess();
    }
  }, [isLoggedIn, props.sdk, dispatch]);

  const { t } = useTranslation();

  const toggleOpen = () => {
    props.sdk.window.stopAutoResizer();
    setTimeout(() => {
      props.sdk.window.startAutoResizer();
    }, 600);
    if (!open) {
      props.sdk.window.updateHeight(800);
    }
    setOpen(!open);
  };

  const handleWindowClose = () => {
    setShowWindowPortal(false);
  };

  const handleClose = () => {
    setIsLoggedIn(true);
    setShowWindowPortal(false);
  };

  const handleOpen = () => {
    setShowWindowPortal(true);
  };

  return (
    <React.Fragment>
      {!fetchFinished && (
        <SpinnerDiv>
          <CircularProgress color="secondary" />
        </SpinnerDiv>
      )}
      {fetchFinished &&
        (isLoggedIn ? (
          <React.Fragment>
            <CustomAppbar
              open={open}
              onIconClick={toggleOpen}
              handleLogout={handleLogout}
              fullscreen={props.fullscreen}
            />
            <Collapse in={open} timeout={500}>
              <TabsPanel
                tabs={[
                  {
                    labelKey: "common.tab.addContentTab",
                    component: (
                      <FormContainer
                        sdk={props.sdk}
                        fullscreen={props.fullscreen}
                      />
                    ),
                  },
                  {
                    labelKey: "common.tab.translationQueue",
                    component: <TranslationQueue sdk={props.sdk} />,
                  },
                ]}
              />
            </Collapse>
          </React.Fragment>
        ) : (
          <ConnectButton
            data-testid="connect"
            onClick={handleOpen}
            color="secondary"
            variant="contained"
            fullWidth
          >
            {t("common.connectToXTM")}
          </ConnectButton>
        ))}
      {showWindowPortal && (
        <WindowPortal closePortal={handleWindowClose}>
          <LoginForm handleClose={handleClose} />
        </WindowPortal>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
