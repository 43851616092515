import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IEntriesState } from './entries.interface';

export const entriesStateSelector = ({ entries }: AppState): IEntriesState => entries;

export const getEntriesSelector = createSelector(entriesStateSelector, ({ entries }) => entries);

export const getParsedEntriesSelector = createSelector(entriesStateSelector, ({ parsedEntries }) => parsedEntries);

export const getEntriesPaginationSelector = createSelector(entriesStateSelector, ({ pagination }) => pagination);

export const getEntriesSortSelector = createSelector(entriesStateSelector, ({ sort }) => sort);

export const getEntriesContentTypesSelector = createSelector(entriesStateSelector, ({ contentTypes }) => contentTypes);

export const getEntriesFiltersSelector = createSelector(entriesStateSelector, ({ filters }) => filters);

export const getEntriesSearchSelector = createSelector(entriesStateSelector, ({ search }) => search);

export const getEntriesTagsSelector = createSelector(entriesStateSelector, ({ tags }) => tags);

export const getEntriesTagFilterSelector = createSelector(entriesStateSelector, ({ tagFilter }) => tagFilter);

export const getEntriesSelectedTagsSelector = createSelector(entriesStateSelector, ({ selectedTags }) => selectedTags);

export const getEntriesSelectedTagIndexSelector = createSelector(entriesStateSelector, ({ selectedTagIndex }) => selectedTagIndex);
