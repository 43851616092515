import styled from '@emotion/styled';
import { AppBar, Button } from '@material-ui/core';

export const StyledAppbar = styled(AppBar)`
  display: flex;
  flex-direction: column !important;
  align-items: baseline;
  justify-content: space-between;
  padding: 4px 12px 12px 12px;
  font-weight: 500;
  box-shadow: none !important;
  font-size: 14px;

  button {
    color: white;

    > span {
      font-size: 14px;

      > svg {
        font-size: 18px;
        margin-right: 4px;
      }
    }
  }
`;

export const HeaderButton = styled(Button)`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff !important;
  transition: opacity 0.2s !important;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 20px;
    height: 20px;
    padding: 0 4px 0 0;
  }
`;
