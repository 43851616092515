import React from "react";
import {
  ContentNameWrapper,
  LabelWrapper,
  SyncIconWrapper,
  TreeNodeChildrenParent,
  TreeNodeChildrenWrapper,
  TreeNodeWrapper,
  WrapperWithHorizontalLine,
} from "../../CustomVariants.styled";
import { green, grey } from "@material-ui/core/colors";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import { useTranslation } from "react-i18next";
import { ReferenceDTO } from "utils/restApplicationClient";
import { TreeMoreItem } from "components/Dialog/Dialog.styled";

export interface TreeNodeProps extends ReferenceDTO {
  selectedNodes?: string[] | null;
  setSelectedNodes?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
    "&.Mui-disabled": {
      color: grey[500],
    },
  },

  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const TreeNode: React.FC<TreeNodeProps> = ({
  id,
  name,
  references,
  contentType,
  setSelectedNodes,
  selectedNodes,
  alreadyReferenced,
  hasMoreReferences,
  isEnabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip
        title={
          isEnabled || hasMoreReferences
            ? ""
            : (t("modal.disabledReferenceTooltip") as string)
        }
        placement="bottom-start"
      >
        <TreeNodeWrapper isEnabled={isEnabled || hasMoreReferences}>
          {hasMoreReferences ? (
            <Tooltip
              arrow
              title={t("modal.hasMoreReferencesTooltip") as string}
            >
              <TreeMoreItem>{t("modal.hasMoreReferencesText")}</TreeMoreItem>
            </Tooltip>
          ) : (
            <FormControlLabel
              data-testid={`entryCheckbox-${id}`}
              control={
                <>
                  <GreenCheckbox
                    color="secondary"
                    disabled={!isEnabled}
                    checked={
                      (selectedNodes?.some((itemId) => itemId === id) &&
                        isEnabled) ??
                      false
                    }
                    onChange={() =>
                      setSelectedNodes &&
                      setSelectedNodes((prev) => {
                        if (prev) {
                          const isInArray = prev.some(
                            (itemId) => itemId === id
                          );

                          if (isInArray) {
                            return prev.filter((itemId) => itemId !== id);
                          }

                          return [...prev, id];
                        }
                        return undefined;
                      })
                    }
                  />
                </>
              }
              label={
                <LabelWrapper>
                  {alreadyReferenced && (
                    <SyncIconWrapper>
                      <Tooltip
                        arrow
                        title={t("modal.entryAlreadyReferenced") as string}
                      >
                        <SyncIcon fontSize="small" />
                      </Tooltip>
                    </SyncIconWrapper>
                  )}
                  <b>{contentType}</b>
                  <ContentNameWrapper>{` (${name})`}</ContentNameWrapper>
                </LabelWrapper>
              }
            />
          )}
        </TreeNodeWrapper>
      </Tooltip>
      {references && (
        <TreeNodeChildrenParent>
          {references.map((child, idx) => (
            <TreeNodeChildrenWrapper
              key={`${id}-${idx}-${new Date().getMilliseconds().toString()}`}
            >
              <WrapperWithHorizontalLine isLast={references.length - 1 === idx}>
                <TreeNode
                  {...child}
                  selectedNodes={selectedNodes}
                  setSelectedNodes={setSelectedNodes}
                />
              </WrapperWithHorizontalLine>
            </TreeNodeChildrenWrapper>
          ))}
        </TreeNodeChildrenParent>
      )}
    </>
  );
};

export default TreeNode;
