/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { ButtonContainer } from 'components/Dialog/Dialog.styled';
import { DialogExtensionSDK } from 'contentful-ui-extensions-sdk';
import { ChangeEvent, Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { XTMProjectEntriesVerifyDTO } from 'utils/restApplicationClient';
import { DialogContent } from './CustomVariants.styled';

interface IProps {
  sdk: DialogExtensionSDK;
  data: Array<XTMProjectEntriesVerifyDTO>;
}

interface IState {
  entriesToUpdate: Array<XTMProjectEntriesVerifyDTO>;
}

type TProps = IProps & WithTranslation;

export class MultipleEntriesUpdateDialog extends Component<TProps, IState> {
  state: IState = {
    entriesToUpdate: [],
  };

  handleCheckboxChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { data } = this.props;
    const entry = data.find(
      (d) => d.entryId === currentTarget.value,
    ) as XTMProjectEntriesVerifyDTO;
    this.setState(({ entriesToUpdate }) => ({
      entriesToUpdate:
        !currentTarget.checked && entriesToUpdate.includes(entry)
          ? [
              ...entriesToUpdate.filter(
                (e) => e.entryId !== currentTarget.value,
              ),
            ]
          : [...entriesToUpdate, entry],
    }));
  };

  renderList = () => {
    const { data } = this.props;
    const { entriesToUpdate } = this.state;
    return (
      <FormGroup>
        {data.map((entry: XTMProjectEntriesVerifyDTO, index) => (
          <FormControlLabel
            data-testid={`entryCheckbox${index}`}
            key={index}
            control={
              <Checkbox
                name={entry.entryName}
                value={entry.entryId}
                checked={entriesToUpdate.includes(entry)}
                onChange={this.handleCheckboxChange}
              />
            }
            label={entry.entryName}
          />
        ))}
      </FormGroup>
    );
  };

  render() {
    const { sdk, t } = this.props;
    const { entriesToUpdate } = this.state;
    return (
      <Fragment>
        <DialogContent data-testid="multipleUpdateDialog">
          <p>{t('addcontent.verificationPopup.firstLine')}</p>
          <p>{t('addcontent.verificationPopup.secondLine')}</p>
          <p>{t('addcontent.verificationPopup.thirdLine')}</p>
          {this.renderList()}
        </DialogContent>
        <ButtonContainer>
          <Button size="small" variant="outlined" onClick={() => sdk.close()}>
            {t('common.cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => sdk.close(entriesToUpdate)}
          >
            {t('common.send')}
          </Button>
        </ButtonContainer>
      </Fragment>
    );
  }
}

export default withTranslation()(MultipleEntriesUpdateDialog);
