import { combineEpics, Epic, ofType } from 'redux-observable';
import { interval, of } from 'rxjs';
import { debounce, switchMap } from 'rxjs/operators';
import {
  getEntriesData,
  getEntriesError,
  getTagsError,
  getUsersError,
  removeTagsPill,
  setEntriesPaginationEnd,
  setParsedEntries,
  setSearchStart,
  setSortEnd,
} from 'store/entries/entries.actions';
import { setAuxSpinner, setSpinner } from './spinner.actions';

const spinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(
      getEntriesData.type,
      setEntriesPaginationEnd.type,
      setSortEnd.type,
      setParsedEntries.type,
      removeTagsPill.type,
      getUsersError.type,
      getEntriesError.type,
      getTagsError.type
    ),
    debounce(({ type }) => {
      if ((type as string) === setParsedEntries.type) {
        return interval(0);
      }
      return interval(300);
    }),
    switchMap(({ type }) => {
      if ((type as string) === setParsedEntries.type || (type as string).endsWith('ERROR')) {
        return of(setSpinner(false));
      }
      return of(setSpinner(true));
    })
  );

const spinnerAuxEpic: Epic = (action$) =>
  action$.pipe(
    ofType(setSearchStart.type, setParsedEntries.type),
    switchMap(({ type }) => {
      if ((type as string) === setParsedEntries.type) {
        return of(setAuxSpinner(false));
      }
      return of(setAuxSpinner(true));
    })
  );

export const spinnerEpics = combineEpics(spinnerEpic, spinnerAuxEpic);
