/* eslint-disable no-console */

/**
 * Clears workers cache and force reloads page
 */
const clearCaches = async (): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.location.reload as any)(true);
  if (caches) {
    try {
      const keys = await caches.keys();
      keys.forEach(async (key) => {
        await caches.delete(key);
      });
    } catch {
      console.error("Couldn't clear worker cache");
    }
  }
};

/**
 * Compares app version with currently deployed version and clears cache if needed
 */
export const checkVersion = async (): Promise<void> => {
  const currentVersion = process.env.REACT_APP_VERSION;
  let metaVersion: string | undefined;
  try {
    metaVersion = (await (await fetch("./meta.json")).json()).version;
  } catch {
    console.error("Couldn't fetch meta.json");
    return;
  }

  if (
    currentVersion !== metaVersion &&
    process.env.NODE_ENV !== "development"
  ) {
    await clearCaches();
  }
};
