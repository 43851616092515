import { ExitToApp } from '@material-ui/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tokenStorageKey } from 'utils/axiosInstance';
import { HeaderButton } from '../CustomAppbar.styled';

interface IProps {
  onClick: () => void;
}

export const Logout: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();

  if (!!localStorage.getItem(tokenStorageKey)) {
    return (
      <HeaderButton disableTouchRipple onClick={onClick}>
        <ExitToApp />
        {t('common.logout')}
      </HeaderButton>
    );
  }

  return null;
};
