import { createReducer } from '@reduxjs/toolkit';
import { FilterOptionsEnum } from 'enums/filterOptions';
import { OrderEnum } from 'enums/orderEnum';
import {
  createTagsPill,
  getContentTypesSuccess,
  getEntriesSuccess,
  getTagsSuccess,
  getUsersSuccess,
  removeTagsPill,
  setEntriesFilters,
  setEntriesPaginationEnd,
  setParsedEntries,
  setSearchEnd,
  setSearchStart,
  setSelectedTags,
  setSortEnd,
  setTagsFilter,
  setTagsIndex,
} from './entries.actions';
import { IEntriesState } from './entries.interface';

const initState: IEntriesState = {
  entries: { total: 0, entries: [] },
  parsedEntries: [],
  search: '',
  pagination: {
    page: 0,
    rows: 5,
  },
  sort: {
    order: OrderEnum.DESC,
    orderBy: 'updatedAt',
  },
  contentTypes: {},
  filters: {
    contentType: 'any',
  },
  selectedTags: [[]],
  tagFilter: [FilterOptionsEnum.NO_FILTER],
  tags: {},
  selectedTagIndex: 0,
  users: {},
};

export const entriesReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getEntriesSuccess, (state, { payload }) => {
      state.entries = payload;
    })
    .addCase(setEntriesPaginationEnd, (state, { payload }) => {
      state.pagination = payload;
    })
    .addCase(setSortEnd, (state, { payload }) => {
      state.sort = payload;
    })
    .addCase(getContentTypesSuccess, (state, { payload }) => {
      state.contentTypes = payload;
    })
    .addCase(setEntriesFilters, (state, { payload }) => {
      state.filters = payload;
    })
    .addCase(setParsedEntries, (state, { payload }) => {
      state.parsedEntries = payload;
    })
    .addCase(setSearchStart, (state, { payload: { search } }) => {
      state.search = search;
    })
    .addCase(setSearchEnd, (state, { payload: { search } }) => {
      state.search = search;
    })
    .addCase(getTagsSuccess, (state, { payload: { tags } }) => {
      state.tags = tags;
    })
    .addCase(setTagsFilter, (state, { payload: { tagFilter, index } }) => {
      state.tagFilter[index] = tagFilter;
    })
    .addCase(setSelectedTags, (state, { payload: { selectedTags, index } }) => {
      state.selectedTags[index] = selectedTags;
    })
    .addCase(getUsersSuccess, (state, { payload: { users } }) => {
      state.users = users;
    })
    .addCase(createTagsPill, (state) => {
      state.selectedTags.push([]);
      state.tagFilter.push(FilterOptionsEnum.NO_FILTER);
    })
    .addCase(removeTagsPill, (state, { payload: { index } }) => {
      state.selectedTags.splice(index, 1);
      state.tagFilter.splice(index, 1);
    })
    .addCase(setTagsIndex, (state, { payload: { index } }) => {
      state.selectedTagIndex = index;
    })
);
