import $ from 'cash-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import { copyStyles } from 'utils/copyStyles';
interface IProps {
  closePortal: () => void;
}

interface IState {
  win: Window | null;
  el: Element | null;
}

export class WindowPortal extends React.Component<IProps, IState> {
  state = { win: null, el: null };
  componentDidMount() {
    const win = window.open('', '', 'width=600,height=600,left=200,top=200');
    const el = document.createElement('div');
    if (win && el) {
      win.document.title = 'XTM Connect';
      const base = document.createElement('base');
      base.href = process.env.PUBLIC_URL;
      (win as Window).document.body.appendChild(el);
      (win as Window).document.head.appendChild(base);
      this.setState({ win, el });

      $((win as Window).document).ready(() => {
        copyStyles(document, (win as Window).document);
      });

      (win as Window).addEventListener('beforeunload', () => {
        this.props.closePortal();
      });
    }
  }

  componentWillUnmount() {
    if (this.state.win) {
      ((this.state.win as unknown) as Window).close();
    }
  }

  render() {
    const { el } = this.state;
    if (!el) {
      return null;
    } else {
      return ReactDOM.createPortal(
        this.props.children,
        (el as unknown) as Element,
      );
    }
  }
}
