import styled from '@emotion/styled';
import { Button, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { XTMLogo } from 'components/XTMLogo/XTMLogo';
import { AppExtensionSDK } from 'contentful-ui-extensions-sdk';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loginSuccess } from 'store/auth/auth.actions';
import { api, getAuthToken, refreshTokenTimeout } from 'utils/axiosInstance';
import { getContentfulIds } from 'utils/initConfig';
import {
  BackgroundWrapper,
  BodyWrapper,
  ConfigScreenWrapper,
} from './ConfigScreen.styled';
import LoginForm from './Form/Login';
import { WindowPortal } from './WindowPortal/WindowPortal';

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderTypography = styled(Typography)`
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ParagraphTypography = styled(Typography)`
  color: #606c7c;
  margin: 15px 0px !important;
`;

const LogoWrapper = styled.div`
  margin: 15px auto;
`;

interface IProps {
  sdk: AppExtensionSDK;
}

const AppConfig: FC<IProps> = ({ sdk }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [allowedContentTypes, setAllowedContentTypes] = useState<string[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showWindowPortal, setShowWindowPortal] = useState(false);

  useEffect(() => {
    onMount()
      .catch(() => {
        sdk.app.onConfigure(() => false);
        sdk.notifier.error(t('errormessage.somethingWrong'));
      })
      .finally(() => {
        sdk.app.setReady();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onLoginSuccess = async () => {
      console.log(allowedContentTypes);
      dispatch(loginSuccess(await getContentfulIds(sdk, allowedContentTypes)));
    };

    if (isLoggedIn) {
      onLoginSuccess();
    }
  }, [isLoggedIn, sdk, dispatch, allowedContentTypes]);

  const onMount = async () => {
    const { space, environment } = sdk.ids;

    await refreshTokenTimeout();
    setIsLoggedIn(Boolean(getAuthToken()));

    const { data: allowedContentTypes } = await api.allowedContentTypes(
      space,
      environment
    );

    sdk.app.onConfigure(configure(allowedContentTypes));

    setAllowedContentTypes(allowedContentTypes);
  };

  const configure = (contentTypes: string[]) => () => {
    return {
      targetState: {
        EditorInterface: contentTypes.reduce(
          (acc: Record<string, object>, contentType) => {
            acc[contentType] = { sidebar: { position: 1 } };
            return acc;
          },
          {}
        ),
      },
    };
  };

  const togglePortal = () => {
    setShowWindowPortal((showWindowPortal) => !showWindowPortal);
  };

  const handleClose = async (success?: boolean) => {
    if (success) {
      const { space, environment } = sdk.ids;
      const { data: allowedContentTypes } = await api.allowedContentTypes(
        space,
        environment
      );

      setAllowedContentTypes(allowedContentTypes);
      setIsLoggedIn(true);
    }
    setShowWindowPortal(false);
  };

  return (
    <div className="app">
      <ConfigScreenWrapper>
        <BackgroundWrapper>
          <StyledCard>
            <BodyWrapper>
              <HeaderTypography variant="h4">
                {t('configForm.configuration')}
              </HeaderTypography>
              <Typography variant="h5">
                {t('configForm.headers.connectHeader')}
              </Typography>
              <ParagraphTypography>
                {t('configForm.labels.connectLabel')}
              </ParagraphTypography>
              <Button
                onClick={togglePortal}
                fullWidth
                variant="contained"
                color="secondary"
              >
                {isLoggedIn ? t('common.changeAccount') : t('common.connect')}
              </Button>
            </BodyWrapper>
            <LogoWrapper>
              <XTMLogo />
            </LogoWrapper>
          </StyledCard>
        </BackgroundWrapper>
      </ConfigScreenWrapper>
      <div className="logo"></div>
      {showWindowPortal && (
        <WindowPortal closePortal={handleClose}>
          <LoginForm handleClose={handleClose} />
        </WindowPortal>
      )}
    </div>
  );
};

export default AppConfig;
