import styled from "@emotion/styled";

export const DialogContent = styled.div`
  &::before {
    content: "";
    border: 1px solid rgba(207, 217, 224, 0.5);
    position: absolute;
    left: 0;
    right: 0;
  }
  margin: 12px 0;

  p {
    margin: 8px 0;
  }

  & > :first-of-type {
    margin-top: 8px;
  }
`;

export const SelectedTagsDiv = styled.div`
  margin-top: 12px;

  & > * {
    margin: 2px;
  }
  & :last-of-type {
    margin: 2px 0px 2px 2px;
  }
  & :first-of-type {
    margin: 2px 2px 2px 0px;
  }
`;

export const ManageReferencesTextInfo = styled.div`
  font-size: 14px;
  margin-top: 1rem !important;
`;

export const TreeWrapper = styled.div`
  margin-top: 1rem;
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
`;

export const WrapperWithHorizontalLine = styled.div<{ isLast?: boolean }>`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 1px;
    top: 25px;
    left: 1px;
    background-color: gray;
  }

  &::after {
    position: absolute;
    content: "";
    width: 1px;
    height: ${({ isLast }) => (isLast ? "26px" : "100%")};
    left: 0;
    background-color: gray;
  }
`;

export const TreeNodeWrapper = styled.div<{ isEnabled?: boolean }>`
  border: 1px solid gray;
  padding: 0 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  position: relative;
  background-color: ${({ isEnabled }) => (isEnabled ? "#fff" : "#f0f0f0")};
`;

export const ManageReferencesButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1rem;
`;

export const TreeNodeChildrenParent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const TreeNodeChildrenWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const SyncIconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  min-width: 20px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContentNameWrapper = styled.p`
  white-space: nowrap;
  overflow: hidden;
`;
