import { Box, Checkbox, CircularProgress, TableSortLabel, Typography } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import SelectTagsDialog from 'components/Dialog/CustomVariants/SelectTagsDialog';
import { PageExtensionSDK, SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';
import { OrderEnum } from 'enums/orderEnum';
import moment from 'moment';
import React, { Component, Fragment, InputHTMLAttributes } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Column, TableCellRenderer, TableHeaderProps } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { AppDispatch, AppState } from 'store';
import { setEntriesPaginationStart, setParsedEntries, setSortStart } from 'store/entries/entries.actions';
import { EntriesPagination, IEntriesState } from 'store/entries/entries.interface';
import {
  getEntriesPaginationSelector,
  getEntriesSelector,
  getEntriesSortSelector,
  getParsedEntriesSelector,
} from 'store/entries/entries.selectors';
import { getSpinnerSelector } from 'store/spinner/spinner.selectors';
import { EntriesDTO, EntryDTO } from 'utils/restApplicationClient';
import { StatusDiv } from '../Sidebar.styled';
import Name from '../TranslationQueue/Name/Name';
import { RefreshButton } from '../TranslationQueue/TranslationTable/TranslationTable.styles';
import {
  EntriesTableBorderTable,
  EntriesTableHeaderCell,
  EntriesTableSpinnerOverlay,
  EntriesTableTableCell,
  EntriesTableTableContainer,
  EntriesTableWithPaginationContainer,
} from './EntriesTable.styled';
import EntriesTablePagination from './EntriesTablePagination';
import EntriesTableSearch from './EntriesTableSearch';

interface IProps {
  sdk: SidebarExtensionSDK | PageExtensionSDK;
  onSelectEntries: (entriesIds: Array<string>) => void;
  native?: boolean;
}

interface IDispatchProps {
  setPagination: (payload: Partial<EntriesPagination>) => AppDispatch;
  setSort: (payload: { property: string }) => AppDispatch;
}

interface IStateProps {
  pagination: IEntriesState['pagination'];
  sort: IEntriesState['sort'];
  entries: EntriesDTO;
  parsedEntries: Array<any>;
  fetchSpinner: boolean;
}

interface IState {
  isCheckedArray: Array<boolean>;
  isAllChecked: boolean;
}

type PropType = IProps & WithTranslation & IDispatchProps & IStateProps;

export class EntriesTable extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    this.unsubscribe.push(
      subscribeActionAfter(setParsedEntries.type, (action) => {
        this.setState({ isCheckedArray: Array((action as PayloadAction<Array<EntryDTO>>).payload.length).fill(false) });
      })
    );

    this.state = {
      isCheckedArray: [],
      isAllChecked: false,
    };
  }

  componentDidMount() {
    const { entries } = this.props;

    this.setState({ isCheckedArray: Array(entries.total).fill(false) });
  }

  private unsubscribe: Array<() => void> = [];

  handleCheck =
    (index: number) =>
    (_event: React.ChangeEvent, value: boolean): void => {
      const isCheckedArray = [...this.state.isCheckedArray];
      isCheckedArray[index] = value;
      this.setState({ isCheckedArray }, () => {
        this.setState({ isAllChecked: this.checkForAllChecked() }, this.handleSelectedEntriesIds);
      });
    };

  handleCheckAll = (_event: React.ChangeEvent | null, value: boolean): void => {
    const isCheckedArray = [...this.state.isCheckedArray.map(() => value)];
    this.setState({ isCheckedArray, isAllChecked: value }, this.handleSelectedEntriesIds);
  };

  handleCheckPage = (_event: React.ChangeEvent | null, value: boolean): void => {
    const {
      pagination: { rows, page },
    } = this.props;
    const checkedValues = [...this.state.isCheckedArray.slice(page * rows, page * rows + rows).map(() => value)];
    const isCheckedArray = [...this.state.isCheckedArray];
    isCheckedArray.splice(page * rows, rows, ...checkedValues);
    this.setState({ isCheckedArray, isAllChecked: value }, this.handleSelectedEntriesIds);
  };

  handleSelectedEntriesIds = (): void => {
    const {
      entries: { entries },
    } = this.props;

    const entriesIds = entries.filter((_, index) => this.state.isCheckedArray[index]).map((entry) => entry.id);
    this.props.onSelectEntries(entriesIds);
  };

  checkForAllChecked = (): boolean => {
    const { isCheckedArray } = this.state;
    return isCheckedArray.length === 0
      ? false
      : this.state.isCheckedArray.reduce((previous, current) => previous && current, true);
  };

  handleRefresh = (): void => {
    const { setPagination } = this.props;

    setPagination({ page: 0 });
    this.handleCheckAll(null, false);
  };

  getStatus = ({
    archivedAt,
    updatedAt,
    publishedAt,
  }: {
    archivedAt: string;
    updatedAt: string;
    publishedAt: string;
    publishedCounter: number;
  }): any => {
    const { t } = this.props;
    if (archivedAt) {
      return t('addcontent.statuses.archived');
    } else if (updatedAt && publishedAt && new Date(updatedAt) > new Date(publishedAt)) {
      return t('addcontent.statuses.changed');
    } else if (publishedAt) {
      return t('addcontent.statuses.published');
    }

    return t('addcontent.statuses.draft');
  };

  cellRenderer: TableCellRenderer = ({ cellData, dataKey, rowIndex }) => {
    return (
      <EntriesTableTableCell component="div" size="small" variant="body">
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="body2" component="div">
            {this.getCellContent(cellData, dataKey, rowIndex)}
          </Typography>
        </Box>
      </EntriesTableTableCell>
    );
  };

  getCellContent = (cellData: any, dataKey: string, rowIndex: number) => {
    switch (dataKey) {
      case 'contentName': {
        return <Name text={cellData} width={144} />;
      }
      case 'checkbox': {
        return (
          <Checkbox
            inputProps={
              {
                'data-testid': `entryCheckbox${rowIndex}`,
              } as InputHTMLAttributes<HTMLInputElement>
            }
            checked={this.state.isCheckedArray[rowIndex] || false}
            onChange={this.handleCheck(rowIndex)}
          />
        );
      }
      case 'type': {
        return <Name text={cellData} width={138} />;
      }
      case 'updatedAt': {
        return moment(cellData).format('LL');
      }
      case 'author': {
        return <Name text={cellData} width={143} />;
      }
      case 'tags': {
        return <Name text={cellData} width={130} />;
      }
      case 'status': {
        return this.renderStatus(cellData);
      }
    }
  };

  headerRenderer = ({ dataKey: label }: TableHeaderProps) => {
    const {
      sort: { order, orderBy },
    } = this.props;
    return (
      <EntriesTableHeaderCell
        align="left"
        size="small"
        sortDirection={orderBy === label ? order : false}
        component="div"
      >
        {this.getHeaderCell(label)}
      </EntriesTableHeaderCell>
    );
  };

  getHeaderCell = (label: string) => {
    const {
      t,
      sort: { order, orderBy },
    } = this.props;
    switch (label) {
      case 'updatedAt': {
        return (
          <TableSortLabel
            active={orderBy === label}
            onClick={this.onChangeOrder(label as string)}
            direction={orderBy === label ? order : OrderEnum.ASC}
          >
            <Box color="#536171">{t(`addcontent.${label}`)}</Box>
          </TableSortLabel>
        );
      }
      case 'checkbox': {
        return <Checkbox checked={this.checkForAllChecked()} onChange={this.handleCheckAll} />;
      }
      default: {
        return <Box fontWeight="fontWeightRegular">{t(`addcontent.${label}`)}</Box>;
      }
    }
  };

  renderStatus = (entry: {
    archivedAt: string;
    updatedAt: string;
    publishedAt: string;
    publishedCounter: number;
  }): JSX.Element => {
    const { t } = this.props;
    const status = this.getStatus(entry);
    let color = 'black';
    switch (status) {
      case t('addcontent.statuses.archived'): {
        color = '#bf3045';
        break;
      }
      case t('addcontent.statuses.changed'): {
        color = '#2e75d4';
        break;
      }
      case t('addcontent.statuses.published'): {
        color = '#16875d';
        break;
      }
      case t('addcontent.statuses.draft'): {
        color = '#f79b0c';
        break;
      }
    }
    const Status = StatusDiv(color);
    return <Status>{status}</Status>;
  };

  onChangeOrder = (property: string): (() => void) => {
    return (): void => {
      this.handleCheckAll(null, false);
      const { setSort } = this.props;

      setSort({ property });
    };
  };

  columns = [
    { dataKey: 'checkbox', width: 74 },
    { dataKey: 'contentName', width: 150 },
    { dataKey: 'type', width: 150 },
    { dataKey: 'updatedAt', width: 170 },
    { dataKey: 'author', width: 150 },
    { dataKey: 'tags', width: 162 },
    { dataKey: 'status', width: 125 },
  ];

  render() {
    const { t, parsedEntries, fetchSpinner } = this.props;
    return (
      <Fragment>
        <SelectTagsDialog />
        <RefreshButton
          disableFocusRipple
          disableRipple
          startIcon={<AutorenewIcon color="secondary" />}
          onClick={this.handleRefresh}
        >
          <Typography color="textPrimary" variant="body2">
            {t('addcontent.refresh')}
          </Typography>
        </RefreshButton>
        <EntriesTableSearch />
        <EntriesTableWithPaginationContainer>
          {fetchSpinner && (
            <EntriesTableSpinnerOverlay>
              <CircularProgress color="secondary" />
            </EntriesTableSpinnerOverlay>
          )}
          <EntriesTableTableContainer>
            <EntriesTableBorderTable
              height={500}
              width={900}
              headerHeight={60}
              rowCount={parsedEntries.length}
              rowHeight={75}
              rowGetter={({ index }: { index: number }) => {
                return parsedEntries[index];
              }}
            >
              {this.columns.map((row) => {
                return (
                  <Column
                    width={row.width}
                    key={row.dataKey}
                    dataKey={row.dataKey}
                    headerRenderer={this.headerRenderer}
                    cellRenderer={this.cellRenderer}
                  ></Column>
                );
              })}
            </EntriesTableBorderTable>
          </EntriesTableTableContainer>
          <EntriesTablePagination />
        </EntriesTableWithPaginationContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  pagination: getEntriesPaginationSelector(state),
  sort: getEntriesSortSelector(state),
  entries: getEntriesSelector(state),
  parsedEntries: getParsedEntriesSelector(state),
  fetchSpinner: getSpinnerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPagination: (payload: Partial<EntriesPagination>) => dispatch(setEntriesPaginationStart(payload)),
  setSort: (payload: { property: string }) => dispatch(setSortStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EntriesTable));
