import { DialogExtensionSDK } from "contentful-ui-extensions-sdk";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogContent,
  ManageReferencesButtonWrapper,
  ManageReferencesTextInfo,
} from "../CustomVariants.styled";
import { ButtonContainer } from "../../Dialog.styled";
import { Button, Tooltip } from "@material-ui/core";
import Tree from "./components/Tree";
import { ReferenceDTO } from "utils/restApplicationClient";
import { getAllNodesIds } from "utils/getAllNodesIds";

interface IProps {
  sdk: DialogExtensionSDK;
  data: {
    entries: ReferenceDTO[];
    selectedIds: string[] | undefined;
  };
}

const ManageReferencesDialog = ({ sdk, data }: IProps) => {
  const { t } = useTranslation();
  const [selectedNodes, setSelectedNodes] = useState<string[] | undefined>(
    data.selectedIds
  );

  return (
    <>
      <DialogContent>
        <ManageReferencesTextInfo>
          {t("addcontent.manageReferencesPopup.textInfo")}
        </ManageReferencesTextInfo>
        <ManageReferencesButtonWrapper>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setSelectedNodes([])}
          >
            {t("addcontent.manageReferencesPopup.clearAll")}
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => setSelectedNodes(getAllNodesIds(data.entries))}
          >
            {t("addcontent.manageReferencesPopup.selectAll")}
          </Button>
        </ManageReferencesButtonWrapper>
        <Tree
          data={data.entries}
          setSelectedNodes={setSelectedNodes}
          selectedNodes={selectedNodes}
        />
      </DialogContent>
      <ButtonContainer>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          data-testid="cancel"
          onClick={() => sdk.close(null)}
        >
          {t("addcontent.manageReferencesPopup.cancel")}
        </Button>
        <Tooltip
          title={
            !selectedNodes?.length
              ? (t(
                  "addcontent.manageReferencesPopup.saveDisabledText"
                ) as string)
              : ""
          }
        >
          <span>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              disabled={!selectedNodes?.length}
              data-testid="save"
              onClick={() => sdk.close(selectedNodes)}
            >
              {t("addcontent.manageReferencesPopup.save")}
            </Button>
          </span>
        </Tooltip>
      </ButtonContainer>
    </>
  );
};

export default ManageReferencesDialog;
