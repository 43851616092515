import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {
        fontSize: '15px',
      },
      root: {
        textTransform: 'none',
      }
    },
    MuiSelect: {
      root: {
        padding: '10px',
        background: 'white',
        color: '#536171',
      },
      selectMenu: {
        padding: '6px',
        fontSize: '14px',
      },
    },
    MuiListItem: {
      root: {
        color: '#536171',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: 'white',
        color: '#536171',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiFormControl: {
      root: {
        minWidth: '280px',
      },
    },
    MuiFormLabel: {
      root: {
        paddingBottom: '10px',
      },
    },
    MuiTab: {
      root: {
        fontSize: '18px',
        textTransform: 'none',
        maxWidth: 'auto',
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '6px 4px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
  palette: {
    primary: {
      main: '#2e3645',
    },
    secondary: {
      main: '#10a4e4',
      contrastText: '#fff',
    },
    text: {
      primary: '#303030',
      secondary: '#536171',
    },
  },
});
