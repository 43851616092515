/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Button, Chip, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Autocomplete } from '@material-ui/lab';
import { Dispatch } from '@reduxjs/toolkit';
import { ButtonContainer, ModalTitle, TagsDialog } from 'components/Dialog/Dialog.styled';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { setDialog } from 'store/config/config.actions';
import { getDialogOpenSelector } from 'store/config/config.selectors';
import { createTagsPill, removeTagsPill, setSelectedTags } from 'store/entries/entries.actions';
import { AutocompleteField, IEntriesState } from 'store/entries/entries.interface';
import {
  getEntriesSelectedTagIndexSelector,
  getEntriesSelectedTagsSelector,
  getEntriesTagsSelector,
} from 'store/entries/entries.selectors';
import { TagDTO } from 'utils/restApplicationClient';
import { DialogContent, SelectedTagsDiv } from './CustomVariants.styled';

interface IStateProps {
  tags: IEntriesState['tags'];
  open: boolean;
  selectedTags: IEntriesState['selectedTags'];
  selectedTagIndex: IEntriesState['selectedTagIndex'];
}

interface IDispatchProps {
  setSelectedTags: (payload: { selectedTags: Array<AutocompleteField>; index: number }) => AppDispatch;
  setDialogOpen: (payload: boolean) => AppDispatch;
  addTagPill: () => AppDispatch;
  removeTagPill: (payload: { index: number }) => AppDispatch;
}

type PropType = IStateProps & IDispatchProps & WithTranslation;

export const SelectTags = ({
  tags,
  t,
  open,
  selectedTags,
  setSelectedTags,
  setDialogOpen,
  selectedTagIndex,
  addTagPill,
  removeTagPill,
}: PropType) => {
  const [value, setValue] = useState({ label: '', value: '' });
  const [currentlySelectedTags, setCurrentlySelectedTags] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    setCurrentlySelectedTags(selectedTags[selectedTagIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedTagIndex]);

  const onChange = (_: any, newValue: { label: string; value: string }) => {
    setCurrentlySelectedTags([...currentlySelectedTags, newValue]);
    setValue({ label: '', value: '' });
  };

  const onChipDelete = (value: string) => () => {
    const removedIndex = currentlySelectedTags.findIndex(({ value: tagValue }) => value === tagValue);
    const tags = [...currentlySelectedTags];
    tags.splice(removedIndex, 1);

    setCurrentlySelectedTags(tags);
  };

  const handleConfirm = () => {
    setSelectedTags({ selectedTags: currentlySelectedTags, index: selectedTagIndex });
    setDialogOpen(false);
  };

  const handleAddAnotherPill = () => {
    addTagPill();
    setSelectedTags({ selectedTags: currentlySelectedTags, index: selectedTagIndex });
    setDialogOpen(false);
  };

  const handleRemovePill = () => {
    removeTagPill({ index: selectedTagIndex });
    setDialogOpen(false);
  };

  const filterTags = ({ id }: TagDTO) =>
    currentlySelectedTags?.findIndex(({ value: selected }) => selected === id) === -1;

  return (
    <TagsDialog
      open={open}
      id="dialog"
      onClick={(event) => {
        if ((event.target as HTMLDivElement)?.id === 'dialog') {
          setDialogOpen(false);
        }
      }}
    >
      <Box width="520px" bgcolor="white" position="relative">
        <ModalTitle color="textPrimary">{t('addcontent.filtersPopup.title')}</ModalTitle>
        <DialogContent>
          <Box display="flex">
            <Box marginRight="12px">
              <Button size="small" variant="contained" color="secondary" onClick={handleAddAnotherPill}>
                <Box display="flex" justifyContent="center" alignItems="center" width="30px">
                  <AddIcon />
                </Box>
                {t('queue.tags.add')}
              </Button>
            </Box>
            {selectedTags.length > 1 && (
              <Button size="small" variant="outlined" color="secondary" onClick={handleRemovePill}>
                <Box display="flex" justifyContent="center" alignItems="center" width="30px">
                  <RemoveIcon />
                </Box>
                {t('queue.tags.remove')}
              </Button>
            )}
          </Box>
          <Typography color="textPrimary" component="div">
            <Box fontSize="14px" paddingTop="12px" paddingBottom="4px" fontWeight="fontWeightBold">
              {t('common.tags')}
            </Box>
          </Typography>
          <Box marginTop="0.75rem" marginBottom="1rem">
            <Autocomplete
              options={Object.entries(tags)
                .filter(([_, tag]) => filterTags(tag))
                .map(([_, { id, name }]) => ({ label: name, value: id }))}
              value={value}
              disableClearable
              onChange={onChange}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('addcontent.filtersPopup.search')}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Box>
          <SelectedTagsDiv>
            {currentlySelectedTags?.map(({ label, value }, index) => (
              <Chip onDelete={onChipDelete(value)} label={label} key={index} />
            ))}
          </SelectedTagsDiv>
        </DialogContent>
        <ButtonContainer>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button size="small" variant="contained" color="secondary" onClick={handleConfirm}>
            {t('common.apply')}
          </Button>
        </ButtonContainer>
      </Box>
    </TagsDialog>
  );
};

const mapStateToProps = (state: AppState): IStateProps => ({
  open: getDialogOpenSelector(state),
  selectedTags: getEntriesSelectedTagsSelector(state),
  selectedTagIndex: getEntriesSelectedTagIndexSelector(state),
  tags: getEntriesTagsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setDialogOpen: (payload: boolean) => dispatch(setDialog(payload)),
  setSelectedTags: (payload: { selectedTags: Array<AutocompleteField>; index: number }) =>
    dispatch(setSelectedTags(payload)),
  addTagPill: () => dispatch(createTagsPill()),
  removeTagPill: (payload: { index: number }) => dispatch(removeTagsPill(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectTags));
