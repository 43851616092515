/** @jsx jsx */
import momentUtils from '@date-io/moment';
import { jsx } from '@emotion/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { customFieldStyles } from 'components/Config/Form/CustomField.style';
import moment, { Moment } from 'moment';
import { Component, Fragment } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomTypography } from './form.styles';

interface IProps {
  inputProps: FieldInputProps<
    { [key: string]: string } | null | undefined,
    HTMLElement
  >;
  label: string;
  onChange?: (date: Moment | null) => void;
  value?: moment.Moment | null;
  disabled?: boolean;
  handleClear?: () => void;
  datePickerButtonDisabled?: boolean;
  clearButtonDisabled?: boolean;
  displayClearButton?: boolean;
}

interface IState {
  date: Moment | null;
  open: boolean;
}

type PropType = IProps & WithTranslation;

export class CustomDatepicker extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      date: null,
      open: false,
    };
  }

  componentDidMount(): void {
    const { onChange } = this.props.inputProps;
    onChange(null);
  }

  componentDidUpdate(previousProps: PropType, previousState: IState): void {
    const { date } = this.state;
    if (date && previousState.date !== date) {
      const now = moment();
      if (date.isBefore(now)) {
        date.hour(now.hour() + 1).minute(0);
      }
      this.props.inputProps.onChange(date.toDate());
    } else if (date === null) {
      this.props.inputProps.onChange(date);
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClear = () => {
    const { handleClear, inputProps } = this.props
    if (handleClear) {
      handleClear();
    }

    this.setState({ date: null });
  };

  handleChange = (date: Moment | null) => {
    const { onChange, inputProps } = this.props;
    if (onChange) {
      onChange(date);
    }

    this.setState({ date: (date as Moment).minutes(0) });
  };

  renderEndAdornment = () => {
    return (
      <Fragment>
        {this.props.displayClearButton && (
          <IconButton
              size="small"
              onClick={this.handleClear}
              disabled={this.props.clearButtonDisabled}
          >
            <CloseIcon />
          </IconButton>
        )}
        <IconButton
          size="small"
          onClick={this.handleOpen}
          data-testid="datePickerButton"
          disabled={this.props.datePickerButtonDisabled}
        >
          <EventIcon />
        </IconButton>
      </Fragment>
    );
  };

  render(): JSX.Element {
    const { inputProps, label, t } = this.props;
    return (
      <div css={customFieldStyles.root}>
        <MuiPickersUtilsProvider utils={momentUtils}>
          <CustomTypography color="textSecondary" variant="subtitle2">
            {t(label)}
          </CustomTypography>
          <DateTimePicker
            disableToolbar
            disablePast
            size="small"
            views={['date', 'hours']}
            ampm={false}
            margin="dense"
            inputVariant="outlined"
            {...inputProps}
            inputProps={{
              'data-testid': 'datePickerInput',
            }}
            InputProps={{
              endAdornment: this.renderEndAdornment(),
            }}
            open={this.state.open}
            value={this.props.value}
            onChange={this.handleChange}
            onClose={this.handleClose}
            onAccept={this.handleClose}
            disabled={this.props.disabled}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withTranslation()(CustomDatepicker);
