/**
 * Copies styles from one document to another and sets correct image sources for images inside iframes.
 *
 * @param sourceDoc - Document from which styles are copied
 * @param targetDoc - Document to which styles are copied
 *
 */
export const copyStyles = async (sourceDoc: Document, targetDoc: Document) => {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
        if (styleSheet.cssRules) {
            const newStyleEl = sourceDoc.createElement('style');

            Array.from(styleSheet.cssRules).forEach(cssRule => {
                newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
            });

            targetDoc.head.appendChild(newStyleEl);
        } else if (styleSheet.href) {
            const newLinkEl = sourceDoc.createElement('link');

            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            targetDoc.head.appendChild(newLinkEl);
        }
    });

    Array.from(targetDoc.images).forEach((image: HTMLImageElement) => {
        image.src = `${window.location.origin}${(image.attributes as any).src.value}`;
    })
}