import { SidebarExtensionSDK } from "contentful-ui-extensions-sdk";
import { EntryFieldEnum } from "enums/entryFieldsStatus";

export const checkEntryFields = async (
  sdk: SidebarExtensionSDK,
  sourceLanguage: string,
): Promise<Array<EntryFieldEnum | undefined>> => {
  const {
    entry: { getSys, fields },
    space: { getContentType },
  } = sdk;
  const {
    contentType: {
      sys: { id: contentTypeId },
    },
  } = getSys();
  let empty = true;
  const contentType = await getContentType(contentTypeId);
  const keys = (contentType as any).fields
    .filter((field: any) => !field.disabled && field.localized)
    .map((field: any) => field.id);
  const statusArray = keys.map((key: keyof typeof fields) => {
    if (!fields[key].getValue(sourceLanguage)) {
      return empty ? EntryFieldEnum.EMPTY : EntryFieldEnum.PARTIAL;
    }
    if (empty) {
      empty = false;
    }
    return undefined;
  });
  return Array.from(new Set(statusArray));
};