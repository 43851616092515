import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SendIcon from '@material-ui/icons/Send';
import { SidebarExtensionSDK } from 'contentful-ui-extensions-sdk';
import { DialogParamEnum } from 'enums/dialogParam';
import { StatusEnum } from 'enums/status';
import React, { Fragment, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { apiErrorHandler } from 'utils/apiErrorHandler';
import { api } from 'utils/axiosInstance';
import { XTMJobStatus } from 'utils/restApplicationClient';
import { ButtonVariantEnum } from '../../../../enums/buttonVariant';
import {
  ButtonMargin,
  SpinnerMargin,
  StatusMenuItemDiv,
} from './StatusMenu.styles';

interface IState {
  anchorElement: HTMLElement | null;
  taskPerform: boolean;
}

interface IProps {
  status: XTMJobStatus;
  jobId: string;
  setJobs: (forceRefresh?: boolean) => void;
  sdk: SidebarExtensionSDK;
}

type PropType = IProps & WithTranslation;

export class StatusMenu extends PureComponent<PropType, IState> {
  state = {
    anchorElement: null,
    missingDetailsAlert: false,
    taskPerform: false,
  };

  buttons: Array<{ label: string; variant: ButtonVariantEnum }> = [
    { label: 'modal.quit', variant: ButtonVariantEnum.QUIT },
    { label: 'modal.ok', variant: ButtonVariantEnum.CONFIRM },
  ];

  handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const { anchorElement } = this.state;
    if (!anchorElement) {
      this.setState({ anchorElement: event.currentTarget });
    }
  };

  handleOpenDialog =
    (title: string, text: string, callback?: () => void) =>
    async (): Promise<void> => {
      this.setDialog(title, text).then((response) => {
        if (response === DialogParamEnum.CONFIRM && callback) {
          callback();
        }
      });
    };

  setDialog = async (title: string, text: string) =>
    this.props.sdk.dialogs.openCurrent({
      width: 400,
      parameters: {
        title: title,
        text: text,
        buttons: this.buttons,
      },
    });

  handleClose = (): void => {
    this.setState({ anchorElement: null });
  };

  handleCancel = (): void => {
    const { jobId, setJobs, sdk } = this.props;
    this.setState({ taskPerform: true });
    api
      .performOperation({ jobsIds: [jobId], operation: 'CANCEL' })
      .catch((error) => apiErrorHandler(error, sdk))
      .finally(() => {
        this.setState({ taskPerform: false });
        setJobs(true);
      });
    this.setState({ anchorElement: null });
  };

  handleRemove = (): void => {
    const { jobId, setJobs, sdk } = this.props;
    this.setState({ taskPerform: true });
    api
      .performOperation({ jobsIds: [jobId], operation: 'DELETE' })
      .catch((error) => apiErrorHandler(error, sdk))
      .finally(() => {
        this.setState({ taskPerform: false });
        setJobs(true);
      });
    this.setState({ anchorElement: null });
  };

  handleSend = (): void => {
    const { jobId, setJobs, sdk } = this.props;
    this.setState({ taskPerform: true });
    api
      .performOperation({ jobsIds: [jobId], operation: 'SEND' })
      .catch((error) => apiErrorHandler(error, sdk))
      .finally(() => {
        this.setState({ taskPerform: false });
        setJobs(true);
      });
    this.setState({ anchorElement: null });
  };

  handleImport = (): void => {
    const { jobId, setJobs, sdk } = this.props;
    this.setState({ taskPerform: true });
    api
      .performOperation({ jobsIds: [jobId], operation: 'IMPORT' })
      .catch((error) => apiErrorHandler(error, sdk))
      .finally(() => {
        this.setState({ taskPerform: false });
        setJobs(true);
      });
    this.setState({ anchorElement: null });
  };

  tooltipTranslation = () => {
    const { t } = this.props;
    return t('queue.fetched');
  };

  getMenuItems = (): Array<JSX.Element> | undefined => {
    const { status, t } = this.props;
    switch (status) {
      case StatusEnum.CANCELLED: {
        return [
          <MenuItem
            dense
            onClick={this.handleOpenDialog(
              'common.remove',
              'modal.inTranslationQueueRemoveAction',
              this.handleRemove
            )}
            key="1"
          >
            <StatusMenuItemDiv>
              <DeleteIcon />
              <span>{t('queue.remove')}</span>
            </StatusMenuItemDiv>
          </MenuItem>,
        ];
      }
      case StatusEnum.NEW: {
        return [
          <MenuItem
            dense
            onClick={this.handleOpenDialog(
              'common.cancel',
              'modal.inTranslationQueueCancelAction',
              this.handleCancel
            )}
            key="1"
          >
            <StatusMenuItemDiv>
              <CloseIcon />
              <span>{t('queue.cancel')}</span>
            </StatusMenuItemDiv>
          </MenuItem>,
          <MenuItem
            dense
            onClick={this.handleOpenDialog(
              'common.send',
              'modal.inTranslationQueueSendAction',
              this.handleSend
            )}
            key="2"
          >
            <StatusMenuItemDiv>
              <SendIcon />
              <span>{t('common.send')}</span>
            </StatusMenuItemDiv>
          </MenuItem>,
        ];
      }
      case StatusEnum.IN_PROGRESS:
      case StatusEnum.SENT: {
        return [
          <MenuItem
            dense
            onClick={this.handleOpenDialog(
              'common.cancel',
              'modal.inTranslationQueueCancelAction',
              this.handleCancel
            )}
            key="1"
          >
            <StatusMenuItemDiv>
              <CloseIcon />
              <span>{t('queue.cancel')}</span>
            </StatusMenuItemDiv>
          </MenuItem>,
        ];
      }
      case StatusEnum.FINISHED: {
        return [
          <MenuItem
            dense
            onClick={this.handleOpenDialog(
              'common.import',
              'modal.inTranslationQueueImportAction',
              this.handleImport
            )}
            key="1"
          >
            <StatusMenuItemDiv>
              <SaveAltIcon />
              <span>{t('queue.import')}</span>
            </StatusMenuItemDiv>
          </MenuItem>,
        ];
      }
      default: {
        return undefined;
      }
    }
  };

  render() {
    const { status } = this.props;
    const { anchorElement, taskPerform } = this.state;
    const menuItems = this.getMenuItems();
    return (
      <Fragment>
        {taskPerform ? (
          <SpinnerMargin>
            <CircularProgress size={20} color="secondary" />
          </SpinnerMargin>
        ) : (
          <ButtonMargin>
            {status === StatusEnum.FETCHED ? (
              <IconButton size="small" data-testid="menuButton" disabled={true}>
                <MoreVertIcon />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={this.handleClick}
                data-testid="menuButton"
                disabled={!menuItems}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </ButtonMargin>
        )}
        {menuItems && (
          <Menu
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            onClose={this.handleClose}
            keepMounted
          >
            {menuItems}
          </Menu>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(StatusMenu);
