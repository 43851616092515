import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import { Dispatch } from '@reduxjs/toolkit';
import React, { ChangeEvent, FC, MouseEvent } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { setEntriesPaginationStart } from 'store/entries/entries.actions';
import { EntriesPagination, IEntriesState } from 'store/entries/entries.interface';
import { getEntriesPaginationSelector, getEntriesSelector } from 'store/entries/entries.selectors';
import { EntriesDTO } from 'utils/restApplicationClient';
import { EntriesTablePaginationContainer } from './EntriesTable.styled';

interface IStateProps {
  pagination: IEntriesState['pagination'];
  entries: EntriesDTO;
}

interface IDispatchProps {
  setPagination: (payload: EntriesPagination) => AppDispatch;
}

type IProps = IStateProps & IDispatchProps & WithTranslation;

const EntriesTablePagination: FC<IProps> = ({ entries: { total }, pagination: { rows, page }, t, setPagination }) => {
  const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
    // this.handleCheckAll(null, false);
    setPagination({ rows, page });
  };

  const handleChangeRowsOnPage = ({
    target: { value: rows },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    // this.handleCheckAll(null, false);
    setPagination({ rows: Number(rows), page: 0 });
  };

  return (
    <EntriesTablePaginationContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        colSpan={6}
        count={total}
        rowsPerPage={rows}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsOnPage}
        labelRowsPerPage={
          <Typography color="textSecondary" variant="body2" component="span">
            {t('queue.rowsPerPage')}
          </Typography>
        }
        labelDisplayedRows={({ to, from, count }) => (
          <Typography color="textSecondary" variant="body2" component="span">
            <Trans i18nKey="addcontent.labelDisplayedRows" values={{ to, from, count }} />
          </Typography>
        )}
      />
    </EntriesTablePaginationContainer>
  );
};

const mapStateToProps = (state: AppState): IStateProps => ({
  entries: getEntriesSelector(state),
  pagination: getEntriesPaginationSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPagination: (payload: EntriesPagination) => dispatch(setEntriesPaginationStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EntriesTablePagination));
