import styled from '@emotion/styled';
import { Button, Tab, Table, TableHead } from '@material-ui/core';
import { TabPanel } from './TabPanel';

export const SideBarWrapper = styled.div`
  & > div {
    background: none;
    border: 1px;
    width: 280px;
    margin: 0 auto;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: solid 1px rgba(120, 120, 120, 0.2);
`;

export const ConnectButton = styled(Button)`
  text-transform: none !important;
`;

export const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const CustomTab = styled(Tab)`
  font-size: 16px !important;
  flex-grow: 1;
`;

export const CustomTableHead = styled(TableHead)`
  background-color: rgba(180, 180, 180, 0.2);
`;

export const BorderTable = styled(Table)`
  border: 1px solid rgba(120, 120, 120, 0.2);
  border-bottom: none;
`;

export const StatusDiv = (color: string) => styled.div`
  color: ${color};
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const CustomTabPanel = styled(TabPanel)`
  padding: 0 !important;
  & > div {
    padding: 0;
  }
`;
