import styled from '@emotion/styled';

export const StatusTextDiv = styled.div`
  text-align: center;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  user-select: none;
  font-size: 12px;
`;