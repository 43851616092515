/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button } from '@material-ui/core';
import { ButtonContainer } from 'components/Dialog/Dialog.styled';
import { DialogExtensionSDK } from 'contentful-ui-extensions-sdk';
import { ButtonVariantEnum } from 'enums/buttonVariant';
import { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogContent } from './CustomVariants.styled';

interface IProps {
  sdk: DialogExtensionSDK;
}

type TProps = IProps & WithTranslation;

export const SingleCancelledUpdateDialog = ({ sdk, t }: TProps) => {
  return (
    <Fragment>
      <DialogContent data-testid="singleCancelledDialog">
        <p>{t('queue.verificationPopup.firstLine')}</p>
      </DialogContent>
      <ButtonContainer>
        <Button
          size="small"
          variant="outlined"
          onClick={() => sdk.close(ButtonVariantEnum.QUIT)}
        >
          {t('common.ignore')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => sdk.close(ButtonVariantEnum.CONFIRM)}
        >
          {t('common.removeInXTM')}
        </Button>
      </ButtonContainer>
    </Fragment>
  );
};

export default withTranslation()(SingleCancelledUpdateDialog);
