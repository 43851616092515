/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Autocomplete } from '@material-ui/lab';
import { customFieldStyles } from 'components/Config/Form/CustomField.style';
import React, { Fragment, ReactNode } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import Name from '../TranslationQueue/Name/Name';
import {
  CustomEndAdornment,
  CustomTypography,
  selectStyles,
} from './form.styles';

interface IProps {
  options: { [key: string]: string }[];
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null,
  ) => void;
  onFocus?: (event: React.FocusEvent) => void
  multiple: boolean;
  inputProps: FieldInputProps<
    | { [key: string]: string }
    | Array<{ [key: string]: string }>
    | null
    | undefined,
    HTMLElement
  >;
  label: string;
  error?: string;
  meta: { error?: string; touched?: boolean };
  testId: string;
  spinner?: boolean;
  disabled?: boolean;
  fullscreen?: boolean;
  disableClearable?: boolean;
}

type PropType = IProps & WithTranslation;

export class CustomSelect extends React.Component<PropType> {
  handleChange = (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null,
  ) => {
    const { onChange, inputProps } = this.props;
    if (onChange) {
      onChange(event, value);
    }
    inputProps.onChange(value);
  };

  renderAdornment = (endAdornment: ReactNode) => {
    const { spinner, error, t } = this.props;
    if (spinner) {
      return (
        <CustomEndAdornment>
          <CircularProgress size={12} />
        </CustomEndAdornment>
      );
    }
    return (
      <Fragment>
        {error && (
          <Tooltip title={<Fragment>{t(error)}</Fragment>}>
            <ErrorIcon color="error" />
          </Tooltip>
        )}
        {endAdornment}
      </Fragment>
    );
  };

  render(): JSX.Element {
    const {
      options,
      multiple,
      inputProps,
      label,
      t,
      meta,
      testId,
      spinner,
      disabled,
      fullscreen,
    } = this.props;
    return (
      <div css={customFieldStyles.root}>
        <CustomTypography color="textSecondary" variant="subtitle2">
          {t(label)}
        </CustomTypography>
        <Autocomplete
          disabled={disabled || spinner}
          multiple={multiple}
          options={options}
          getOptionLabel={(option) => (option.label ? option.label : '')}
          onChange={this.handleChange}
          onFocus={this.props.onFocus}
          disableClearable={this.props.disableClearable}
          getOptionSelected={(option, value) =>
            value.label === option.label || !multiple
          }
          value={inputProps.value ? inputProps.value : []}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': testId,
              }}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: this.renderAdornment(
                  params.InputProps.endAdornment,
                ),
              }}
            />
          )}
          css={
            meta.error && meta.touched
              ? selectStyles.outlineError
              : selectStyles.select
          }
          renderOption={(option) => (
            <Name text={option.label} width={fullscreen ? 880 : 312} />
          )}
          size="small"
        />
        {meta.error && meta.touched && (
          <span css={selectStyles.textError}>{t(meta.error)}</span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomSelect);
