import { css, SerializedStyles } from '@emotion/core';

export const mainStyles = (): SerializedStyles => css`
  .outLineError {
    &:hover {
      fieldset {
        border-color: red !important;
        border-width: 3px;
      }
    }

      fieldset {
        border-color: red !important;
        border-width: 2px;
      }
    }
  

  .textError {
    color: red;
    text-align: start;
    font-style: oblique;
    max-width: 400px;
  }
`;
