import { createReducer } from '@reduxjs/toolkit';
import { initConfig, setDialog } from './config.actions';
import { IConfigState } from './config.interface';

const initState: IConfigState = {
  environmentId: '',
  spaceId: '',
  defaultLanguage: '',
  contentTypeIds: [],
  sdk: {} as any,
  dialogOpen: false,
};

export const configReducer = createReducer(initState, (builder) =>
  builder
    .addCase(initConfig, (state, { payload: { environmentId, spaceId, defaultLanguage, contentTypeIds, sdk } }) => {
      state.environmentId = environmentId;
      state.spaceId = spaceId;
      state.defaultLanguage = defaultLanguage;
      state.contentTypeIds = contentTypeIds;
      state.sdk = sdk;
    })
    .addCase(setDialog, (state, { payload }) => {
      state.dialogOpen = payload;
    })
);
