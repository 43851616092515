export const debounce = (
  callbackFunc: Function,
  limit: number,
): (() => void) => {
  let timeout: NodeJS.Timeout | null;
  return (): void => {
    const later = (): void => {
      timeout = null;
      return callbackFunc();
    };
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, limit);
  };
};
