import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const ConfigScreenWrapper = styled.div`
  height: 100vh;
  position: relative;
  background-color: #eff3f7 !important;
  overflow: auto;
`;

export const BackgroundWrapper = styled.div`
  margin: 0 20px;
  padding: 20px 0;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
`;

export const BodyWrapper = styled.div`
  width: 700px;
  margin: 30px;
`;

export const FieldWidthStyle = css`
  max-width: 100%;
`;

export const TooltipStyle = css`
  margin-top: 30px !important;
`;
