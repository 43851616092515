/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button } from '@material-ui/core';
import { ButtonContainer } from 'components/Dialog/Dialog.styled';
import { DialogExtensionSDK } from 'contentful-ui-extensions-sdk';
import { DialogParamEnum } from 'enums/dialogParam';
import { EntryFieldEnum } from 'enums/entryFieldsStatus';
import { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DialogContent } from './CustomVariants.styled';

interface IProps {
  sdk: DialogExtensionSDK;
  data: Array<EntryFieldEnum | undefined>;
}

type TProps = IProps & WithTranslation;

export const MultipleErrorsDialog = ({ sdk, data, t }: TProps) => {
  const renderList = () => {
    return (
      <ul>
        {data
          .filter((value) => value !== undefined)
          .map((value, index) => (
            <li key={index}>
              {t(`addcontent.errorsPopup.${(value as string).toLowerCase()}`)}
            </li>
          ))}
      </ul>
    );
  };
  return (
    <Fragment>
      <DialogContent data-testid="multipleErrorsDialog">
        <p>{t('addcontent.errorsPopup.firstLine')}</p>
        {renderList()}
      </DialogContent>
      <ButtonContainer>
        <Button
          size="small"
          variant="outlined"
          onClick={() => sdk.close(DialogParamEnum.CANCEL)}
        >
          {t('common.cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => sdk.close(DialogParamEnum.CONFIRM)}
        >
          {t('common.send')}
        </Button>
      </ButtonContainer>
    </Fragment>
  );
};

export default withTranslation()(MultipleErrorsDialog);
