/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { colors, Tooltip } from '@material-ui/core';
import { StatusEnum } from 'enums/status';
import { Fragment, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { XTMJobStatus } from 'utils/restApplicationClient';
import { StatusTextDiv } from './Status.styles';

interface IProps {
  status: XTMJobStatus;
  error?: boolean;
  width?: number;
}

interface IState {
  isOverflown: boolean;
}

type PropType = IProps & WithTranslation;

export class Status extends PureComponent<PropType, IState> {
  StatusDiv = styled.div`
    border: 2px solid black;
    border-radius: 6px;
    padding: 4px;
    width: ${this.props.width || 70}px;
    margin-left: 4px;
  `;

  state = {
    isOverflown: false,
  };

  componentDidMount() {
    this.checkIfOverflown();
  }

  componentDidUpdate() {
    this.checkIfOverflown();
  }

  checkIfOverflown() {
    const element = this.element;
    if (element) {
      const isOverflown =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;
      if (this.state.isOverflown !== isOverflown) {
        this.setState({ isOverflown });
      }
    }
  }

  getVariantStyle = () => {
    const { status, error } = this.props;
    let color;

    if (error) {
      color = colors.red[600];
    } else if (status === StatusEnum.IN_PROGRESS) {
      color = colors.lightBlue[600];
    } else if (status === StatusEnum.CANCELLED) {
      color = colors.red[600];
    } else if (status === StatusEnum.FINISHED) {
      color = colors.green[600];
    } else if (status === StatusEnum.FETCHED) {
      color = colors.grey[500];
    } else if (status === StatusEnum.SENDING) {
      color = colors.blueGrey[500];
    } else {
      color = colors.grey[900];
    }
    return css`
      color: ${color};
      border-color: ${color};
    `;
  };

  getLabel = (): string | undefined => {
    const { status, error, t } = this.props;

    if (error) {
      return t('queue.error');
    }

    switch (status) {
      case StatusEnum.CANCELLED: {
        return t('queue.cancelled');
      }
      case StatusEnum.FINISHED: {
        return t('queue.finished');
      }
      case StatusEnum.IN_PROGRESS: {
        return t('queue.inProgress');
      }
      case StatusEnum.NEW: {
        return t('queue.new');
      }
      case StatusEnum.SENT: {
        return t('queue.sent');
      }
      case StatusEnum.FETCHED: {
        return t('queue.finished');
      }
      case StatusEnum.SENDING: {
        return t('queue.sending');
      }
      default:
        return undefined;
    }
  };

  element: HTMLDivElement | null = null;
  render() {
    const { isOverflown } = this.state;
    return (
      <Fragment>
        <this.StatusDiv css={this.getVariantStyle()} data-testid="statusDiv">
          {isOverflown ? (
            <Tooltip
              title={<Fragment>{this.getLabel()}</Fragment>}
              data-testid="statusTooltip"
            >
              <StatusTextDiv
                ref={(element) => {
                  this.element = element;
                }}
              >
                {this.getLabel()}
              </StatusTextDiv>
            </Tooltip>
          ) : (
            <StatusTextDiv
              ref={(element) => {
                this.element = element;
              }}
            >
              {this.getLabel()}
            </StatusTextDiv>
          )}
        </this.StatusDiv>
      </Fragment>
    );
  }
}

export default withTranslation()(Status);
