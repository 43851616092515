import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { switchMap, pluck } from 'rxjs/operators';
import { initConfig } from 'store/config/config.actions';
import { InitConfigPayload } from 'store/config/config.interface';
import { loginSuccess } from './auth.actions';

const loginSuccessEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginSuccess.type),
    pluck<PayloadAction<InitConfigPayload | undefined>, 'payload'>('payload'),
    switchMap((payload) => {
      if (!payload) {
        return EMPTY;
      }

      return of(initConfig(payload));
    })
  );

export const authEpics = combineEpics(loginSuccessEpic);
