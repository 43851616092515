import styled from '@emotion/styled';

export const StatusMenuItemDiv = styled.div`
  display: flex;
  align-items: center;
  color: '#536171';
  > svg {
    margin-right: 12px;
  }
`;

export const SpinnerMargin = styled.div`
  margin: auto 15px;
`;

export const ButtonMargin = styled.div`
  margin: auto 10px;
`;
