/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { AxiosError } from "axios";
import {
  PageExtensionSDK,
  SidebarExtensionSDK,
} from "contentful-ui-extensions-sdk";
import { ButtonVariantEnum } from "enums/buttonVariant";
import { DialogParamEnum } from "enums/dialogParam";
import { DialogVariantEnum } from "enums/dialogVariant";
import { EntryFieldEnum } from "enums/entryFieldsStatus";
import { createForm, FormApi } from "final-form";
import uniqBy from "lodash.uniqby";
import moment, { Moment } from "moment";
import React, { Component } from "react";
import { Field, FieldInputProps, Form } from "react-final-form";
import { withTranslation, WithTranslation } from "react-i18next";
import { ApiError, apiErrorHandler } from "utils/apiErrorHandler";
import { api, urlStorageKey } from "utils/axiosInstance";
import { checkEntryFields } from "utils/checkEntryFields";
import {
  projectNameValidator,
  requiredAutocomplete,
} from "utils/customValidators";
import {
  EntryGroupingType,
  ReferenceDTO,
  XTMProjectDTO,
  XTMProjectEntriesVerifyDTO,
} from "utils/restApplicationClient";
import {
  GetCustomersDTO,
  GetTemplatesDTO,
  TranslateRequestDTO,
} from "utils/restApplicationClientTypeOverrides";
import EntriesTable from "../EntriesTable/EntriesTable";
import CustomDatepicker from "./CustomDatepicker";
import CustomSelect from "./CustomSelect";
import {
  ManageReferenceButtonWrapper,
  ButtonGroup,
  ButtonWrapper,
  selectStyles,
  TitleTypography,
} from "./form.styles";
import CustomCheckbox from "./CustomCheckbox";
import CustomField from "./CustomField";
import { PROJECTNAME_WHITESPACE_REGEX } from "../../../utils/customRegexp";
import { getAllNodesIds } from "utils/getAllNodesIds";
import DiagramProjectIcon from "./ManageReferencesIcon";
import { debounce } from "utils/debounce";

interface IFormValues {
  activeProject: { label: string; value: string };
  dueDate: string;
  sourceLanguage: { label: string; value: string };
  targetLanguages: Array<{ label: string; value: string }>;
  xtmCustomer: { label: string; value: string };
  xtmTemplate: { label: string; value: string };
  entryGrouping:
    | { label: string; value: EntryGroupingType }
    | boolean
    | undefined;
  projectName?: string;
  projectDescription: string;
  prefix?: string;
  mergeFilesCheckbox: boolean;
}

interface IProps {
  sdk: SidebarExtensionSDK | PageExtensionSDK;
  fullscreen?: boolean;
}

interface IState {
  sourceLanguages: Array<string>;
  xtmCustomer: GetCustomersDTO;
  xtmTemplate: GetTemplatesDTO;
  targetLanguages: Array<string>;
  activeProjects?: XTMProjectDTO[];
  activeProject?: { label: string; value: string };
  xtmCustomerSpinner: boolean;
  xtmTemplateSpinner: boolean;
  activeProjectsSpinner: boolean;
  translateSpinner: boolean;
  queueSpinner: boolean;
  entriesIds: Array<string>;
  allowUserChange: boolean;
  entryGrouping: EntryGroupingType | undefined;
  defaultEntryGrouping: EntryGroupingType | undefined;
  projectName?: string;
  projectDescription: string;
  prefix?: string;
  mergeFilesCheckbox: boolean;
  entryGroupingDisabled?: boolean;
  dueDate: moment.Moment | null;
  datePickerDisabled: boolean;
  datePickerButtonDisabled: boolean;
  clearDatePickerButtonDisabled: boolean;
  clearEntryGroupingDisabled: boolean;
  entryGroupingChecked: boolean;
  displayDatePickerClearButton: boolean;
  manageReferencesEntriesData: ReferenceDTO[] | null;
  manageReferencesLimitError: string;
  manageReferencesEntriesIds: string[] | undefined;
  getManageReferencesSpinner: boolean;
  ignoreChildren: boolean;
  entryLevelLocalization: boolean;
}

type PropType = IProps & WithTranslation;

export class FormContainer extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      sourceLanguages: [],
      targetLanguages: [],
      xtmCustomer: { customers: [], defaultCustomerId: -1 },
      xtmTemplate: { templates: [], defaultTemplateId: -1 },
      activeProjects: undefined,
      xtmCustomerSpinner: false,
      xtmTemplateSpinner: false,
      activeProjectsSpinner: false,
      translateSpinner: false,
      queueSpinner: false,
      entriesIds: [],
      allowUserChange: false,
      entryGrouping: undefined,
      defaultEntryGrouping: undefined,
      projectName: "",
      projectDescription: "",
      prefix: "",
      mergeFilesCheckbox: false,
      entryGroupingDisabled: false,
      dueDate: null,
      datePickerDisabled: false,
      datePickerButtonDisabled: false,
      clearDatePickerButtonDisabled: false,
      clearEntryGroupingDisabled: true,
      entryGroupingChecked: false,
      displayDatePickerClearButton: false,
      manageReferencesEntriesData: null,
      manageReferencesLimitError: "",
      manageReferencesEntriesIds: undefined,
      getManageReferencesSpinner: false,
      ignoreChildren: false,
      entryLevelLocalization: false,
    };
    this.form = createForm({
      onSubmit: this.onSubmit(false),
    });
  }

  onSysChangedListener?: Function;
  ignoreFirstEvent = true;

  componentDidMount(): void {
    const { sdk } = this.props;
    const languages = sdk.locales.available;
    const ids = {
      spaceId: sdk.ids.space,
      environmentId: sdk.ids.environment,
    };
    if (!this.props.fullscreen) {
      api
        .getClientPreferences(
          this.props.sdk.ids.space,
          this.props.sdk.ids.environment,
          {
            entriesIds: [
              (this.props.sdk as SidebarExtensionSDK)?.entry?.getSys()?.id,
            ],
          }
        )
        .then(({ data: { entryLevelLocalization } }) =>
          this.setState({
            entryLevelLocalization,
          })
        )
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorCode &&
            error.response.data.errorCode ===
              "errormessage.contentful.toomanylinksinreference"
          ) {
            this.setState({
              manageReferencesLimitError: error.response.data.errorCode,
            });
          }
        });
    }
    api
      .getUserSettings(ids)
      .then(({ data }) =>
        this.setState({ allowUserChange: data.allowUserChangeEntryGrouping })
      );
    api.getUserSettings(ids).then(({ data }) =>
      this.setState({
        defaultEntryGrouping: data.defaultEntryGroupingType,
        entryGrouping: data.defaultEntryGroupingType,
      })
    );
    this.handleSysChanged();
    this.setState({
      sourceLanguages: languages,
      targetLanguages: languages.filter(
        (language) => language !== sdk.locales.default
      ),
      xtmCustomerSpinner: true,
      xtmTemplateSpinner: true,
    });
    api
      .getXTMCustomers$GET$api_content_customers()
      .then(({ data }) => {
        this.setState({ xtmCustomer: data });
        if (data.defaultCustomerId) {
          this.setState({ xtmTemplateSpinner: true });
          api
            .getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(
              data.defaultCustomerId
            )
            .then(({ data }) => {
              this.setState({ xtmTemplate: data });
            })
            .catch((error) => apiErrorHandler(error, sdk))
            .finally(() => {
              this.setState({ xtmTemplateSpinner: false });
            });
        }
      })
      .catch((error) => apiErrorHandler(error, sdk))
      .finally(() => {
        this.setState({ xtmCustomerSpinner: false, xtmTemplateSpinner: false });
      });
  }

  componentWillUnmount() {
    if (this.onSysChangedListener) {
      this.onSysChangedListener();
    }
  }

  componentDidUpdate = (_: IProps, prevState: IState) => {
    if (
      prevState.entriesIds.length !== this.state.entriesIds.length &&
      !!this.state.entriesIds.length
    ) {
      this.setState({ ignoreChildren: false });
      this.setState({ manageReferencesEntriesIds: undefined });
      this.setState({
        manageReferencesLimitError: "",
      });

      api
        .getClientPreferences(
          this.props.sdk.ids.space,
          this.props.sdk.ids.environment,
          {
            entriesIds: this.state.entriesIds,
          }
        )
        .then(({ data: { entryLevelLocalization } }) =>
          this.setState({ entryLevelLocalization })
        )
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorCode &&
            error.response.data.errorCode ===
              "errormessage.contentful.toomanylinksinreference"
          ) {
            this.setState({
              manageReferencesLimitError: error.response.data.errorCode,
            });
          }
        });
    }
  };

  handleSysChanged = () => {
    const { fullscreen, sdk } = this.props;
    const { space, environment } = sdk.ids;
    if (!fullscreen) {
      const { id } = (sdk as SidebarExtensionSDK).entry.getSys();
      this.onSysChangedListener = (
        sdk as SidebarExtensionSDK
      ).entry.onSysChanged(() => {
        if (this.ignoreFirstEvent) {
          this.ignoreFirstEvent = false;
        } else {
          api
            .compareContent(space, environment, id)
            .catch((error) => apiErrorHandler(error, sdk));
        }
      });
    }
  };

  getActiveProjects(values: IFormValues) {
    const { sdk } = this.props;
    api
      .getActiveXTMProjects({
        sourceLanguage: values.sourceLanguage.value,
        targetLanguages: values.targetLanguages.map(
          (language) => language.value
        ),
        customerId: Number(values.xtmCustomer.value),
      })
      .then(({ data }) => {
        this.setState({ activeProjects: data });
      })
      .catch((error) => apiErrorHandler(error, sdk))
      .finally(() => {
        this.setState({ activeProjectsSpinner: false });
      });
  }

  onSubmit =
    (addToQueue: boolean) =>
    async (values: IFormValues): Promise<void> => {
      const { sdk, t, fullscreen } = this.props;
      if ((await this.isFormValid(values)) === DialogParamEnum.CONFIRM) {
        this.setState({
          translateSpinner: !addToQueue,
          queueSpinner: addToQueue,
        });
        if (!values.activeProject) {
          const entries = await this.verifyProject(values);
          const entriesToTranslate: TranslateRequestDTO["entries"] = {};
          if (entries) {
            let translationProject = this.getTranslationParameters(values);
            entriesToTranslate["new"] = translationProject.entries[
              "new"
            ].filter((entryId) => !entries.find((e) => e.entryId === entryId));
            Array.from(new Set(entries.map((e) => e.projectId))).forEach(
              (projectId) => {
                entriesToTranslate[projectId] = [
                  ...entries
                    .filter((e) => e.projectId === projectId)
                    .map((e) => e.entryId),
                ];
              }
            );
            try {
              if (addToQueue) {
                await api.addToQueue({
                  ...translationProject,
                  entries: entriesToTranslate,
                });
              } else {
                await api.translate({
                  ...translationProject,
                  entries: entriesToTranslate,
                });
              }

              this.getActiveProjects(values);
              sdk.notifier.success(
                t(
                  addToQueue
                    ? "addcontent.success.addToQueue"
                    : "addcontent.success.sendForTranslation",
                  {
                    count: this.countEntries(entriesToTranslate),
                  }
                )
              );
            } catch (error) {
              apiErrorHandler(error as AxiosError<ApiError>, sdk);
            } finally {
              this.setState({ translateSpinner: false, queueSpinner: false });
            }
            return;
          } else if (entries === undefined) {
            this.setState({ translateSpinner: false, queueSpinner: false });
            return;
          }
        }
        const translationParameters = this.getTranslationParameters(values);
        if (!fullscreen) {
        }
        try {
          // if (addToQueue) {
          //   await api.addToQueue(translationParameters);
          // } else {
          //   // const translate = await api.translate(translationParameters);
          //   // if ( && translate.data.length > 0) {
          //   //   sdk.dialogs.openCurrent({
          //   //     parameters: {
          //   //       title: t("addcontent.dialog.title"),
          //   //       variant: DialogVariantEnum.TABLE_VIEW,
          //   //       data: translate.data,
          //   //     },
          //   //   });
          //   // }
          // }
          if (addToQueue) {
            await api.addToQueue({
              ...translationParameters,
            });
          } else {
            await api.translate({
              ...translationParameters,
            });
          }
          this.getActiveProjects(values);
          sdk.notifier.success(
            t(
              addToQueue
                ? "addcontent.success.addToQueue"
                : "addcontent.success.sendForTranslation",
              {
                count: this.countEntries(translationParameters.entries),
              }
            )
          );
        } catch (error) {
          apiErrorHandler(error as AxiosError<ApiError>, sdk);
        } finally {
          this.setState({ translateSpinner: false, queueSpinner: false });
        }
      }
    };

  countEntries = (entries: Record<string, string[]>) => {
    let count = 0;

    Object.entries(entries).forEach(([, indexEntries]) => {
      count += indexEntries.length;
    });

    return count;
  };

  verifyProject = async (
    values: IFormValues
  ): Promise<Array<XTMProjectEntriesVerifyDTO> | undefined | null> => {
    const { fullscreen, sdk, t } = this.props;
    const { data } = await api
      .verify({
        customerId: Number(values.xtmCustomer.value),
        templateId: Number(values.xtmTemplate.value),
        sourceLanguage: values.sourceLanguage.value,
        targetLanguages: values.targetLanguages.map((lang: any) => lang.value),
        environmentId: sdk.ids.environment,
        spaceId: sdk.ids.space,
        entryIds: fullscreen
          ? [...this.state.entriesIds]
          : [(sdk as SidebarExtensionSDK).entry.getSys().id],
      })
      .catch((error) => apiErrorHandler(error, sdk));

    if (data && data.haveProjects) {
      const entriesToDisplay = uniqBy(data.entries, "entryId");
      return await sdk.dialogs
        .openCurrent({
          allowHeightOverflow: true,
          parameters: {
            title: t("common.warning"),
            variant:
              entriesToDisplay.length > 1
                ? DialogVariantEnum.MULTIPLE_UPDATE
                : DialogVariantEnum.SINGLE_UPDATE,
            data: entriesToDisplay,
          },
        })
        .then((res: Array<XTMProjectEntriesVerifyDTO> | undefined) => {
          return (
            res &&
            data.entries.filter((e: XTMProjectEntriesVerifyDTO) =>
              res.find((r) => r.entryId === e.entryId)
            )
          );
        });
    }

    return null;
  };

  addToQueue = async (): Promise<void> => {
    const values = this.form.getState().values;
    this.form.getRegisteredFields().forEach((field) => {
      this.form.focus(field as any);
      this.form.blur(field as any);
    });

    if (!this.form.getState().hasValidationErrors) {
      await this.onSubmit(true)(values);
    }
  };

  async isFormValid(formValues: IFormValues): Promise<DialogParamEnum> {
    const { sdk, fullscreen } = this.props;
    if (!fullscreen) {
      const checkValuesStatus = await checkEntryFields(
        sdk as SidebarExtensionSDK,
        formValues.sourceLanguage.value
      );
      if (
        checkValuesStatus.length === 1 &&
        checkValuesStatus.indexOf(EntryFieldEnum.EMPTY) > -1
      ) {
        return await sdk.dialogs.openCurrent({
          width: 500,
          parameters: {
            title: "modal.warning",
            text: "modal.emptyAlert",
            buttons: this.emptyButtons,
          },
        });
      }

      if (checkValuesStatus.length === 0) {
        return await sdk.dialogs.openCurrent({
          width: 510,
          parameters: {
            title: "modal.warning",
            text: "modal.noLocaleAlert",
            buttons: this.emptyButtons,
          },
        });
      }
    }
    return DialogParamEnum.CONFIRM;
  }

  getTranslationParameters(values: IFormValues): TranslateRequestDTO {
    const { sdk, fullscreen } = this.props;
    const { entriesIds } = this.state;
    const entries: TranslateRequestDTO["entries"] = {};

    const entryGrouping = (
      data:
        | boolean
        | {
            label: string;
            value: EntryGroupingType;
          }
        | undefined
    ): EntryGroupingType => {
      if (fullscreen) {
        // @ts-ignore
        return typeof data === "object" ? data.value : this.state.entryGrouping;
      } else if (data) {
        return "ALL_IN_ONE_FILE";
      } else return "SEPARATELY";
    };

    const projectNameFiltering = (data: string | undefined) => {
      if (
        !data ||
        (data && data.match(PROJECTNAME_WHITESPACE_REGEX)) ||
        data === this.state.projectName
      ) {
        return undefined;
      } else {
        return this.state.prefix + data;
      }
    };

    const projectDescriptionFiltering = (data: string | undefined) => {
      return data && data !== this.state.projectDescription ? data : undefined;
    };

    entries[values.activeProject?.value ? values.activeProject?.value : "new"] =
      fullscreen
        ? [...entriesIds]
        : [(sdk as SidebarExtensionSDK).entry.getSys().id];
    return {
      contentfulUsername: sdk.user.email,
      customerId: Number(values.xtmCustomer.value),
      entries,
      environmentId: sdk.ids.environment,
      sourceLanguage: values.sourceLanguage.value,
      targetLanguages: values.targetLanguages.map((lang: any) => lang.value),
      spaceId: sdk.ids.space,
      templateId: Number(values.xtmTemplate.value),
      dueDate: values.dueDate ? this.parseDate(values.dueDate) : null,
      serverUrl: localStorage.getItem(urlStorageKey) || "",
      entryGrouping: entryGrouping(values.entryGrouping),
      projectName: projectNameFiltering(values.projectName),
      mergeFilesCheckbox: fullscreen ? true : this.state.mergeFilesCheckbox,
      projectDescription: projectDescriptionFiltering(
        values.projectDescription
      ),
      selectedEntries: this.state.ignoreChildren
        ? this.state.manageReferencesEntriesIds
        : [],
      ignoreChildren: this.state.ignoreChildren,
    };
  }

  parseDate = (dateString: string) => {
    const now = moment();
    const date = moment(dateString);
    if (date.isBefore(now)) {
      date.hour(now.hour() + 1).minute(0);
    }
    return date.toDate();
  };

  parseLanguages = (
    languages: Array<string>
  ): Array<{ [key: string]: string }> => {
    return languages.map((language) => {
      return { value: language, label: language };
    });
  };

  parseCustomers = (
    customers: GetCustomersDTO
  ): Array<{ [key: string]: string }> => {
    if (customers.customers.length > 0) {
      return customers.customers.map((customer) => {
        return {
          value: customer.xtmCustomerId.toString(),
          label: customer.name,
        };
      });
    }
    return [];
  };

  parseTemplates = (
    templates: GetTemplatesDTO
  ): Array<{ [key: string]: string }> => {
    if (templates.templates.length > 0) {
      return templates.templates.map((template) => {
        return {
          value: template.xtmTemplateId.toString(),
          label: template.name,
        };
      });
    }
    return [];
  };

  parseProjects = (
    projects?: Array<XTMProjectDTO>
  ): Array<{ [key: string]: string }> => {
    if (projects) {
      return projects.map((project) => {
        return this.parseProject(project);
      });
    }
    return [];
  };

  parseProject = (project: XTMProjectDTO): { label: string; value: string } => {
    return { value: project.id.toString(), label: project.name };
  };

  parseEntryGrouping: Array<{ [key: string]: string }> = [
    { value: "SEPARATELY", label: "Submit separately" },
    { value: "ALL_IN_ONE_FILE", label: "Submit as one source file" },
    { value: "ENTRY_GROUPING", label: "Merge by entry" },
  ];

  getDefaultCustomer = (
    customers: GetCustomersDTO
  ): { [key: string]: string } | undefined => {
    if (customers.defaultCustomerId && customers.defaultCustomerId >= 0) {
      const defaultCustomer = customers.customers.find(
        (customer) => customer.xtmCustomerId === customers.defaultCustomerId
      );
      if (defaultCustomer) {
        return {
          value: defaultCustomer.xtmCustomerId.toString(),
          label: defaultCustomer.name,
        };
      }
    }
    return undefined;
  };

  getDefaultTemplate = (
    templates: GetTemplatesDTO
  ): { [key: string]: string } | undefined => {
    if (templates.defaultTemplateId && templates.defaultTemplateId >= 0) {
      const defaultTemplate = templates.templates.find(
        (template) => template.xtmTemplateId === templates.defaultTemplateId
      );
      if (defaultTemplate) {
        return {
          value: defaultTemplate.xtmTemplateId.toString(),
          label: defaultTemplate.name,
        };
      }
    }
    return undefined;
  };

  handleCustomerChange = (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null
  ): void => {
    const { sdk } = this.props;
    const values = this.form.getState().values;
    this.form.change("activeProject", undefined);
    this.setState({ activeProjects: undefined });
    if (value && !Array.isArray(value)) {
      if (
        values.sourceLanguage &&
        values.targetLanguages &&
        values.targetLanguages.length > 0
      ) {
        this.setState({ activeProjectsSpinner: true });
        api
          .getActiveXTMProjects({
            sourceLanguage: values.sourceLanguage.value,
            targetLanguages: values.targetLanguages.map(
              (language) => language.value
            ),
            customerId: Number(value.value),
          })
          .then(({ data }) => {
            this.setState({ activeProjects: data });
          })
          .catch((error) => apiErrorHandler(error, sdk))
          .finally(() => {
            this.setState({ activeProjectsSpinner: false });
          });
      }
      this.setState({ xtmTemplateSpinner: true });
      api
        .getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(
          Number(value.value)
        )
        .then(({ data }) => {
          this.form.change("xtmTemplate", undefined);
          this.setState({ xtmTemplate: data });
        })
        .catch((error) => apiErrorHandler(error, sdk))
        .finally(() => {
          this.setState({ xtmTemplateSpinner: false });
        });
    } else {
      this.form.change("xtmTemplate", undefined);
      this.setState({
        xtmTemplate: { defaultTemplateId: null, templates: [] },
      });
    }
  };

  handleSourceLanguageChange = (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null
  ): void => {
    const { sdk } = this.props;
    const languages = sdk.locales.available;
    this.form.change("activeProject", undefined);
    this.setState({ activeProjects: undefined });
    this.clearPopulatedProjectData();

    if (value && !Array.isArray(value)) {
      const previousTargetLanguages =
        this.form.getState().values.targetLanguages;
      const customerId =
        this.form.getState().values.xtmCustomer &&
        this.form.getState().values.xtmCustomer.value;
      const newTargetLanguages =
        (previousTargetLanguages &&
          previousTargetLanguages.filter(
            (language) => language.value !== value.value
          )) ||
        undefined;
      this.form.change("targetLanguages", newTargetLanguages);
      this.setState({
        targetLanguages: languages.filter(
          (language) => language !== value.value
        ),
        activeProjects: undefined,
      });
      if (newTargetLanguages && newTargetLanguages.length > 0 && customerId) {
        const targetLanguages = newTargetLanguages.map(
          (language) => language.value
        );
        this.handleActiveProjects(value.value, targetLanguages, customerId);
      }
    } else {
      this.setState({ targetLanguages: languages });
    }
  };

  handleTargetLanguagesChange = (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null
  ): void => {
    this.form.change("activeProject", undefined);
    this.setState({ activeProjects: undefined });
    this.clearPopulatedProjectData();

    if (value && Array.isArray(value) && value.length > 0) {
      const targetLanguages = value.map((language) => language.value);
      const formState = this.form.getState();
      const customerId =
        formState.values.xtmCustomer && formState.values.xtmCustomer.value;
      const sourceLanguage =
        formState.values.sourceLanguage?.value || undefined;
      if (sourceLanguage && customerId) {
        this.handleActiveProjects(sourceLanguage, targetLanguages, customerId);
      }
    }
  };

  handleSelectEntries = (entriesIds: Array<string>) => {
    this.setState({ entriesIds });
    this.handleActiveProjectsOnFocus();
  };

  handleActiveProjects = (
    sourceLanguage: string,
    targetLanguages: string[],
    customerId: string
  ) => {
    if (sourceLanguage !== undefined && targetLanguages !== undefined) {
      const { sdk } = this.props;
      if (sourceLanguage && targetLanguages && customerId) {
        this.setState({ activeProjectsSpinner: true });
        api
          .getActiveXTMProjects({
            sourceLanguage: sourceLanguage,
            targetLanguages: targetLanguages,
            customerId: Number(customerId),
          })
          .then(({ data }) => {
            this.setState({ activeProjects: data });
          })
          .catch((error) => apiErrorHandler(error, sdk))
          .finally(() => {
            this.setState({ activeProjectsSpinner: false });
          });
      }
    }
  };

  handleActiveProjectsOnFocus = () => {
    const { sourceLanguage, targetLanguages, xtmCustomer } =
      this.form.getState().values;

    if (targetLanguages !== undefined) {
      const targetLanguagesMap = targetLanguages.map((data) => data.value);
      this.handleActiveProjects(
        sourceLanguage.value,
        targetLanguagesMap,
        xtmCustomer.value
      );
    }
  };

  // errorButtons: Array<{ label: string; variant: ButtonVariantEnum }> = [
  //   { label: 'modal.cancel', variant: ButtonVariantEnum.QUIT },
  //   { label: 'modal.send', variant: ButtonVariantEnum.CONFIRM },
  // ];

  emptyButtons: Array<{ label: string; variant: ButtonVariantEnum }> = [
    { label: "modal.ok", variant: ButtonVariantEnum.OK },
  ];

  form: FormApi<IFormValues>;

  mapEntryGroupingForDefault = (
    entryGrouping: EntryGroupingType | undefined
  ) => {
    switch (entryGrouping) {
      case "SEPARATELY":
        return { value: "SEPARATELY", label: "Submit separately" };
      case "ALL_IN_ONE_FILE":
        return { value: "ALL_IN_ONE_FILE", label: "Submit as one source file" };
      case "ENTRY_GROUPING":
        return { value: "ENTRY_GROUPING", label: "Merge by entry" };
      default:
        return undefined;
    }
  };

  handleActiveProjectChange = (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null
  ): void => {
    if (value) {
      const activeProject = value as { label: string; value: string };
      const { sdk } = this.props;
      const ids = {
        spaceId: sdk.ids.space,
        environmentId: sdk.ids.environment,
      };
      this.getProjectPrefix(ids);

      api
        .getActiveXTMProjectDetails(Number(activeProject.value))
        .then(({ data }) => this.populateProjectData(data, activeProject))
        .catch((error) => apiErrorHandler(error, sdk));
    } else {
      this.clearPopulatedProjectData();
    }
  };

  populateProjectData = (
    data: XTMProjectDTO,
    activeProject: { label: string; value: string }
  ) => {
    const entryGrouping = this.mapEntryGroupingForDefault(
      data.entryGroupingType
    );
    this.handleEntryGroupingCheckedStatus(data.entryGroupingType);

    this.setState({
      projectDescription: data.description,
      entryGrouping: data.entryGroupingType,
      projectName: this.removePrefixFromProjectName(data.name),
      activeProject: activeProject,
      dueDate: this.convertTimestampToMoment(data.dueDate),
      datePickerDisabled: true,
      datePickerButtonDisabled: true,
      clearDatePickerButtonDisabled: true,
      entryGroupingDisabled: true,
      displayDatePickerClearButton: true,
    });

    this.form.change(
      "dueDate",
      this.convertTimestampToDateString(data.dueDate)
    );
    this.form.change("activeProject", activeProject);
    this.form.change("projectDescription", data.description);
    this.form.change(
      "projectName",
      this.removePrefixFromProjectName(data.name)
    );
    this.form.change("entryGrouping", {
      label: entryGrouping !== undefined ? entryGrouping.label : "",
      value: data.entryGroupingType,
    });
  };

  clearPopulatedProjectData = () => {
    this.setState({
      projectDescription: "",
      entryGrouping: this.state.defaultEntryGrouping,
      projectName: undefined,
      activeProject: undefined,
      datePickerDisabled: false,
      datePickerButtonDisabled: false,
      entryGroupingDisabled: false,
      clearDatePickerButtonDisabled: false,
      entryGroupingChecked: false,
      mergeFilesCheckbox: false,
      dueDate: null,
      prefix: undefined,
      displayDatePickerClearButton: false,
    });

    this.form.change("activeProject", undefined);
    this.form.change("projectDescription", undefined);
    this.form.change("projectName", undefined);
    this.form.change(
      "entryGrouping",
      this.parseEntryGroupingEnum(this.state.entryGrouping)
    );
    this.form.change("dueDate", "");
  };

  removePrefixFromProjectName = (projectName: string): string => {
    return this.state.prefix !== undefined &&
      projectName.startsWith(this.state.prefix)
      ? projectName.split(this.state.prefix)[1]
      : projectName;
  };

  handleEntryGroupingCheckedStatus = (data: EntryGroupingType) => {
    data === "ALL_IN_ONE_FILE"
      ? this.setState({ entryGroupingChecked: true })
      : this.setState({ entryGroupingChecked: false });
  };

  parseEntryGroupingEnum = (
    entryGrouping: EntryGroupingType | undefined
  ): { label: string; value: EntryGroupingType } | undefined => {
    const defaultEntryGrouping = this.mapEntryGroupingForDefault(entryGrouping);
    return defaultEntryGrouping !== undefined && entryGrouping !== undefined
      ? { label: defaultEntryGrouping.label, value: entryGrouping }
      : undefined;
  };

  handleDueDate = (date: Moment | null) => {
    this.setState({ dueDate: date });
    this.setState({ displayDatePickerClearButton: true });
  };

  handleDueDateClear = () => {
    this.setState({ dueDate: null });
    this.setState({ displayDatePickerClearButton: false });
  };

  convertTimestampToMoment = (timestamp: string): Moment | null => {
    return ["", undefined, null].includes(timestamp)
      ? null
      : moment(this.convertTimestampToDateString(timestamp), "LLLL");
  };

  convertTimestampToDateString = (timestamp: string): string => {
    return ["", undefined, null].includes(timestamp)
      ? ""
      : moment(Number(timestamp)).format("LLLL");
  };

  handleMergeFilesCheckbox = (): void => {
    this.setState({ mergeFilesCheckbox: !this.state.mergeFilesCheckbox });
    this.setState({ entryGroupingChecked: !this.state.entryGroupingChecked });

    !this.state.mergeFilesCheckbox
      ? this.setState({ entryGrouping: "ALL_IN_ONE_FILE" })
      : this.setState({ entryGrouping: "SEPARATELY" });
  };

  handleEntryGroupingChange = (
    event: React.ChangeEvent<{}>,
    value: { [key: string]: string } | Array<{ [key: string]: string }> | null
  ): void => {
    if (value) {
      const entryGrouping = value as {
        label: string;
        value: EntryGroupingType;
      };
      this.setState({ entryGrouping: entryGrouping.value });
    }
  };

  getProjectPrefix = (ids: {
    spaceId: string;
    environmentId: string;
  }): void => {
    api.getUserSettings(ids).then(({ data }) => {
      this.setState({ prefix: data.xtmProjectNamePrefix + "_" });
    });
  };

  getManageReferencesEntries = async () => {
    const { sourceLanguage } = this.form.getState().values;
    this.setState({ getManageReferencesSpinner: true });

    return api
      .getReferences(
        this.props.sdk.ids.space,
        this.props.sdk.ids.environment,
        {
          entriesIds: this.props.fullscreen
            ? this.state.entriesIds
            : [(this.props.sdk as SidebarExtensionSDK)?.entry?.getSys()?.id],
        },
        { locale: sourceLanguage.value }
      )
      .then(({ data }) => {
        this.setState({ manageReferencesEntriesData: data });
        if (this.state.manageReferencesEntriesIds === undefined) {
          this.setState({
            manageReferencesEntriesIds: getAllNodesIds(data),
          });
        }
        return data;
      })
      .catch((error) => apiErrorHandler(error, this.props.sdk))
      .finally(() => {
        this.setState({ getManageReferencesSpinner: false });
      });
  };

  handleOpenReferencesModal = debounce(async () => {
    const { sdk, t } = this.props;
    if (this.state.manageReferencesLimitError) {
      return await sdk.dialogs.openCurrent({
        width: 500,
        parameters: {
          title: "modal.warning",
          text: "modal.tooManyLinksInReferences",
          buttons: this.emptyButtons,
        },
      });
    }

    await this.getManageReferencesEntries();

    if (!this.state.manageReferencesEntriesData) {
      return await sdk.dialogs.openCurrent({
        width: 500,
        parameters: {
          title: "modal.warning",
          text: "modal.manageReferencesErrorData",
          buttons: this.emptyButtons,
        },
      });
    }

    sdk.dialogs
      .openCurrent({
        parameters: {
          title: t("addcontent.manageReferences"),
          variant: DialogVariantEnum.MANAGE_REFERENCES,
          data: {
            entries: this.state.manageReferencesEntriesData,
            selectedIds: this.state.manageReferencesEntriesIds,
          },
        },
      })
      .then((selectedNodes) => {
        if (!selectedNodes) {
          return;
        }
        this.setState({ ignoreChildren: true });
        return this.setState({ manageReferencesEntriesIds: selectedNodes });
      });
  }, 500);

  render() {
    const {
      sourceLanguages,
      xtmCustomer,
      xtmTemplate,
      targetLanguages,
      activeProjects,
      xtmCustomerSpinner,
      xtmTemplateSpinner,
      activeProjectsSpinner,
      translateSpinner,
      queueSpinner,
      entriesIds,
      allowUserChange,
      entryGrouping,
      entryGroupingDisabled,
      dueDate,
      datePickerDisabled,
      datePickerButtonDisabled,
      clearDatePickerButtonDisabled,
      clearEntryGroupingDisabled,
      entryGroupingChecked,
      displayDatePickerClearButton,
    } = this.state;
    const { t, fullscreen, sdk } = this.props;
    const defaultCustomer = this.getDefaultCustomer(xtmCustomer);
    const defaultTemplate = this.getDefaultTemplate(xtmTemplate);
    const defaultSourceLanguage = this.parseLanguages([sdk.locales.default])[0];
    const isNotEntriesSelected = fullscreen && entriesIds.length === 0;
    const ids = {
      spaceId: sdk.ids.space,
      environmentId: sdk.ids.environment,
    };

    const onProjectNameFocus = (): void => {
      this.getProjectPrefix(ids);
    };

    const onProjectNameBlur = (value: any) => {
      if (!value || value.match(PROJECTNAME_WHITESPACE_REGEX)) {
        this.setState({ prefix: undefined });
        this.form.change("projectName", "");
      }
    };

    return (
      <div>
        {fullscreen && (
          <EntriesTable sdk={sdk} onSelectEntries={this.handleSelectEntries} />
        )}
        <Form
          onSubmit={this.onSubmit(false)}
          subscription={{ pristine: true, submitting: true }}
          form={this.form}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} css={selectStyles.root}>
              <div>
                <Field
                  name="xtmCustomer"
                  initialValue={defaultCustomer}
                  validate={requiredAutocomplete}
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label={"addcontent.xtmCustomer"}
                      options={this.parseCustomers(xtmCustomer)}
                      multiple={false}
                      inputProps={
                        input as unknown as FieldInputProps<
                          { [key: string]: string } | null | undefined
                        >
                      }
                      onChange={this.handleCustomerChange}
                      error={
                        xtmCustomer.defaultCustomerId === null
                          ? "configForm.warnings.noCustomer"
                          : undefined
                      }
                      meta={meta}
                      testId="xtmCustomerInput"
                      spinner={xtmCustomerSpinner}
                      fullscreen={fullscreen}
                    />
                  )}
                </Field>
                <Field
                  name="xtmTemplate"
                  initialValue={defaultTemplate}
                  validate={requiredAutocomplete}
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label={"addcontent.xtmTemplate"}
                      options={this.parseTemplates(xtmTemplate)}
                      multiple={false}
                      inputProps={
                        input as unknown as FieldInputProps<
                          { [key: string]: string } | null | undefined
                        >
                      }
                      meta={meta}
                      testId="xtmTemplateInput"
                      spinner={xtmTemplateSpinner}
                      fullscreen={fullscreen}
                    />
                  )}
                </Field>
                <TitleTypography variant="body1" color="textSecondary">
                  {t("addcontent.translationSettings")}
                </TitleTypography>
                <Field
                  name="sourceLanguage"
                  validate={requiredAutocomplete}
                  initialValue={defaultSourceLanguage}
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label={"addcontent.sourceLanguage"}
                      options={this.parseLanguages(sourceLanguages)}
                      multiple={false}
                      inputProps={input}
                      onChange={this.handleSourceLanguageChange}
                      meta={meta}
                      testId="sourceLanguageInput"
                      fullscreen={fullscreen}
                    />
                  )}
                </Field>
                <Field name="targetLanguages" validate={requiredAutocomplete}>
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label={"addcontent.targetLanguages"}
                      options={this.parseLanguages(targetLanguages)}
                      multiple
                      inputProps={input}
                      meta={meta}
                      onChange={this.handleTargetLanguagesChange}
                      testId="targetLanguagesInput"
                      fullscreen={fullscreen}
                    />
                  )}
                </Field>
                <Field name={"projectName"} validate={projectNameValidator}>
                  {({ input, meta }): JSX.Element => (
                    <CustomField
                      label={"addcontent.projectName.label"}
                      inputProps={{
                        maxLength: this.state.prefix
                          ? 100 - this.state.prefix.length
                          : 100,
                        ...input,
                        onFocus: () => onProjectNameFocus(),
                        onBlur: () => onProjectNameBlur(input.value),
                      }}
                      placeholder={"addcontent.projectName.placeholder"}
                      showCharacterCount
                      tooltipInfo={"addcontent.projectName.tooltip"}
                      error={meta.error}
                      touched={meta.touched}
                      adornment={this.state.prefix}
                    />
                  )}
                </Field>
                <Field name={"projectDescription"}>
                  {({ input }): JSX.Element => (
                    <CustomField
                      label={"addcontent.description.label"}
                      inputProps={{ maxLength: 2000, ...input }}
                      placeholder={"addcontent.description.placeholder"}
                      showCharacterCount
                      multiline={true}
                      rows={3}
                      tooltipInfo={"addcontent.description.tooltip"}
                    />
                  )}
                </Field>
                <TitleTypography variant="body1" color="textSecondary">
                  {t("addcontent.continuousProject")}
                </TitleTypography>
                <Field name="activeProject">
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label={"addcontent.activeXtmProjects"}
                      options={this.parseProjects(activeProjects)}
                      multiple={false}
                      inputProps={input}
                      onChange={this.handleActiveProjectChange}
                      //onFocus={this.handleActiveProjectsOnFocus} causes an error with the inability to call onblur will be fix with solution based on xtm callbacks
                      meta={meta}
                      testId="activeXtmProjectsInput"
                      spinner={activeProjectsSpinner}
                      fullscreen={fullscreen}
                    />
                  )}
                </Field>
                <Field name={"dueDate"}>
                  {({ input }): JSX.Element => (
                    <CustomDatepicker
                      label={"addcontent.dueDate"}
                      inputProps={input}
                      onChange={this.handleDueDate}
                      value={dueDate}
                      disabled={datePickerDisabled}
                      handleClear={this.handleDueDateClear}
                      datePickerButtonDisabled={datePickerButtonDisabled}
                      clearButtonDisabled={clearDatePickerButtonDisabled}
                      displayClearButton={displayDatePickerClearButton}
                    />
                  )}
                </Field>
                {allowUserChange ? (
                  <Field
                    name="entryGrouping"
                    defaultValue={this.mapEntryGroupingForDefault(
                      entryGrouping
                    )}
                  >
                    {({ input, meta }): JSX.Element =>
                      fullscreen ? (
                        <CustomSelect
                          multiple={false}
                          label={"addcontent.entryGrouping.dropdown"}
                          options={this.parseEntryGrouping}
                          inputProps={input}
                          onChange={this.handleEntryGroupingChange}
                          meta={meta}
                          testId="targetLanguagesInput"
                          fullscreen={fullscreen}
                          disabled={entryGroupingDisabled}
                          disableClearable={clearEntryGroupingDisabled}
                        />
                      ) : (
                        <CustomCheckbox
                          label={"addcontent.entryGrouping.checkbox"}
                          inputProps={{
                            ...input,
                            onChange: this.handleMergeFilesCheckbox,
                            disabled: entryGroupingDisabled,
                            checked: entryGroupingChecked,
                          }}
                        />
                      )
                    }
                  </Field>
                ) : null}
              </div>
              <ButtonWrapper>
                <Tooltip
                  title={
                    this.state.entryLevelLocalization
                      ? (t("addcontent.manageReferencesTooltip") as string)
                      : ""
                  }
                  arrow
                  placement="bottom"
                >
                  <ManageReferenceButtonWrapper fullscreen={fullscreen}>
                    <Button
                      size="small"
                      variant="outlined"
                      data-testid="manageReferencesButton"
                      css={selectStyles.button}
                      onClick={this.handleOpenReferencesModal}
                      disabled={
                        queueSpinner ||
                        translateSpinner ||
                        isNotEntriesSelected ||
                        this.state.entryLevelLocalization ||
                        this.state.getManageReferencesSpinner
                      }
                    >
                      {this.state.getManageReferencesSpinner ? (
                        <CircularProgress size={fullscreen ? 19 : 20} />
                      ) : (
                        <React.Fragment>
                          <DiagramProjectIcon
                            entryLevelLocalization={
                              queueSpinner ||
                              translateSpinner ||
                              isNotEntriesSelected ||
                              this.state.entryLevelLocalization
                            }
                          />
                          <span>{t("addcontent.manageReferences")}</span>
                        </React.Fragment>
                      )}
                    </Button>
                  </ManageReferenceButtonWrapper>
                </Tooltip>
                <ButtonGroup>
                  <Button
                    size="small"
                    variant="outlined"
                    data-testid="addToQueue"
                    css={selectStyles.button}
                    onClick={this.addToQueue}
                    disabled={
                      queueSpinner || translateSpinner || isNotEntriesSelected
                    }
                  >
                    {queueSpinner ? (
                      <CircularProgress size={32} color="secondary" />
                    ) : (
                      t("addcontent.addToQueue")
                    )}
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    data-testid="sendForTranslation"
                    css={selectStyles.button}
                    color="secondary"
                    disabled={
                      queueSpinner || translateSpinner || isNotEntriesSelected
                    }
                  >
                    {translateSpinner ? (
                      <CircularProgress size={32} color="secondary" />
                    ) : (
                      t("addcontent.sendForTranslation")
                    )}
                  </Button>
                </ButtonGroup>
              </ButtonWrapper>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withTranslation()(FormContainer);
