import { createReducer } from '@reduxjs/toolkit';
import { setAuxSpinner, setSpinner } from './spinner.actions';
import { ISpinnerState } from './spinner.interface';

const initState: ISpinnerState = {
  spinner: false,
  auxSpinner: false,
};

export const spinnerReducer = createReducer(initState, (builder) =>
  builder
    .addCase(setSpinner, (state, { payload }) => {
      state.spinner = payload;
    })
    .addCase(setAuxSpinner, (state, { payload }) => {
      state.auxSpinner = payload;
    })
);
