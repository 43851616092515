import React from "react";
import TreeNode from "./TreeNode";
import { TreeWrapper } from "../../CustomVariants.styled";
import { ReferenceDTO } from "utils/restApplicationClient";

interface TreeProps {
  data: ReferenceDTO[];
  selectedNodes: string[] | undefined;
  setSelectedNodes: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const Tree: React.FC<TreeProps> = ({
  data,
  selectedNodes,
  setSelectedNodes,
}) => {
  return (
    <TreeWrapper>
      {data.map((node) => (
        <TreeNode
          key={node.id}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          {...node}
        />
      ))}
    </TreeWrapper>
  );
};

export default Tree;
