import { css } from '@emotion/core';

export const customFieldStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    margin: 4px auto;
    width: 100%;
  `,
  label: css`
    font-weight: bold;
    color: #696969;
    text-align: start;
  `,
  outlineError: css`
    &:hover {
      fieldset {
        border-color: red !important;
        border-width: 3px;
      }
    }

    fieldset {
      border-color: red !important;
      border-width: 2px;
    }
  `,
  textError: css`
      color: red;
      text-align: start;
      font-style: oblique;
      max-width: 400px;
  `,
  checkbox: css`
    display: flex;
  `,

  select: css`
    margin-top: 8px;
    margin-bottom: 4px;
  `,
};
