import { createAction } from "@reduxjs/toolkit";
import { FilterOptionsEnum } from "enums/filterOptions";
import { InitConfigPayload } from "store/config/config.interface";
import { EntriesDTO, EntryStatus } from "utils/restApplicationClient";
import { EntryRequestDTO } from "utils/restApplicationClientTypeOverrides";
import { EntriesActionTypes } from "./entries.actions.types";
import {
  AutocompleteField,
  EntriesPagination,
  EntriesSort,
  IEntriesState,
} from "./entries.interface";

export const prepareEntriesPayload = createAction<Partial<EntryRequestDTO>>(
  EntriesActionTypes.PrepareEntriesPayload
);

export const setParsedEntries = createAction<Array<any>>(
  EntriesActionTypes.SetParsedEntries
);

export const getEntries = createAction<EntryRequestDTO>(
  EntriesActionTypes.GetEntries
);

export const getEntriesSuccess = createAction<EntriesDTO>(
  EntriesActionTypes.GetEntriesSuccess
);

export const getEntriesError = createAction<any>(
  EntriesActionTypes.GetEntriesError
);

export const getContentTypes = createAction<InitConfigPayload | undefined>(
  EntriesActionTypes.GetContentTypes
);

export const getContentTypesSuccess = createAction<
  IEntriesState["contentTypes"]
>(EntriesActionTypes.GetContentTypesSuccess);

export const getContentTypesError = createAction<any>(
  EntriesActionTypes.GetContentTypesError
);

export const setEntriesPaginationStart = createAction<
  Partial<EntriesPagination>
>(EntriesActionTypes.SetPaginationStart);

export const setEntriesPaginationEnd = createAction<EntriesPagination>(
  EntriesActionTypes.SetPaginationEnd
);

export const setSortStart = createAction<{ property: string }>(
  EntriesActionTypes.SetSortStart
);

export const setSortEnd = createAction<EntriesSort>(
  EntriesActionTypes.SetSortEnd
);

export const setEntriesFilters = createAction<{
  status?: EntryStatus;
  contentType: string;
}>(EntriesActionTypes.SetFilters);

export const setSearchStart = createAction<{ search: string }>(
  EntriesActionTypes.SetSearchSTART
);

export const setSearchEnd = createAction<{ search: string }>(
  EntriesActionTypes.SetSearchEND
);

export const getTags = createAction<InitConfigPayload | undefined>(
  EntriesActionTypes.GetTags
);

export const getTagsSuccess = createAction<{ tags: IEntriesState["tags"] }>(
  EntriesActionTypes.GetTagsSuccess
);

export const getTagsError = createAction<any>(EntriesActionTypes.GetTagsError);

export const getEntriesData = createAction<Partial<EntryRequestDTO>>(
  EntriesActionTypes.GetEntriesData
);

export const setTagsFilter = createAction<{
  tagFilter: FilterOptionsEnum;
  index: number;
}>(EntriesActionTypes.SetTagFilter);

export const setSelectedTags = createAction<{
  selectedTags: Array<AutocompleteField>;
  index: number;
}>(EntriesActionTypes.SetSelectedTags);

export const getUsers = createAction<InitConfigPayload | undefined>(
  EntriesActionTypes.GetUsers
);

export const getUsersSuccess = createAction<{ users: IEntriesState["users"] }>(
  EntriesActionTypes.GetUsersSuccess
);

export const getUsersError = createAction<any>(
  EntriesActionTypes.GetUsersError
);

export const createTagsPill = createAction(EntriesActionTypes.CreateTagsPill);

export const removeTagsPill = createAction<{ index: number }>(
  EntriesActionTypes.RemoveTagsPill
);

export const setTagsIndex = createAction<{ index: number }>(
  EntriesActionTypes.SetTagsIndex
);

// export const setTranslate = createAction(EntriesActionTypes.GetTranslate);

export const setTranslateError = createAction(
  EntriesActionTypes.GetTranslateError
);
