/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button } from '@material-ui/core';
import { ButtonContainer } from 'components/Dialog/Dialog.styled';
import { DialogExtensionSDK } from 'contentful-ui-extensions-sdk';
import { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { XTMProjectEntriesVerifyDTO } from 'utils/restApplicationClient';
import { DialogContent } from './CustomVariants.styled';

interface IProps {
  sdk: DialogExtensionSDK;
  data: Array<XTMProjectEntriesVerifyDTO> | string;
}

type TProps = IProps & WithTranslation;

export const SingleEntryUpdateDialog = ({ sdk, data, t }: TProps) => (
  <Fragment>
    <DialogContent data-testid="singleUpdateDialog">
      {(data === 'errormessage.job.content' && <p>{t(data)}</p>) || (
        <Fragment>
          <p>{t('addcontent.verificationPopup.firstLine')}</p>
          <p>{t('addcontent.verificationPopup.secondLine')}</p>
        </Fragment>
      )}
    </DialogContent>
    <ButtonContainer>
      <Button
        size="small"
        variant="outlined"
        onClick={() =>
          sdk.close(data === 'errormessage.job.content' ? null : undefined)
        }
      >
        {(data === 'errormessage.job.content' && t('common.ignore')) ||
          t('common.cancel')}
      </Button>
      <Button size="small" variant="outlined" onClick={() => sdk.close(data)}>
        {t('addcontent.verificationPopup.buttons.update')}
      </Button>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        onClick={() => sdk.close([])}
      >
        {t('addcontent.verificationPopup.buttons.new')}
      </Button>
    </ButtonContainer>
  </Fragment>
);

export default withTranslation()(SingleEntryUpdateDialog);
