import { Tooltip, Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { getLanguageName } from 'utils/languageUtils';

interface IProps {
  languages: Array<string>;
  length: number;
}

class TargetLanguages extends PureComponent<IProps> {
  render() {
    const { languages, length } = this.props;
    const languageNames = languages.map(getLanguageName);

    return (
        <Typography variant="caption" component="div">
          {languageNames.length > length ? (
              <Tooltip
                  title={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {languageNames.map((language, index) => (
                          <p key={index}>{language}</p>
                      ))}
                    </div>
                  }
                  data-testid="targetLanguagesTooltip"
              >
                <div>{languageNames.slice(0, 1).map((language) => language)}, ...</div>
              </Tooltip>
          ) : (
              <div>{languageNames.map((language) => language)}</div>
          )}
        </Typography>
    );
  }
}

export default TargetLanguages;
