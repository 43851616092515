import { Box, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Dispatch } from "@reduxjs/toolkit";
import { EntryStatusEnum } from "enums/entryStatus";
import { FilterOptionsEnum } from "enums/filterOptions";
import React, { ChangeEvent, Component, Fragment } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "store";
import {
  setEntriesFilters,
  setSearchStart,
} from "store/entries/entries.actions";
import { EntriesFilters, IEntriesState } from "store/entries/entries.interface";
import {
  getEntriesContentTypesSelector,
  getEntriesFiltersSelector,
  getEntriesSearchSelector,
  getEntriesTagFilterSelector,
} from "store/entries/entries.selectors";
import { getAuxSpinnerSelector } from "store/spinner/spinner.selectors";
import { EntryStatus } from "utils/restApplicationClient";
import { EntriesTableTextField } from "./EntriesTable.styled";
import EntriesTablePill from "./EntriesTablePill";
import EntriesTableTagPill from "./EntriesTableTagPill";

const statusOptions = [
  EntryStatusEnum.ANY,

  EntryStatusEnum.PUBLISHED,

  EntryStatusEnum.CHANGED,

  EntryStatusEnum.DRAFT,

  EntryStatusEnum.ARCHIVED,
];

const tagFilterOptions = [
  { value: FilterOptionsEnum.NO_FILTER, hideOption: true },
  { value: FilterOptionsEnum.ANY, hideOption: true },
  { value: FilterOptionsEnum.NOT_TAGGED, hideOption: true },
  { value: FilterOptionsEnum.INCLUDES },
  { value: FilterOptionsEnum.NO_INCLUDES },
  { value: FilterOptionsEnum.INCLUDE_ALL },
];

interface IStateProps {
  contentTypes: IEntriesState["contentTypes"];
  tagFilters: IEntriesState["tagFilter"];
  filters: EntriesFilters;
  search: string;
  spinner: boolean;
}

interface IDispatchProps {
  setFilters: (payload: EntriesFilters) => AppDispatch;
  setSearch: (payload: { search: string }) => AppDispatch;
}

type PropType = WithTranslation & IStateProps & IDispatchProps;

class EntriesTableSearch extends Component<PropType> {
  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { setSearch } = this.props;

    setSearch({ search: event.target.value });
  };

  onStatusChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const { setFilters, filters } = this.props;

    setFilters({ ...filters, type: event.target.value as EntryStatus });
  };

  onTypeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const { setFilters, filters } = this.props;

    setFilters({ ...filters, contentType: event.target.value as string });
  };

  mapContentTypes = (): Array<{ value?: string; label: string }> => {
    const contentTypes = [{ label: "Any", value: "any" }];
    return contentTypes.concat(
      Object.keys(this.props.contentTypes).map((key) => ({
        label: this.props.contentTypes[key].name,
        value: key,
      }))
    );
  };

  mapStatuses = (): Array<{ value?: string; label: string }> => {
    return statusOptions.map((status) => ({
      label: this.props.t(`addcontent.statuses.${status.toLowerCase()}`),
      value: status,
    }));
  };

  render(): JSX.Element {
    const {
      filters: { contentType, type },
      search,
      tagFilters,
      t,
    } = this.props;

    return (
      <Fragment>
        <Box
          display="flex"
          flexWrap="wrap"
          border="1px solid rgb(224, 224, 224)"
        >
          <EntriesTablePill
            title={t("addcontent.type")}
            options={this.mapContentTypes()}
            onChange={this.onTypeChange}
            value={contentType}
          />
          <EntriesTablePill
            title={t("addcontent.status")}
            options={this.mapStatuses()}
            onChange={this.onStatusChange}
            value={type || "any"}
          />
          {tagFilters.map((_, index) => (
            <EntriesTableTagPill
              key={index}
              title={t("addcontent.tags")}
              filterOptions={tagFilterOptions}
              index={index}
            />
          ))}
          <EntriesTableTextField
            color="primary"
            variant="outlined"
            margin="dense"
            placeholder={t("common.search")}
            size="small"
            value={search}
            InputProps={{
              endAdornment: this.props.spinner ? (
                <CircularProgress size={16} />
              ) : (
                <SearchIcon color="disabled" />
              ),
            }}
            onChange={this.onSearchChange}
          />
        </Box>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  filters: getEntriesFiltersSelector(state),
  search: getEntriesSearchSelector(state),
  contentTypes: getEntriesContentTypesSelector(state),
  spinner: getAuxSpinnerSelector(state),
  tagFilters: getEntriesTagFilterSelector(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>
): IDispatchProps => ({
  setFilters: (payload: EntriesFilters) => dispatch(setEntriesFilters(payload)),
  setSearch: (payload: { search: string }) => dispatch(setSearchStart(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EntriesTableSearch));
