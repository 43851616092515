import styled from '@emotion/styled';
import { CircularProgress, InputBaseComponentProps, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { ChangeEvent, Fragment, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)`
  background-color: white;
  > div input {
    font-size: 14px;
    padding: 8px !important;
  }
`;

interface IProps extends WithTranslation {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  spinner?: boolean;
}

class SearchInput extends PureComponent<IProps> {
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <StyledTextField
          color="primary"
          variant="outlined"
          margin="dense"
          fullWidth
          placeholder={t('common.search')}
          size="small"
          inputProps={
            {
              'data-testid': 'searchInput',
            } as InputBaseComponentProps
          }
          InputProps={{
            endAdornment: this.props.spinner ? <CircularProgress size={16} /> : <SearchIcon color="disabled" />,
          }}
          onChange={this.props.onChange}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(SearchInput);
