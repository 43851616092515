export const EntriesActionTypes = {
  PrepareEntriesPayload: "Entries: Prepare entries payload",
  SetParsedEntries: "Entries: Set parsed entries",
  GetEntries: "Entries: Get entries",
  GetEntriesSuccess: "Entries: Get entries SUCCESS",
  GetEntriesError: "Entries: Get entries ERROR",
  GetContentTypes: "Entries: Get content types",
  GetContentTypesSuccess: "Entries: Get content types SUCCESS",
  GetContentTypesError: "Entries: Get content types ERROR",
  SetPaginationStart: "Entries: Set pagination START",
  SetPaginationEnd: "Entries: Set pagination END",
  SetSortStart: "Entries: Set sort START",
  SetSortEnd: "Entries: Set sort END",
  SetFilters: "Entires: Set filters",
  SetSearchSTART: "Entries: Set search START",
  SetSearchEND: "Entries: Set search END",
  GetTags: "Entries: Get Tags",
  GetTagsSuccess: "Entries: Get Tags SUCESS",
  GetTagsError: "Entries: Get Tags ERROR",
  GetEntriesData: "Entires: Get entries data",
  SetTagFilter: "Entries: Set tag filter",
  SetSelectedTags: "Entries: Set selected tags",
  GetUsers: "Entries: Get users",
  GetUsersSuccess: "Entries: Get users SUCCESS",
  GetUsersError: "Entries: Get users ERROR",
  SetTagsIndex: "Entries: Set tags index",
  CreateTagsPill: "Entries: Create tags pill",
  RemoveTagsPill: "Entries: Remove tags pill",
  // GetTranslate: "Entries: Get Translate",
  // GetTranslateSuccess: "Entries: Get Translate Success",
  GetTranslateError: "Entries: Get Translate Error",
};
