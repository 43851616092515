import { Tooltip } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { NameDiv } from './Name.styled';

interface IProps {
  text: string;
  width: number;
}

interface IState {
  isOverflown: boolean;
}

type PropType = IProps;

class Name extends Component<PropType, IState> {
  state = {
    isOverflown: false,
  };

  componentDidMount() {
    this.checkIfOverflown();
  }

  componentDidUpdate() {
    this.checkIfOverflown();
  }

  checkIfOverflown() {
    const element = this.element;
    if (element) {
      const isOverflown =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;
      if (this.state.isOverflown !== isOverflown) {
        this.setState({ isOverflown });
      }
    }
  }

  element: HTMLDivElement | null = null;

  render() {
    const { isOverflown } = this.state;
    const { text, width } = this.props;

    const NameDivWidth = NameDiv(`${width}px`);
    return (
      <Fragment>
        {isOverflown ? (
          <Tooltip title={<Fragment>{text}</Fragment>}>
            <NameDivWidth
              ref={(element) => {
                this.element = element;
              }}
            >
              {text}
            </NameDivWidth>
          </Tooltip>
        ) : (
          <NameDivWidth
            ref={(element) => {
              this.element = element;
            }}
          >
            {text}
          </NameDivWidth>
        )}
      </Fragment>
    );
  }
}

export default Name;
