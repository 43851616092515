import { ContentType, User } from 'contentful-ui-extensions-sdk';
import i18next from 'i18next';
import get from 'lodash.get';
import isObject from 'lodash.isobject';
import isString from 'lodash.isstring';
import { EntryDTO } from './restApplicationClient';

function titleOrDefault(title: string | undefined, defaultTitle: string): string {
  if (!isString(title)) {
    return defaultTitle;
  }
  if (title) {
    const trimmedTitle = title.trim();
    if (trimmedTitle.length === 0) {
      return defaultTitle;
    }
    return trimmedTitle;
  }
  return defaultTitle;
}

function getFieldValue({
  /**
   * Expects an entity fetched with a flag Skip-Transformation: true
   */
  entity,
  fieldId,
  defaultLocaleCode,
}: {
  entity: {
    fields: { [key: string]: { [valueKey: string]: string | undefined } };
  };
  fieldId: string;
  defaultLocaleCode: string;
}): string | undefined {
  const values = get(entity, ['fields', fieldId]);
  if (!isObject(values)) {
    return;
  }

  const firstLocaleCode = Object.keys(values)[0];

  return values[defaultLocaleCode] || values[firstLocaleCode];
}

export function getEntryTitle({
  entry,
  contentType,
  defaultLocaleCode,
  defaultTitle,
}: {
  entry: EntryDTO;
  contentType?: ContentType;
  defaultLocaleCode: string;
  defaultTitle: string;
}) {
  let title;

  if (!contentType) {
    return defaultTitle;
  }

  const displayField = contentType.displayField;
  if (!displayField) {
    return defaultTitle;
  }

  const displayFieldInfo = contentType.fields.find((field) => field.id === displayField);

  if (!displayFieldInfo) {
    return defaultTitle;
  }

  // when localization for a field is "turned off",
  // we don't clean up the "old" localized data, so it is still in the response.
  // Therefore, we're checking if displayField is localizable.
  if (displayFieldInfo.localized) {
    title = getFieldValue({
      entity: entry,
      fieldId: displayField,
      defaultLocaleCode,
    });
    if (!title) {
      // Older content types may return id/apiName, but some entry lookup paths do not fetch raw data
      // In order to still return a title in this case, look for displayField as apiName in content type,
      // ...but still look for displayField as a field in the entry
      title = getFieldValue({
        entity: entry,
        fieldId: displayFieldInfo.id,
        defaultLocaleCode,
      });
    }
  } else {
    title = getFieldValue({
      entity: entry,
      fieldId: displayField,
      defaultLocaleCode,
    });
    if (!title) {
      title = getFieldValue({
        entity: entry,
        fieldId: displayFieldInfo.id,
        defaultLocaleCode,
      });
    }
  }

  return titleOrDefault(title, defaultTitle);
}

export const getUserName = (users: Record<string, User>, id: string) => {
  const user = users[id];

  return (user && `${user.firstName} ${user.lastName}`) || i18next.t('addcontent.unknown');
};
