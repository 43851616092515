import {
  CircularProgress,
  InputBaseComponentProps,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import { Pagination } from "@material-ui/lab";
import { SidebarExtensionSDK } from "contentful-ui-extensions-sdk";
import { OrderEnum } from "enums/orderEnum";
import React, { ChangeEvent, Component, Fragment } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { apiErrorHandler } from "utils/apiErrorHandler";
import { api } from "utils/axiosInstance";
import { debounce } from "utils/debounce";
import { Page, XTMJobDTO } from "utils/restApplicationClient";
import SearchInput from "../SearchInput/SearchInput";
import {
  SelectRowsDiv,
  TableSpinnerContainer,
} from "./TranslationQueue.styled";
import Row from "./TranslationTable/Row";
import {
  HeaderCell,
  PaginationDiv,
  RefreshButton,
} from "./TranslationTable/TranslationTable.styles";

interface IProps {
  sdk: SidebarExtensionSDK;
  fullscreen?: boolean;
  native?: boolean;
}

interface IState {
  jobs?: Page<XTMJobDTO>;
  page: number;
  count: number;
  rowsPerPage: number;
  jobsSpinner: boolean;
  searchSpinner: boolean;
  debounceFn?: () => void;
  search: string;
  order: OrderEnum;
  orderBy: string;
}

type PropType = IProps & WithTranslation;

export class TranslationQueue extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 5,
      jobs: undefined,
      count: 0,
      jobsSpinner: false,
      searchSpinner: false,
      search: "",
      order: OrderEnum.ASC,
      orderBy: "",
    };
  }

  onSysChangedListener?: Function;
  ignoreFirstEvent = true;

  componentDidMount(): void {
    const { sdk, fullscreen } = this.props;
    const { space, environment } = sdk.ids;
    this.setJobsPaginated();

    if (!fullscreen) {
      const { id } = sdk.entry.getSys();
      this.onSysChangedListener = sdk.entry.onSysChanged(() => {
        if (this.ignoreFirstEvent) {
          this.ignoreFirstEvent = false;
        } else {
          api
            .compareContent(space, environment, id)
            .then(({ data: check }) => {
              if (check) this.setJobsPaginated(true);
            })
            .catch((error) => apiErrorHandler(error, sdk));
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.onSysChangedListener) {
      this.onSysChangedListener();
    }
  }

  setJobsPaginated = (forceRefresh?: boolean): void => {
    const { sdk } = this.props;
    const { page, rowsPerPage, search, orderBy, order } = this.state;
    if (sdk.ids.environment && sdk.ids.space) {
      this.setState({ jobsSpinner: true });
      api
        .findAllPaged({
          forceRefresh,
          page: page - 1,
          size: rowsPerPage,
          environmentId: sdk.ids.environment,
          spaceId: sdk.ids.space,
          projectName: encodeURI(search),
          sort: `${orderBy},${order}`,
        })
        .then(({ data }) => {
          this.setState({
            jobs: data,
            count: Math.ceil(data.totalElements / rowsPerPage),
          });
        })
        .catch((error) => apiErrorHandler(error, sdk))
        .finally(() => {
          this.setState({ jobsSpinner: false, searchSpinner: false });
        });
    }
  };

  onRefresh = (): void => {
    this.setJobsPaginated(true);
  };

  onChangePage = (_: ChangeEvent<unknown>, page: number): void => {
    this.setState({ page }, () => {
      this.setJobsPaginated();
    });
  };

  onChangeRowsPerPage = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    this.setState({ rowsPerPage: Number(event.target.value), page: 1 }, () => {
      this.setJobsPaginated();
    });
  };

  onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { debounceFn } = this.state;
    this.setState({ searchSpinner: true });
    event.persist();
    if (!debounceFn) {
      this.setState(
        {
          debounceFn: debounce(() => {
            this.setState({ search: event.target.value }, () => {
              this.setJobsPaginated();
            });
          }, 500),
        },
        () => {
          if (this.state.debounceFn) {
            this.state.debounceFn();
          }
        }
      );
    } else {
      debounceFn();
    }
  };

  onChangeOrder = (property: string): (() => void) => {
    return (): void => {
      const { orderBy, order } = this.state;
      const isAsc = orderBy === property && order === OrderEnum.ASC;
      this.setState(
        {
          order: isAsc ? OrderEnum.DESC : OrderEnum.ASC,
          orderBy: property,
        },
        () => {
          this.setJobsPaginated();
        }
      );
    };
  };

  setTableSpinner = (spinner: boolean) => {
    this.setState({ jobsSpinner: spinner });
  };

  renderRows(): JSX.Element | Array<JSX.Element> {
    const { sdk, t, fullscreen } = this.props;
    const { jobsSpinner, jobs } = this.state;
    if (jobsSpinner) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <TableSpinnerContainer>
              <CircularProgress size={40} color="secondary" />
            </TableSpinnerContainer>
          </TableCell>
        </TableRow>
      );
    }
    if (!jobs || (jobs && jobs.totalElements === 0)) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <TableSpinnerContainer>
              <SpeakerNotesOffIcon fontSize="large" color="disabled" />
              <Typography color="textSecondary" variant="caption">
                {t("queue.tableEmpty")}
              </Typography>
            </TableSpinnerContainer>
          </TableCell>
        </TableRow>
      );
    }
    return jobs.content.map((job, key) => (
      <Row
        row={job}
        key={key}
        sdk={sdk}
        setJobs={this.setJobsPaginated}
        fullscreen={fullscreen}
        setSpinner={this.setTableSpinner}
      />
    ));
  }

  renderSort(): JSX.Element {
    const { t } = this.props;
    const { order, orderBy } = this.state;
    return (
      <TableRow>
        <HeaderCell align="left" width={32}></HeaderCell>
        <HeaderCell
          align="left"
          sortDirection={orderBy === "projectName" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "project.projectName"}
            onClick={this.onChangeOrder("project.projectName")}
            direction={
              orderBy === "project.projectName" ? order : OrderEnum.ASC
            }
            data-testid="projectNameLabel"
          >
            {t("queue.name")}
          </TableSortLabel>
        </HeaderCell>
        <HeaderCell
          align="center"
          width={124}
          sortDirection={orderBy === "status" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "status"}
            onClick={this.onChangeOrder("status")}
            direction={orderBy === "status" ? order : OrderEnum.ASC}
            data-testid="statusLabel"
          >
            {t("queue.status")}
          </TableSortLabel>
        </HeaderCell>
      </TableRow>
    );
  }

  render(): JSX.Element {
    const { page, rowsPerPage, count, searchSpinner } = this.state;
    const { t, native } = this.props;
    return (
      <Fragment>
        <RefreshButton
          onClick={this.onRefresh}
          disableFocusRipple
          disableRipple
          data-testid="refreshButton"
          startIcon={<AutorenewIcon color="secondary" />}
        >
          <Typography color="textPrimary" variant="body2">
            {t("queue.refresh")}
          </Typography>
        </RefreshButton>
        <SearchInput onChange={this.onSearchChange} spinner={searchSpinner} />
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>{this.renderSort()}</TableHead>
          <TableBody>{this.renderRows()}</TableBody>
        </Table>
        <PaginationDiv>
          <Pagination
            size="small"
            count={count}
            color="secondary"
            page={page}
            onChange={this.onChangePage}
          />
        </PaginationDiv>
        <SelectRowsDiv>
          <Typography variant="body2" color="textSecondary">
            {t("queue.rowsPerPage")}
          </Typography>
          <Select
            variant="outlined"
            value={rowsPerPage}
            onChange={this.onChangeRowsPerPage}
            inputProps={
              {
                "data-testid": "rowsPerPageSelect",
              } as InputBaseComponentProps
            }
            native={native}
          >
            {native && [
              <option value={5} key="5">
                5
              </option>,
              <option value={10} key="10">
                10
              </option>,
              <option value={15} key="15">
                15
              </option>,
              <option value={20} key="20">
                20
              </option>,
            ]}
            {!native && [
              <MenuItem value={5} key="5">
                5
              </MenuItem>,
              <MenuItem value={10} key="10">
                10
              </MenuItem>,
              <MenuItem value={15} key="15">
                15
              </MenuItem>,
              <MenuItem value={20} key="20">
                20
              </MenuItem>,
            ]}
          </Select>
        </SelectRowsDiv>
      </Fragment>
    );
  }
}

export default withTranslation()(TranslationQueue);
