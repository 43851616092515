/** @jsx jsx */
// eslint-disable  @typescript-eslint/no-explicit-any

import { jsx } from '@emotion/core';
import { Checkbox } from '@material-ui/core';
import { InputHTMLAttributes, PureComponent } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CheckboxDiv, customInputStyles, FieldDiv } from './CustomInput.style';

interface IProps {
  label: string;
  inputProps: FieldInputProps<unknown, HTMLElement>;
  testId?: string;
}

export class CustomCheckbox extends PureComponent<IProps & WithTranslation> {
  render(): JSX.Element {
    const { t, inputProps, label, testId,  } = this.props;
    return (
      <FieldDiv>
        <span css={customInputStyles.label}>{t(label)}</span>
        <CheckboxDiv>
          <Checkbox
            {...inputProps}
            inputProps={
              { 'data-testid': testId } as InputHTMLAttributes<HTMLInputElement>
            }
          />
        </CheckboxDiv>
      </FieldDiv>
    );
  }
}

export default withTranslation()(CustomCheckbox);
