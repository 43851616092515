import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import subscribeActionMiddleware from 'redux-subscribe-action';
import { api } from 'utils/axiosInstance';
import { authEpics } from './auth/auth.epics';
import { IConfigState } from './config/config.interface';
import { configReducer } from './config/config.reducer';
import { entriesEpics } from './entries/entries.epics';
import { IEntriesState } from './entries/entries.interface';
import { entriesReducer } from './entries/entries.reducer';
import { notificationEpics } from './notification/notification.epics';
import { spinnerEpics } from './spinner/spinner.epics';
import { ISpinnerState } from './spinner/spinner.interface';
import { spinnerReducer } from './spinner/spinner.reducer';

export interface IStoreState {
  entries: IEntriesState;
  config: IConfigState;
  spinner: ISpinnerState;
}

const appReducer = combineReducers({
  entries: entriesReducer,
  config: configReducer,
  spinner: spinnerReducer,
});

const epicMiddleware = createEpicMiddleware({
  dependencies: { api },
});

// TODO: modify appReducer to wrap clearing the store on logout
const store = configureStore({
  reducer: appReducer,
  middleware: [epicMiddleware, subscribeActionMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

epicMiddleware.run(
  combineEpics(entriesEpics, spinnerEpics, notificationEpics, authEpics)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module.hot.accept('./', () => store.replaceReducer(appReducer));
}

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;
export default store;
