import styled from '@emotion/styled';
import { LinearProgress, Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';

const CustomProgress = styled(LinearProgress)`
  height: 20px !important;
  background-color: transparent !important;
  .MuiLinearProgress-barColorSecondary {
    background-color: #ccedfa !important;
  }
`;

const ProgressSpan = styled.span`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    font-size: 10px !important;
  }
`;

const ProgressDiv = styled.div`
  position: relative;
`;

class ProgressBar extends PureComponent<{ value: number }> {
  render() {
    return (
      <ProgressDiv>
        <CustomProgress
          color="secondary"
          variant="determinate"
          value={this.props.value}
        />
        <ProgressSpan>
          <Typography color="textSecondary">
            Progress: {this.props.value.toFixed(0)}%
          </Typography>
        </ProgressSpan>
      </ProgressDiv>
    );
  }
}

export default ProgressBar;
