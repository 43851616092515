import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import { CustomTab, CustomTabPanel } from 'components/Sidebar/Sidebar.styled';
import React, { FC, Fragment, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

type TabInfo = {
  labelKey: string;
  component: JSX.Element;
};

interface IProps extends WithTranslation {
  tabs: Array<TabInfo>;
}

const TabsPanel: FC<IProps> = ({ t, tabs }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Fragment>
      <Tabs value={selectedTab} textColor="secondary" color={'secondary'} onChange={handleChange} aria-label="tab">
        {tabs.map(({ labelKey }, index) => (
          <CustomTab label={t(labelKey)} key={`Tab-${index}`} />
        ))}
      </Tabs>
      <Divider />
      {tabs.map(({ component }, index) => (
        <CustomTabPanel value={selectedTab} index={index} key={`TabPanel-${index}`}>
          {component}
        </CustomTabPanel>
      ))}
    </Fragment>
  );
};

export default withTranslation()(TabsPanel);
