import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IConfigState } from './config.interface';

export const configStateSelector = ({ config }: AppState): IConfigState => config;

export const getConfigSelector = createSelector(configStateSelector, ({ environmentId, spaceId, defaultLanguage }) => ({
  environmentId,
  spaceId,
  defaultLanguage,
}));

export const getDialogOpenSelector = createSelector(configStateSelector, ({ dialogOpen }) => dialogOpen);
