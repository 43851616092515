/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CircularProgress } from "@material-ui/core";
import { Dispatch } from "@reduxjs/toolkit";
import CustomAppbar from "components/Appbar/CustomAppbar";
import LoginForm from "components/Config/Form/Login";
import { WindowPortal } from "components/Config/WindowPortal/WindowPortal";
import TabsPanel from "components/TabsPanel/TabsPanel";
import {
  PageExtensionSDK,
  SidebarExtensionSDK,
} from "contentful-ui-extensions-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AppDispatch } from "store";
import { loginSuccess } from "store/auth/auth.actions";
import { initConfig } from "store/config/config.actions";
import { InitConfigPayload } from "store/config/config.interface";
import { getEntriesData } from "store/entries/entries.actions";
import {
  clearToken,
  getAuthToken,
  refreshTokenTimeout,
} from "utils/axiosInstance";
import { getContentfulIds } from "utils/initConfig";
import FormContainer from "../Sidebar/AddContent/FormContainer";
import { ConnectButton, SpinnerDiv } from "../Sidebar/Sidebar.styled";
import TranslationQueue from "../Sidebar/TranslationQueue/TranslationQueue";
import { Scroller, TabsWrapper } from "./Page.styled";

interface SidebarProps {
  sdk: PageExtensionSDK;
  fullscreen?: boolean;
}

interface IDispatchProps {
  initConfig: (payload: InitConfigPayload) => AppDispatch;
  loginSuccess: (payload: InitConfigPayload) => AppDispatch;
  getEntriesData: () => AppDispatch;
}

type IProps = SidebarProps & IDispatchProps;

const Page = ({ loginSuccess, sdk, fullscreen, getEntriesData }: IProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showWindowPortal, setShowWindowPortal] = useState<boolean>(false);
  const [fetchFinished, setFetchFinished] = useState<boolean>(false);

  const handleLogout = () => {
    clearToken();
    setIsLoggedIn(false);
  };

  useEffect(() => {
    async function refreshAsync() {
      await refreshTokenTimeout().finally(() => {
        setIsLoggedIn(Boolean(getAuthToken()));
        setFetchFinished(true);
      });
    }

    refreshAsync();
  }, [sdk]);

  useEffect(() => {
    const onLoginSuccess = async () => {
      loginSuccess(await getContentfulIds(sdk));
      getEntriesData();
    };

    if (isLoggedIn) {
      onLoginSuccess();
    }
  }, [getEntriesData, isLoggedIn, loginSuccess, sdk]);

  const { t } = useTranslation();

  const handleWindowClose = () => {
    setShowWindowPortal(false);
  };

  const handleClose = (success?: boolean) => {
    if (success) {
      setIsLoggedIn(true);
    }
    setShowWindowPortal(false);
  };

  const handleOpen = () => {
    setShowWindowPortal(true);
  };

  return (
    <React.Fragment>
      {!fetchFinished && (
        <SpinnerDiv>
          <CircularProgress color="secondary" />
        </SpinnerDiv>
      )}
      {fetchFinished &&
        (isLoggedIn ? (
          <React.Fragment>
            <CustomAppbar handleLogout={handleLogout} fullscreen={fullscreen} />
            <Scroller>
              <TabsWrapper>
                <TabsPanel
                  tabs={[
                    {
                      labelKey: "common.tab.addContentTab",
                      component: (
                        <FormContainer sdk={sdk} fullscreen={fullscreen} />
                      ),
                    },
                    {
                      labelKey: "common.tab.translationQueue",
                      component: (
                        <TranslationQueue
                          sdk={sdk as SidebarExtensionSDK}
                          fullscreen={fullscreen}
                        />
                      ),
                    },
                  ]}
                />
              </TabsWrapper>
            </Scroller>
          </React.Fragment>
        ) : (
          <TabsWrapper style={{ paddingTop: 20 }}>
            <ConnectButton
              data-testid="connect"
              onClick={handleOpen}
              color="secondary"
              variant="contained"
              fullWidth
            >
              {t("common.connectToXTM")}
            </ConnectButton>
          </TabsWrapper>
        ))}
      {showWindowPortal && (
        <WindowPortal closePortal={handleWindowClose}>
          <LoginForm handleClose={handleClose} />
        </WindowPortal>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>
): IDispatchProps => ({
  initConfig: (payload: InitConfigPayload) => dispatch(initConfig(payload)),
  loginSuccess: (payload: InitConfigPayload) => dispatch(loginSuccess(payload)),
  getEntriesData: () => dispatch(getEntriesData({})),
});

export default connect(null, mapDispatchToProps)(Page);
