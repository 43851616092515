import { Box, MenuItem } from '@material-ui/core';
import React, { ChangeEvent, Fragment, PureComponent } from 'react';
import { EntriesTableButton, EntriesTableSelect } from './EntriesTable.styled';

interface IProps {
  title: string;
  options: Array<{ label: string; value?: string }>;
  onChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  value: string;
}

class EntriesTablePill extends PureComponent<IProps> {
  render() {
    const { title, options, onChange, value } = this.props;
    return (
      <Fragment>
        <EntriesTableButton variant="text" size="small" disableRipple>
          <Box padding="0 8px" fontWeight="fontWeightBold" fontSize="14px" color="#536171">
            {title}
          </Box>
          <EntriesTableSelect
            inputProps={{ style: { border: 'none' } }}
            SelectDisplayProps={{ style: { fontWeight: 700, color: '#fff' } }}
            onChange={onChange}
            value={value}
          >
            {options.map(({ label, value }, index) => (
              <MenuItem value={value} key={index}>
                {label}
              </MenuItem>
            ))}
          </EntriesTableSelect>
        </EntriesTableButton>
      </Fragment>
    );
  }
}

export default EntriesTablePill;
