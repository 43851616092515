/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, CircularProgress, Link, Typography } from '@material-ui/core';
import { XTMLogo } from 'components/XTMLogo/XTMLogo';
import { createForm, FormApi, Unsubscribe } from 'final-form';
import { Component } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  api,
  initializeApi,
  refreshTokenTimeout,
  urlStorageKey,
} from 'utils/axiosInstance';
import {
  composeValidators,
  email,
  fetchValidatorObject,
  password,
  required,
} from 'utils/customValidators';
import CustomField from './CustomField';
import { StyledLinkDiv, TopBar } from './Form.styled';
import {
  formHeaderStyle,
  loginButtonStyle,
  loginWrapperStyle,
} from './Login.style';

interface IFormValues {
  email: string;
  password: string;
  applicationURL: string;
}

interface IState {
  submitValues: IFormValues;
  spinner: boolean;
  loginError?: string;
}

interface ILoginProps {
  handleClose: (success?: boolean) => void;
}

type PropType = ILoginProps & WithTranslation;

export class LoginForm extends Component<PropType, IState> {
  unsubscribe: Unsubscribe;
  constructor(props: PropType) {
    super(props);
    this.state = {
      loginError: undefined,
      spinner: false,
      submitValues: {
        applicationURL: '',
        email: '',
        password: '',
      },
    };
    this.form = createForm({
      onSubmit: this.handleSubmit,
      destroyOnUnregister: true,
    });
    this.unsubscribe = this.form.subscribe(
      () => {
        if (this.state.loginError) {
          this.clearLoginErrors();
        }
      },
      { values: true }
    );
  }

  componentWillUnmount(): void {
    this.unsubscribe();
  }

  form: FormApi<IFormValues, Partial<Record<string, unknown>>>;

  handleSubmit = (formValues: IFormValues): void => {
    const { email, password } = formValues;
    const applicationURL = /^http[s]?:\/\//.test(
      formValues.applicationURL.trim()
    )
      ? formValues.applicationURL.trim()
      : `https://${formValues.applicationURL.trim()}`;

    this.setState({ submitValues: formValues, spinner: true });
    this.form.change('email', email.trim());
    localStorage.setItem(urlStorageKey, applicationURL);
    initializeApi();

    api
      .authenticateUser({ email, password })
      .then(async () => {
        this.setState({ spinner: false });
        await refreshTokenTimeout();
        this.props.handleClose(true);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errorCode
        ) {
          this.setState({
            loginError: error.response.data.errorCode,
            spinner: false,
          });
        } else {
          this.setState({
            loginError: 'errormessage.apiUrlError',
            spinner: false,
          });
        }
      });
  };

  clearLoginErrors = () => {
    this.setState({ loginError: undefined });
  };

  render() {
    const { t } = this.props;
    const { submitValues, loginError } = this.state;
    return (
      <div>
        <TopBar>
          <XTMLogo />
        </TopBar>
        <div css={loginWrapperStyle}>
          <FinalForm
            onSubmit={this.handleSubmit}
            form={this.form}
            validate={fetchValidatorObject(
              loginError,
              submitValues as unknown as Record<string, string>
            )}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit }): JSX.Element => (
              <form onSubmit={handleSubmit} data-testid="login-form">
                <h2 css={formHeaderStyle}>{t('loginForm.header')}</h2>
                {loginError && (
                  <span className="textError">{t(loginError)}</span>
                )}
                <Field
                  name="applicationURL"
                  validate={required}
                  formatOnBlur
                  format={(value) => (value === undefined ? '' : value.trim())}
                  initialValue=""
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomField
                      label={t('loginForm.labels.appURL')}
                      inputProps={input}
                      error={meta.error}
                      touched={meta.touched}
                      testId="applicationURL"
                    />
                  )}
                </Field>

                <Field
                  name="email"
                  validate={composeValidators([required, email])}
                  formatOnBlur
                  format={(value) => (value === undefined ? '' : value.trim())}
                  initialValue=""
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomField
                      label={t('loginForm.labels.username')}
                      inputProps={input}
                      error={meta.error}
                      touched={meta.touched}
                      testId="email"
                    />
                  )}
                </Field>
                <Field
                  name="password"
                  validate={composeValidators([required, password])}
                >
                  {({ input, meta }): JSX.Element => (
                    <CustomField
                      label={t('loginForm.labels.password')}
                      inputProps={input}
                      error={meta.error}
                      touched={meta.touched}
                      testId="password"
                      type="password"
                    />
                  )}
                </Field>
                <Button
                  css={loginButtonStyle}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  data-testid="login"
                  disabled={this.state.spinner}
                >
                  {this.state.spinner && (
                    <CircularProgress size={24} color="secondary" />
                  )}
                  {!this.state.spinner && t('loginForm.logIn')}
                </Button>
                <StyledLinkDiv>
                  <Link
                    href={`${
                      (window as any)._env_?.REACT_APP_APPLICATION_URL
                    }/reset-password/user`}
                  >
                    <Typography color="secondary">
                      {t('common.resetPassword')}
                    </Typography>
                  </Link>
                </StyledLinkDiv>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(LoginForm);
